import { Action } from '@ngrx/store'

export enum ErrorActionTypes {
  throwNewCustomError = '[Error] throw new custom error',
}

export class ThrowNewCustomError {
  readonly type = ErrorActionTypes.throwNewCustomError
  constructor(payload: any) {}
}

export type ErrorActions = ThrowNewCustomError

import { createFeatureSelector, createSelector } from '@ngrx/store'
import { video } from '../reducers'
import { adapter } from './video.reducer'

const videoEntitySelectors = adapter.getSelectors()

export const getVideoStore = createFeatureSelector<video.State>('video')

export const getVideoEntities = createSelector(getVideoStore, videoEntitySelectors.selectAll)
export const getVideosLoaded = createSelector(getVideoStore, store => store.loaded)

import { createFeatureSelector, createSelector } from '@ngrx/store'
import { getSelectedJobId } from '../job/job.selectors'
import { application } from '../reducers'
import { adapter } from './application.reducer'

const applicationStageEntitySelectors = adapter.getSelectors()

export const getApplicationStore = createFeatureSelector<application.State>('application')

export const getApplicationStageEntities = createSelector(getApplicationStore, applicationStageEntitySelectors.selectAll)

export const getApplicationStageForJob = (jobPostId: string) =>
  createSelector(getApplicationStageEntities, applications => applications.find(application => jobPostId === application.jobPostId))

export const getApplicationStageForSelectedJob = createSelector(getApplicationStageEntities, getSelectedJobId, (applications, selectedJobPostId) =>
  applications.find(application => selectedJobPostId === application.jobPostId)
)

export const getApplicationPreview = createSelector(getApplicationStore, store => store.processedApplicationPreview)
export const loadingApplicationPreview = createSelector(getApplicationStore, store => store.loadingApplicationPreview)

export const getSelectedJobApplication = createSelector(getApplicationStore, store => store.selectedJobApplication)
export const getApplicationHistory = createSelector(getApplicationStore, store => store.selectedApplicationHistory)
export const getStagedApplicationUpdate = createSelector(getApplicationStore, store => store.selectedApplicationUpdateStage)
export const getSelectedApplicationDialogues = createSelector(getApplicationStore, store => store.selectedApplicationDialogues)
export const loadingSelectedApplicationDialogues = createSelector(getApplicationStore, store => store.loadingVirtualDialogues)
export const loadingApplicationHistory = createSelector(getApplicationStore, store => store.loadingApplicationHistory)
export const loadingSelectedJobApplication = createSelector(getApplicationStore, store => store.loadingSelectedJobApplication)
export const submittingApplicationUpdate = createSelector(getApplicationStore, store => store.submittingApplicationUpdate)
export const withdrawingApplication = createSelector(getApplicationStore, store => store.withdrawingApplication)
export const loadingChooseResume = createSelector(getApplicationStore, store => store.loadingChooseResume)

<e11-panel>
  <div *ngIf="user">
    <h3 class="e11-mb-4">{{ 'Profile Picture' | translate }}</h3>
    <div class="e11-block md:e11-flex">
      <div class="e11-w-full md:e11-w-1/3 md:e11-mr-8 e11-text-center">
        <div class="e11-flex e11-justify-center e11-mb-4">
          <e11-avatar
            [imageUrl]="user.photoURL"
            [initials]="user.firstName.charAt(0) + user.lastName.charAt(0) || undefined"
            [size]="'xxxl'"
            [hasBorder]="true"
          ></e11-avatar>
        </div>

        <e11-button
          [size]="'sm'"
          [color]="'primary-accent'"
          value="{{ 'Update Profile Picture' | translate }}"
          (click)="modalProfilePic.open()"
        ></e11-button>
        <p class="account-created e11-pt-2" *ngIf="user.__createdAt">{{ 'Account Created ' + user.__createdAt.toLocaleDateString() }}</p>
      </div>
      <div class="e11-w-full md:e11-w-2/3">
        <p class="e11-font-primary-demibold">{{ 'Add your favorite headshot, picture, gif, or any image you like.' }}</p>
        <p class="">
          {{
            'This image represents you for account navigation and when sending or reading messages. If you choose not to upload and image your initials will be displayed.'
              | translate
          }}
        </p>
      </div>
    </div>

    <e11-modal #modalProfilePic [width]="'md'" title="{{ 'Update Profile Photo' | translate }}" [showHeader]="true" [showFooter]="false">
      <div modal-body>
        <photo-edit [currentPhotoUrl]="user.photoURL" (newPhotoUrl)="saveNewPhoto($event)" (removePhoto)="removeProfilePhoto()"></photo-edit>
      </div>
    </e11-modal>
  </div>
</e11-panel>

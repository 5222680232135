import { IPublicJobPostVM } from '@candidate/app/models/jobs/job-post-public.model'
import { compareCandidateJobVM } from '@candidate/app/services/jobs/candidate-job.util'
import { upsertById } from '@engineering11/utility'
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import {
  ICandidateJobApplicationStash,
  ICandidateJobSubmissionPreview,
  ICandidateJobVM,
  IProcessedJobApplication,
  IVirtualDialogue,
} from 'shared-lib'
import { JobActions, JobActionTypes } from './job.actions'

export interface State extends EntityState<ICandidateJobVM> {
  loaded: boolean
  loadingJobs: boolean
  error: [] | null
  selectedJobId: string | null
  loadingSelectedJob: boolean
  jobApplicationStash: ICandidateJobApplicationStash | null
  jobSubmission: ICandidateJobSubmissionPreview | null
  processedApplication: IProcessedJobApplication | null
  publicJobDetail: IPublicJobPostVM | null
  jobEntities: ICandidateJobVM[]
  virtualDialogues: IVirtualDialogue[] // All dialogues for the candidate - consider making this entity state
}

export function selectCandidateJobId(a: ICandidateJobVM): string {
  return a.jobId
}

export const adapter: EntityAdapter<ICandidateJobVM> = createEntityAdapter<ICandidateJobVM>({
  selectId: selectCandidateJobId,
  sortComparer: compareCandidateJobVM,
})

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loadingJobs: false,
  error: null,
  selectedJobId: null,
  loadingSelectedJob: false,
  jobApplicationStash: null,
  jobSubmission: null,
  processedApplication: null,
  jobEntities: [],
  virtualDialogues: [],
  publicJobDetail: null,
})

export function reducer(state = initialState, action: JobActions): State {
  switch (action.type) {
    case JobActionTypes.getAllJobs:
    case JobActionTypes.buildCandidateJobSubmission:
      return {
        ...state,
        error: null,
        loadingJobs: true,
      }
    case JobActionTypes.processJobApplication:
    case JobActionTypes.processJobApplicationPreview:
      return {
        ...state,
        error: null,
        loadingJobs: true,
      }
    case JobActionTypes.buildCandidateJobSubmissionSuccess:
      return {
        ...state,
        loadingJobs: false,
        jobSubmission: { ...state.jobSubmission, ...action.payload }, // Means candidateResponses don't get erased
      }
    case JobActionTypes.getJobSubmissionStashSuccess:
      return {
        ...state,
        loadingJobs: false,
        jobApplicationStash: action.payload,
      }
    case JobActionTypes.getAllJobsSuccess:
      return adapter.setMany(action.payload, {
        ...state,
        loaded: true,
        loadingJobs: false,
      })
    case JobActionTypes.getJob:
      return {
        ...state,
        loadingSelectedJob: true,
      }
    case JobActionTypes.getJobSuccess:
      // return { ...state, selectedJobId: action.payload.jobId }
      return adapter.setOne(action.payload, { ...state, loadingSelectedJob: false, selectedJobId: action.payload.jobId })
    case JobActionTypes.getPublicJobSuccess:
      return { ...state, publicJobDetail: action.payload }
    case JobActionTypes.listenJobSuccess:
      // return { ...state, selectedJobId: action.payload.jobId }
      return adapter.setOne(action.payload, { ...state })

    case JobActionTypes.removeJobSuccess:
      return adapter.removeOne(action.payload, state)
    case JobActionTypes.getAllVirtualDialoguesSuccess:
      return { ...state, virtualDialogues: action.payload }
    case JobActionTypes.processJobApplicationSuccess:
    case JobActionTypes.processJobApplicationPreviewSuccess:
      return {
        ...state,
        loadingJobs: false,
        processedApplication: action.payload,
      }
    case JobActionTypes.updateJobApplicationStash:
      return {
        ...state,
        jobApplicationStash: { ...state.jobApplicationStash, ...action.payload },
      }
    case JobActionTypes.candidateResponsesChanged:
    case JobActionTypes.candidateResponsesChangedSuccess:
      const jobSubmission = state.jobSubmission
        ? {
            ...state.jobSubmission,
            candidateResponses: action.payload.candidateResponses,
          }
        : null
      return {
        ...state,
        loadingJobs: false,
        jobSubmission,
        jobApplicationStash: { ...state.jobApplicationStash, ...action.payload },
      }
    case JobActionTypes.getVirtualDialogueSuccess:
      if (!action.payload) {
        return state
      }
      const virtualDialogues = upsertById(action.payload, state.virtualDialogues)
      return { ...state, virtualDialogues }
    case JobActionTypes.error:
      return {
        ...state,
        error: action.payload,
      }
    case JobActionTypes.reset:
      return {
        ...initialState,
      }
    default:
      return state
  }
}

export const jobEntitySelectors = adapter.getSelectors()

import { Injectable } from '@angular/core'
import { AtLeast } from '@engineering11/types'
import { Observable } from 'rxjs'
import { ICandidateJobApplicationStash, ICandidateJobSubmissionPreview } from 'shared-lib'
import { CandidateApplicationStashRepository } from './candidate-application-stash.repository'

@Injectable({ providedIn: 'root' })
export class CandidateApplicationStashService {
  constructor(private candidateApplicationStashRepository: CandidateApplicationStashRepository) {}

  getByUserAndJobPost(userId: string, jobPostId: string): Observable<ICandidateJobApplicationStash | undefined> {
    const id = this.buildStashId(userId, jobPostId)
    return this.candidateApplicationStashRepository.get(id)
  }

  upsert(model: Omit<ICandidateJobApplicationStash, 'id'>) {
    const modelToSave: ICandidateJobApplicationStash = { ...model, id: this.buildStashId(model.candidateId, model.jobPostId) }
    return this.candidateApplicationStashRepository.upsert(modelToSave)
  }

  update(model: AtLeast<ICandidateJobApplicationStash, 'jobPostId' | 'candidateId'>) {
    const modelToSave = { ...model, id: this.buildStashId(model.candidateId, model.jobPostId) }
    return this.candidateApplicationStashRepository.update(modelToSave)
  }

  private buildStashId(userId: string, jobPostId: string) {
    return `${userId}_${jobPostId}`
  }
}

<header>
  <nav class="e11-bg-skin-primary e11-fixed e11-w-full e11-z-[12]">
    <div class="e11-container-full e11-mx-auto e11-flex e11-px-6">
      <div class="e11-header-logo e11-flex e11-items-center e11-cursor-pointer" [routerLink]="'/network'">
        <img [src]="logoUrl" class="e11-h-16" />
      </div>
      <div class="e11-flex e11-grow e11-justify-end e11-items-center">
        <a class="e11-text-skin-white" target="_blank" href="https://cnected.com/"> {{ 'Learn more about Cnect' | translate }}</a>
      </div>
    </div>
  </nav>
</header>

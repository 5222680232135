import { Injectable } from '@angular/core'
import { CandidateDetailTabs, EmployerNavigationService } from '@employer/app/services/navigation.service'
import { IConversation, MessagingNavigationService } from '@engineering11/messaging-web'
import { E11ErrorHandlerService, toE11Error } from '@engineering11/web-api-error'
import { ApplicationConversationConfigProvider, IApplicationConversationMetadata } from 'shared-lib'
import { ApplicationDetailTabs, CandidateHomeNavigationService } from '@candidate/app/services/candidate-home-navigation.service'

@Injectable({ providedIn: 'root' })
export class CandidateApplicationConversationProvider extends ApplicationConversationConfigProvider {
  constructor(
    private navigationService: CandidateHomeNavigationService,
    messagingNavigationService: MessagingNavigationService,
    private errorHandler: E11ErrorHandlerService
  ) {
    super(messagingNavigationService)
  }

  onConversationClick(conversation: IConversation<IApplicationConversationMetadata>): void {
    const { jobPostId } = conversation.clientMetadata!

    try {
      this.navigationService.toApplicationDetailTab(jobPostId, ApplicationDetailTabs.Messages)
    } catch (err: any) {
      this.errorHandler.handleError(
        toE11Error(err, {
          type: 'messaging/route-failure',
          title: 'Failed to navigate',
          additionalData: conversation,
        })
      )
      super.onConversationClick(conversation) // fallback if navigation fails
    }
  }
}

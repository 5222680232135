<div *ngIf="config" class="e11-mt-5">
  <ais-instantsearch [config]="config">
    <ais-configure [searchParameters]="searchParams"></ais-configure>
    <div class="row">
      <div class="col-xs-12 col-md-6 nopadding-left">
        <h1>{{ 'Search Job Posts' | translate }}</h1>
      </div>
      <div class="col-xs-12 col-md-6 nopadding-right">
        <ais-search-box placeholder="{{ 'Search Job Posts' | translate }}" [searchAsYouType]="true"></ais-search-box>
      </div>
    </div>

    <div class="spacer-2rem"></div>

    <div class="row">
      <ais-hits>
        <ng-template let-hits="hits">
          <!-- List -->
          <div *ngIf="hits.length > 0">
            <table class="">
              <thead class="e11-border-b e11-border-skin-app-borders">
                <tr #thead>
                  <th class="ellipsis text-left">{{ 'Job Title' | translate }}</th>
                  <th class="ellipsis text-left">{{ 'Employment Type' | translate }}</th>
                  <th class="ellipsis text-left">{{ 'Department' | translate }}</th>
                  <th class="ellipsis text-left">{{ 'Experience Level' | translate }}</th>
                  <th class="ellipsis text-left">{{ 'Location' | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <tr class="" *ngFor="let post of hits; let i = index" [routerLink]="['/landing/job', post.id]">
                  <td class="ellipsis" data-col="Job Title">{{ post.jobTitle }}</td>
                  <td class="ellipsis" data-col="Employment Type">{{ post.employmentType | employmentType }}</td>
                  <td class="ellipsis" data-col="Department">{{ post.department }}</td>
                  <td class="ellipsis" data-col="Experience Level">{{ post.experienceLevel | experienceLevel }}</td>
                  <td class="ellipsis" data-col="Location">{{ post.locationAddress ? post.locationAddress.name : ('N/A' | translate) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </ng-template>
      </ais-hits>

      <div class="row pagination">
        <div class="col-xs-12">
          <ais-pagination></ais-pagination>
        </div>
      </div>
    </div>
  </ais-instantsearch>
</div>

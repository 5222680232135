import { IVideoWithThumbnail } from '@engineering11/files-web'
import { AtLeast } from '@engineering11/types'

export enum VirtualDialogueStatus {
  Draft,
  Published_Employer,
  Published_Candidate,
}

// First, second, third. Needed to allow for specific ordering depending on status.
export const statusOrderEmployer = Object.freeze({
  [VirtualDialogueStatus.Published_Candidate]: 1,
  [VirtualDialogueStatus.Draft]: 2,
  [VirtualDialogueStatus.Published_Employer]: 3,
})

export interface IVirtualDialogue {
  id: string
  name: string | null
  candidateId: string
  jobPostId: string
  customerKey: string
  status: VirtualDialogueStatus
  senderId: string
  senderInformation: { displayName: string }
  questions: IQuestion[]
  candidateApplicationId: string
  hasEmployerViewedResponse?: boolean

  dateSentToCandidate: Date | null
  dateFinishedByCandidate: Date | null
}

export interface VirtualDialogueUpdateModel {
  name?: string
  hasEmployerViewedResponse?: boolean
}

export interface VirtualDialogueCreateModel {
  candidateId: string
  jobPostId: string
  name?: string
}

export interface VirtualDialoguesByCandidateAndJobRequest {
  customerKey: string
  candidateId: string
  jobPostId: string
}

export interface VirtualDialogueUpdateRequest {
  virtualDialogue: IVirtualDialogue
  updateModel: VirtualDialogueUpdateModel
}

export interface VirtualDialogueEditQuestionRequest {
  questionId: string
  model: EditQuestionModel
}

export enum QuestionType {
  Essay = 'Essay',
  Video = 'Video',
  Multiple = 'Multiple',
}

export const dialogueQuestionTypeMappings = new Map<QuestionType, string>().set(QuestionType.Essay, 'Text').set(QuestionType.Video, 'Video')

export interface BaseQuestion {
  id: string
  position: number
  question: string
  type: QuestionType
  libraryId?: string | null
}

export interface EssayQuestion extends BaseQuestion {
  type: QuestionType.Essay
  response: string | null
}

export interface VideoQuestion extends BaseQuestion {
  type: QuestionType.Video
  response: IVideoWithThumbnail | null
}

export type IQuestion = EssayQuestion | VideoQuestion
export type IQuestionResponse = IQuestion['response']

export type IQuestionUpdate = AtLeast<IQuestion, 'id'>

export type CreateQuestionModel = Pick<BaseQuestion, 'type' | 'question' | 'libraryId'>

export type EditQuestionModel = Partial<Pick<BaseQuestion, 'position' | 'question' | 'type' | 'libraryId'>>

export interface DeleteQuestionRequest {
  questionId: string
  libraryId?: string
}

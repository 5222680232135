import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core'
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { ApplicationPage } from '@candidate/app/modules/applications/views/apply/apply.component'
import { ReplaceValues } from '@engineering11/types'
import { hasChanged } from '@engineering11/utility'
import { E11Logger } from '@engineering11/web-api-error'
import { Subject } from 'rxjs'
import { debounceTime, map, takeUntil, tap } from 'rxjs/operators'
import { FormSelectModel, FormSelectService, ICandidateJobVM, ICandidateResponses } from 'shared-lib'

/**
 * TODO: Replace with dynamic form builder
 */

@Component({
  selector: 'candidate-question-responses',
  templateUrl: './candidate-question-responses.component.html',
  styleUrls: ['./candidate-question-responses.component.scss'],
})
export class CandidateQuestionResponsesComponent implements OnChanges, OnDestroy {
  @Input() currentResponses?: ICandidateResponses

  @Input() jobDetail: ICandidateJobVM | undefined

  @Input() loading = false

  @Output() newResponses = new EventEmitter<ICandidateResponses>()

  responsesFormGroup = new UntypedFormGroup({})

  showConfirmationDetails: boolean = false

  formValid$ = new Subject<boolean>()

  destroy$: Subject<boolean> = new Subject<boolean>()

  referralSourceList: FormSelectModel[] = []
  showReferralDetails: boolean = false
  selectDefaultReferral = 'cnect' //
  referrerDetailRequired: boolean = false

  // Must remain at latest to prevent bug where candidate submits their application without the latest responses
  currentCandidateResponses?: ICandidateResponses

  enumApplicationPage = ApplicationPage
  constructor(private formBuilder: UntypedFormBuilder, private logger: E11Logger, private formSelectService: FormSelectService) {
    this.referralSourceList = this.formSelectService.getCandidateReferralList()
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { jobDetail, currentResponses } = changes
    // Prevents form from being re-initialised with data that's just updated the responses, possibly overwriting what the candidate did between the last update and now.
    if (
      (jobDetail &&
        hasChanged<ICandidateJobVM>('jobId', {
          before: jobDetail.previousValue,
          after: jobDetail.currentValue,
        })) ||
      (currentResponses && !currentResponses.previousValue && currentResponses.currentValue)
    ) {
      this.initFormGroup(currentResponses?.currentValue)
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
    this.formValid$.complete()
  }

  get f() {
    return this.responsesFormGroup.controls as {
      [key: string]: UntypedFormControl
    }
  }

  get isFormValid() {
    return this.responsesFormGroup.valid
  }

  get isScheduleAvailable() {
    return this.jobDetail?.scheduleText && this.jobDetail.scheduleText.length
  }

  private initFormGroup(currentResponses?: ICandidateResponses) {
    this.logger.log('initting form group with ', currentResponses)

    const maxYearsExperience = 123
    const maxMonths = 11
    const minExperience = 0
    const formInput: ReplaceValues<ICandidateResponses, UntypedFormControl> = {
      legalWorkAuthorization: new UntypedFormControl(currentResponses?.legalWorkAuthorization, [Validators.required]),
      visaSponsorshipRequired: new UntypedFormControl(currentResponses?.visaSponsorshipRequired, [Validators.required]),
      yearsRelevantExperience: new UntypedFormControl(currentResponses?.yearsRelevantExperience ? currentResponses?.yearsRelevantExperience : 0, [
        Validators.required,
        Validators.min(minExperience),
        Validators.max(maxYearsExperience),
      ]),
      monthsRelevantExperience: new UntypedFormControl(currentResponses?.monthsRelevantExperience ? currentResponses?.monthsRelevantExperience : 0, [
        Validators.required,
        Validators.min(minExperience),
        Validators.max(maxMonths),
      ]),
      isDefaultApplication: new UntypedFormControl(currentResponses?.isDefaultApplication),
    }

    if (this.jobDetail?.travelPercentage) {
      formInput.travelConfirmed = new UntypedFormControl(currentResponses?.travelConfirmed, [Validators.required])
    } else {
      formInput.travelConfirmed = new UntypedFormControl(currentResponses?.travelConfirmed)
    }

    if (this.isScheduleAvailable) {
      formInput.hoursConfirmed = new UntypedFormControl(currentResponses?.hoursConfirmed, [Validators.required])
    } else {
      formInput.hoursConfirmed = new UntypedFormControl(currentResponses?.hoursConfirmed)
    }

    this.responsesFormGroup = this.formBuilder.group(formInput)
    this.formValid$.next(this.responsesFormGroup.valid)

    const currentCandidateResponses$ = this.responsesFormGroup.valueChanges.pipe(map(changes => formChangesToResponses(changes)))
    // ! Since this refires on changes, what happens to the subscriptions?
    currentCandidateResponses$.pipe(takeUntil(this.destroy$)).subscribe(candidateResponses => (this.currentCandidateResponses = candidateResponses))
    currentCandidateResponses$.pipe(takeUntil(this.destroy$), debounceTime(1000)).subscribe(candidateResponses => {
      this.responsesChanged(candidateResponses)
    })
  }

  private responsesChanged(newResponses: ICandidateResponses) {
    this.formValid$.next(this.responsesFormGroup.valid)
    this.newResponses.emit(newResponses)
  }

  toggleConfirmationDetails() {
    // This could be handled in the template but putting here incase we need to expand on the functionality later
    this.showConfirmationDetails = !this.showConfirmationDetails
  }
}

function formChangesToResponses(changes: any): ICandidateResponses {
  return {
    ...changes,
    monthsRelevantExperience: parseInt(changes.monthsRelevantExperience || 0),
    yearsRelevantExperience: parseInt(changes.yearsRelevantExperience || 0),
  }
}

import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild, ViewEncapsulation } from '@angular/core'
import { UserMode } from '../../model/component-modes.enum'
import { BrowserDetectionService } from '../../service/browser-detection.service'
import { IWorkHistory } from './../../model/interfaces'

@Component({
  selector: 'ui-work-history-line-item',
  template: `
    <div class="e11-border e11-border-skin-app-borders e11-p-2 e11-mb-4 e11-rounded">
      <div class="e11-flex">
        <div class="e11-w-3/4">
          <p class="e11-font-bold e11-line-clamp-3 e11-break-normal e11-mb-0" [ngClass]="{ 'e11-mb-1': !workHistory }">
            {{ workHistory?.companyName }}
          </p>
        </div>
        <div class="e11-w-1/4 e11-flex e11-justify-end">
          <span
            *ngIf="workHistory && userMode === userModeEnum.Candidate"
            class="e11-flex e11-items-start e11-cursor-pointer e11-text-skin-primary-accent hover:e11-text-skin-primary e11-text-sm"
            (click)="viewDetail.emit(workHistory); editView.emit(true)"
          >
            <span class="material-icons-outlined md-18 e11-text-skin-primary-accent hover:e11-text-skin-primary e11-mr-1"> edit </span>
            <!-- Edit -->
          </span>
        </div>
      </div>

      <div class="e11-break-words e11-text-sm e11-line-clamp-2" *ngIf="workHistory?.jobTitle">{{ workHistory?.jobTitle }}</div>
      <div *ngIf="pdfMode" class="e11-w-1/2">
        <date-display-start-end *ngIf="workHistory" class="e11-text-skin-light e11-text-sm" [item]="workHistory"> </date-display-start-end>
      </div>
      <div class="e11-break-words e11-line-clamp-2 e11-skeleton-empty e11-mb-1" *ngIf="!workHistory"></div>

      <div
        #readMoreSection
        class="e11-readmore-content e11-break-words e11-text-sm"
        [ngClass]="{ 'e11-line-clamp-3': showReadMore, safari_only: browser === 'Safari', 'e11-block': pdfMode }"
        [ngxSummernoteView]="workHistory?.jobDescription || ''"
      ></div>

      <!-- <div *ngIf="workHistory?.jobDescription" [ngxSummernoteView]="workHistory?.jobDescription || ''"></div> -->
      <div class="e11-skeleton-empty" *ngIf="!workHistory"></div>

      <div *ngIf="!pdfMode" class="e11-w-full e11-flex">
        <div class="e11-w-1/2">
          <date-display-start-end *ngIf="workHistory" class="e11-text-skin-light e11-text-sm" [item]="workHistory"> </date-display-start-end>
        </div>
        <div class="e11-w-1/2 e11-flex e11-items-center e11-justify-end">
          <span
            *ngIf="workHistory && hasReadMore"
            (click)="readMore(workHistory.id)"
            class="e11-text-skin-primary-accent hover:e11-text-skin-primary e11-cursor-pointer"
          >
            <span class="e11-flex e11-items-center e11-text-sm" *ngIf="workHistory.id !== workHistoryId">
              <span class="material-icons-outlined"> expand_more </span>{{ 'Read more' | translate }}
            </span>
            <span class="e11-flex e11-items-center e11-text-sm" *ngIf="workHistory.id === workHistoryId">
              <span class="material-icons-outlined"> expand_less </span>{{ 'Close' | translate }}
            </span>
          </span>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./ui-work-history-line-item.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiWorkHistoryLineItemComponent implements OnChanges {
  @ViewChild('readMoreSection') private readMoreSection!: ElementRef

  @Input() userMode: UserMode = UserMode.Candidate
  @Input() workHistory?: IWorkHistory // undefined means skeleton loader
  @Input() removable: boolean = false
  @Input() pdfMode: boolean = false

  @Output() viewDetail = new EventEmitter()
  @Output() editView = new EventEmitter()
  @Output() deleteItem = new EventEmitter<IWorkHistory>()

  userModeEnum = UserMode
  workHistoryId: string = ''
  readmoreHeight?: number | undefined
  hasReadMore: boolean = false
  browser: string = ''

  constructor(private changeDetector: ChangeDetectorRef, private detectBrowser: BrowserDetectionService) {}

  ngOnChanges() {
    this.readmore(16, 1.5, 3)
    this.changeDetector.detectChanges()
    this.browser = this.detectBrowser.getBrowser()
  }

  // @Todo - Refactor into readmore component - character count unreliable
  readmore(pixelHeight: number, lineHeight: number, maxLines: number) {
    let maxHeight = pixelHeight * lineHeight * maxLines // max readMore container height before line clamp is added
    if (this.readMoreSection && this.readMoreSection.nativeElement && this.readmoreHeight === undefined) {
      this.readmoreHeight = this.readMoreSection.nativeElement.getBoundingClientRect().height
      if (this.readMoreSection.nativeElement.getBoundingClientRect().height > maxHeight) {
        this.hasReadMore = true
      } else {
        this.hasReadMore = false
      }
    }
  }

  readMore(workHistoryId: string) {
    workHistoryId === this.workHistoryId ? (this.workHistoryId = '') : (this.workHistoryId = workHistoryId)
  }

  get showReadMore() {
    return this.hasReadMore && this.workHistory?.id !== this.workHistoryId
  }
}

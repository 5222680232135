import { Pipe, PipeTransform } from '@angular/core'
import { ISkill, SkillLevel } from 'shared-lib'

@Pipe({
  name: 'skillsByLevel',
})
export class SkillsByLevelPipe implements PipeTransform {
  transform(skills: ISkill[], skillLevel: SkillLevel): ISkill[] {
    return skills.filter(skill => skill.level === skillLevel)
  }
}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core'
import { FilesService, IFileReceipt } from '@engineering11/files-web'
import { PlayerSize } from '@engineering11/multimedia-web'
import { E11ModalComponent } from '@engineering11/ui-lib/e11-modal'
import { Size } from '@engineering11/ui-lib/e11-ui-config'
import { deepCopy } from '@engineering11/utility'
import { RequestedUpdateFields } from '../../model/candidate-application.model'
import { UserMode } from '../../model/component-modes.enum'
import { IFirstImpression } from '../../model/interfaces'

@Component({
  selector: 'single-first-impression',
  template: `
    <div *ngIf="loaded">
      <single-first-impression-card
        [firstImpression]="signedFirstImpression"
        [userMode]="userMode"
        [openRequest]="openRequest"
        (openVideoPlayer)="openVideoPlayer($event)"
        (openRequestUpdate)="openRequestUpdate.emit(true)"
        [readonly]="readOnly"
        [size]="size"
        [showRequestButton]="showRequestButton"
      ></single-first-impression-card>
      <div class="e11-h-[400px]" *ngIf="showPlayer && userMode === enumUserMode.Public">
        <video-player
          *ngIf="showPlayer && userMode === enumUserMode.Public"
          [playerSize]="playerSizeEnum.Small"
          [videoPlayerResource]="videoPlayerResource"
          (playerClose)="closeVideoModal()"
        >
        </video-player>
      </div>
    </div>

    <div *ngIf="!loaded" class="e11-panel-skeleton"></div>
    <e11-modal
      #modalItem
      width="xl"
      [bodyHeight]="'large'"
      bodyClassOverrides="e11-px-0 e11-py-0"
      [showHeader]="false"
      [hideCloseButton]="false"
      [closer]="true"
      [showFooter]="false"
      (modalClosed)="closeVideoModal()"
    >
      <div modal-body class="video-container e11-h-full">
        <video-player *ngIf="showPlayer" [playerSize]="playerSizeEnum.Small" [videoPlayerResource]="videoPlayerResource"></video-player>
      </div>
    </e11-modal>
  `,
  styleUrls: ['./single-first-impression.component.scss'],
})
export class SingleFirstImpressionComponent implements OnInit, OnChanges {
  @Input() firstImpression?: IFirstImpression | null
  @Input() userMode: UserMode = UserMode.Candidate
  @Input() size?: Size | undefined
  @Input() readOnly = false
  @Input() openRequest?: RequestedUpdateFields
  @Input() showRequestButton = true

  @Output() openRequestUpdate: EventEmitter<boolean> = new EventEmitter<boolean>()
  @ViewChild('modalItem') modal!: E11ModalComponent

  signedFirstImpression?: IFirstImpression
  playerSizeEnum = PlayerSize
  loaded = false
  showPlayer = false
  videoPlayerResource!: { thumb?: string; video?: string }
  enumUserMode = UserMode

  constructor(private filesService: FilesService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.loadAndSignFirstImpression()
  }

  async ngOnInit(): Promise<void> {
    this.loadAndSignFirstImpression()
  }

  async loadAndSignFirstImpression() {
    if (this.firstImpression) {
      this.firstImpression = deepCopy(this.firstImpression) // Need this because signThumbnail mutates the original state
      this.signedFirstImpression = await this.signThumbnail(this.firstImpression!) // The input data could be overwritten
    } else {
      this.signedFirstImpression = undefined
    }
    this.loaded = true
  }

  async signThumbnail(firstImpression: IFirstImpression): Promise<IFirstImpression> {
    let fileReceipts = new Array<IFileReceipt>()
    if (firstImpression.thumb) {
      fileReceipts = await this.filesService.signedURLs([firstImpression.thumb])
      firstImpression.thumb.signedURL = fileReceipts[0].signedURL
    }
    return firstImpression
  }

  openVideoPlayer(firstImpression: IFirstImpression) {
    if (firstImpression.thumb?.signedURL) {
      this.videoPlayerResource = {
        thumb: firstImpression.thumb.signedURL,
      }

      if (firstImpression.video) {
        this.filesService.signedURLs([firstImpression.video]).then(val => {
          if (val.length > 0 && val[0].signedURL) {
            this.videoPlayerResource.video = val[0].signedURL
            this.videoPlayerResource = Object.assign({}, this.videoPlayerResource)
          }
        })
      }

      if (this.userMode !== this.enumUserMode.Public) {
        this.modal.open()
      }
      this.showPlayer = true
    }
  }

  closeVideoModal() {
    this.showPlayer = false

    this.videoPlayerResource = { thumb: undefined, video: undefined }
  }
}

<form class="e11-h-full" (ngSubmit)="onSubmit()" [formGroup]="jobSearchForm" novalidate>
  <h3 class="sm:e11-hidden">{{ 'Job Search' | translate }}</h3>

  <div class="e11-w-full e11-rounded-md e11-bg-skin-primary-accent e11-px-6 e11-py-4 e11-h-32 sm:e11-h-20">
    <div class="e11-w-full sm:e11-hidden e11-mb-4">
      <e11-input
        [clearIcon]="true"
        [leadingIcon]="'search'"
        [parentForm]="jobSearchForm"
        [placeholder]="'Job title, company, or keywords' | translate"
        [size]="'md'"
        [type]="'text'"
        [isRounded]="true"
        borderGlow="primary"
        formControlName="query"
        id="input_query"
        name="query"
      >
      </e11-input>
    </div>
    <div class="e11-w-full e11-flex">
      <div class="e11-hidden sm:e11-flex">
        <h3 class="e11-break-keep e11-text-skin-white lg:e11-mr-4 e11-w-[160px]">{{ 'Job Search' | translate }}</h3>
      </div>
      <div class="e11-grow e11-hidden sm:e11-flex e11-mr-2">
        <e11-input
          [leadingIcon]="'search'"
          [parentForm]="jobSearchForm"
          [placeholder]="'Job title, company, or keywords' | translate"
          [isRounded]="true"
          borderGlow="primary"
          formControlName="query"
          id="input_query"
          name="query"
          class="e11-w-full"
        >
        </e11-input>
      </div>
      <div class="e11-mr-2 e11-grow sm:e11-shrink">
        <e11-input
          [parentForm]="jobSearchForm"
          [placeholder]="'City, state, or zip' | translate"
          [leadingIcon]="'place'"
          [isRounded]="true"
          borderGlow="primary"
          formControlName="address"
          id="input_address"
          name="address"
        >
        </e11-input>
      </div>
      <div>
        <e11-button
          [color]="''"
          [display]="'block'"
          [style]="'e11-bg-skin-white'"
          [fontColor]="'e11-text-skin-primary-accent'"
          containerClassOverrides="e11-bg-skin-white e11-text-skin-primary-accent"
          [buttonStyle]="'pill'"
          [type]="'submit'"
          [value]="'Search' | translate"
        ></e11-button>
      </div>
    </div>
  </div>
  <ng-container *ngrxLet="jobSearchResult$ as searchResult">
    <div class="e11-flex lg:e11-hidden e11-w-full e11-justify-end">
      <span class="e11-text-sm e11-text-skin-light"> Search | {{ searchResult?.totalResults ?? 0 }} {{ 'Results' | translate }}</span>
    </div>
  </ng-container>
</form>

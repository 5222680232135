export function getPandoUTMFromQuery(params: URLSearchParams) {
  const lowerCaseParams = new URLSearchParams()
  for (const key of params.keys()) {
    lowerCaseParams.append(key.toLowerCase(), params.get(key)!)
  }
  return {
    source: lowerCaseParams.get('source') ?? '',
    integration: lowerCaseParams.get('integration') ?? '',
    integrationId: lowerCaseParams.get('integrationid') ?? '',
  }
}

// @TODO move to Util, keeping it here for easy cherry peak
export function convertKeysToLowerCase<T>(obj: T): T {
  if (!obj || typeof obj !== 'object') {
    return obj // Return as is if the input is not an object
  }

  const convertedObj: Partial<T> = {}

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const lowerCaseKey = key.toLowerCase()
      convertedObj[lowerCaseKey as keyof T] = obj[key]
    }
  }

  return convertedObj as T
}

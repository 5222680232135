export enum Platform {
  dev = "cnect-candidate-dev",
  qa = "cnect-candidate-qa",
  prod = "cnect-candidate",
}

export enum Module {
  landing = "landing",
  authentication = "authentication",
  registration = "registration",
  jobs = "jobs",
  users = "users",
  public = "public",
}

export enum View {
  home = "home",
  view2 = "view2",
  termsOfUse = "terms_of_use",
  authentication = "authentication",
  authenticationEmailVerification = "authentication_email_verification",
  authenticationFindAccount = "authentication_find_account",
  authenticationPasswordReset = "authentication_password_reset",
  authenticationVerificationCode = "authentication_verification-code",
  registration = "registration_register",
  registrationCreatePassword = "registration_create_password",
}

export enum AnalyticsEvents {
  pageView = "page_view",
  login = "login",
  authentication = "authenticate",
  accountRecovery = "account_recovery",
  passwordReset = "password_reset",
  registration = "registration",
}

export enum UserAction {
  buttonClick = "button_click",
  formSubmit = "form_submit",
  specialAction = "special_action",
}

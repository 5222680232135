import { Component, EventEmitter, Output, inject } from '@angular/core'
import { environment } from '@candidate/environments/environment'
import { isNotNil } from '@engineering11/utility'
import { E11Logger } from '@engineering11/web-api-error'
import { ViewportService } from '@engineering11/web-utilities'
import { filter, map } from 'rxjs/operators'

// ! components prefixed with `ui` are for display only and are not intended to contain complex logic

@Component({
  selector: 'ui-modal-intro',
  template: `
    <div class="content e11-relative e11-z-1">
      <div class="e11-block sm:e11-flex">
        <!-- LEFT -->
        <div class="e11-w-full sm:e11-max-w-[212px] e11-mb-4 sm:e11-mb-0 e11-block sm:e11-flex e11-shadow-md">
          <div class="e11-p-4 e11-bg-skin-primary-accent e11-rounded e11-h-full">
            <h3 class="e11-text-skin-white e11-mb-2">{{ 'Mobile App' | translate }}</h3>
            <p class="e11-text-skin-white e11-text-sm">
              {{
                'Did you know the power of Cnect is available in a mobile app? Download the Cnected app to easily manage your applications and network, communicate with employers, and receive real-time notifications about your applications all from the palm of your hand!'
                  | translate
              }}
            </p>

            <div class="e11-block">
              <div class="e11-grid e11-grid-cols-2 sm:e11-block">
                <div class="e11-mr-4 sm:e11-mr-0">
                  <a href="https://apps.apple.com/us/app/cnected/id6467040985" target="_blank">
                    <img src="../../../../../assets/images/ios_app_store_badge.svg" class="e11-mb-2 e11-mx-auto" />
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=com.e11.prod.cnect" target="_blank">
                    <img src="../../../../../assets/images/google_play_badge.svg" class="e11-mb-2 e11-mx-auto" />
                  </a>
                </div>
                <div>
                  <qrcode
                    [qrdata]="appDownloadDynamicLink"
                    [errorCorrectionLevel]="'M'"
                    [width]="(isMobile$ | async) ? 120 : 180"
                    [ariaLabel]="'Download the Cnect mobile application from the app store.' | translate"
                    [title]="'Download the Cnect mobile application from the app store.' | translate"
                  >
                  </qrcode>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Right - Slides -->
        <div
          *ngrxLet="isMobile$ as isMobile"
          class="slides gradient e11-flex e11-px-2 e11-grow e11-pt-0 sm:e11-p-4 sm:e11-pt-12"
          [ngClass]="{ 'e11-ml-0': isMobile, 'e11-ml-2': !isMobile }"
        >
          <!-- Slide 1 -->
          <div class="slide slide-one" [ngClass]="{ 'slide-active': slide === 1, 'e11-pt-4': isMobile }">
            <h1 class="e11-mb-2 sm:e11-mb-8 e11-text-center e11-text-skin-white e11-text-2xl sm:e11-text-4xl">
              {{ 'Tell Your Story' | translate }}
            </h1>
            <p class="e11-text-sm sm:e11-text-base sm:e11-px-1 e11-font-medium e11-text-skin-white e11-text-left e11-px-2">
              {{
                "First impressions are everything, especially when it comes to taking that next step in your career. Cnect's First Impression Videos let you showcase your personality, communication skills, and enthusiasm for employers and introduce yourself on your terms."
              }}
            </p>
          </div>
          <!-- Slide 2 -->
          <div class="slide slide-two" [ngClass]="{ 'slide-active': slide === 2, 'e11-pt-4': isMobile }">
            <h1 class="e11-mb-2 sm:e11-mb-8 e11-text-center e11-text-skin-white e11-text-2xl sm:e11-text-4xl">
              {{ 'Manage Your Profiles' | translate }}
            </h1>
            <p class="e11-text-sm sm:e11-text-base sm:e11-px-1 e11-font-medium e11-text-skin-white e11-text-left e11-px-2">
              {{
                "Resumes don't stand out, and most of the time they all look the same. Plus, trying to fit everything on a single piece of paper about you and your work experience will be impossible. With Cnect, you can build and manage digital profiles that can be used when applying to jobs. You can even use Cnect’s resume parser to quickly upload your existing resumes for a jumpstart on building your profiles! These profiles can also be shared digitally as well as downloaded as a PDF for those companies that require you to attach a resume."
              }}
            </p>
          </div>

          <div class="slide slide-three" [ngClass]="{ 'slide-active': slide === 3, 'e11-pt-4': isMobile }">
            <h1 class="e11-mb-2 sm:e11-mb-8 e11-text-center e11-text-skin-white e11-text-2xl sm:e11-text-4xl">
              {{ 'Submit and Manage Applications' | translate }}
            </h1>
            <p class="e11-text-sm sm:e11-text-base sm:e11-px-1 e11-font-medium e11-text-skin-white e11-text-left e11-px-2">
              {{
                "Applying to jobs is quick and easy with Cnect. You can build an application from scratch, or choose to populate it from one of your existing profiles or an uploaded resume file. You'll always be able to track your in-progress and submitted applications, as well as view the application history to quickly see the status and last actions taken."
              }}
            </p>
          </div>

          <div class="slide slide-four" [ngClass]="{ 'slide-active': slide === 4, 'e11-pt-4': isMobile }">
            <h1 class="e11-mb-2 sm:e11-mb-8 e11-text-center e11-text-skin-white e11-text-2xl sm:e11-text-4xl">
              {{ 'Communicate With Employers' | translate }}
            </h1>
            <p class="e11-text-sm sm:e11-text-base sm:e11-px-1 e11-font-medium e11-text-skin-white e11-text-left e11-px-2">
              {{
                'After submitting your application, communication with employers is easy and happens directly in the Cnect app. Employers and candidates can message back and forth, as well as send and receive attachments and videos. Employers can also request updates for specific or missing pieces of an application, which you can quickly see and fulfill using the app.'
              }}
            </p>
          </div>

          <div class="slide slide-five" [ngClass]="{ 'slide-active': slide === 5, 'e11-pt-4': isMobile }">
            <h1 class="e11-mb-2 sm:e11-mb-8 e11-text-center e11-text-skin-white e11-text-2xl sm:e11-text-4xl">
              {{ 'Engage In Digital Interviewing' | translate }}
            </h1>
            <p class="e11-text-sm sm:e11-text-base sm:e11-px-1 e11-font-medium e11-text-skin-white e11-text-left e11-px-2">
              {{
                " Using Cnect's digital Interview Sessions creates an interactive and convenient interview experience that seamlessly fits into our modern lives, and helps establish a genuine connection between you and the employer. Employers send over text-based and video-based questions that you can respond to using the app, when it's convenient for you."
              }}
            </p>
          </div>

          <div class="slide slide-six" [ngClass]="{ 'slide-active': slide === 6, 'e11-pt-4': isMobile }">
            <h1 class="e11-mb-2 sm:e11-mb-8 e11-text-center e11-text-skin-white e11-text-2xl sm:e11-text-4xl">
              {{ 'Build a Private Network' | translate }}
            </h1>
            <p class="e11-text-sm sm:e11-text-base sm:e11-px-1 e11-font-medium e11-text-skin-white e11-text-left e11-px-2">
              {{
                "Curate and message with a personal and private network using Cnect. It's easy to invite your friends, family, and coworkers to connect with you and, unlike other job sites, your network and connections are completely private and unsearchable, so no one will know you are on the platform unless you want them to."
              }}
            </p>
          </div>

          <div class="controls e11-block e11-absolute e11-bottom-4 e11-w-[95%]">
            <div class="e11-flex e11-w-full e11-justify-center">
              <span
                (click)="navigateSlides(slide - 1)"
                class="e11-cursor-pointer e11-inline-flex e11-items-center hover:e11-bg-skin-grey/20 e11-p-2 e11-rounded"
                [ngClass]="{
                  'e11-pointer-events-none e11-cursor-default e11-opacity-70 e11-text-skin-white': slide === 1,
                  'e11-text-skin-secondary': slide !== 1
                }"
              >
                <span class="material-icons-outlined"> arrow_back_ios </span>
              </span>

              <!-- Slide Indicators -->
              <div class="e11-flex e11-items-center e11-justify-center">
                <span
                  (click)="slide = i + 1"
                  class="e11-h-2 e11-w-2 e11-rounded-full e11-mx-1 e11-cursor-pointer"
                  *ngFor="let i of slides | range"
                  [ngClass]="{
                    'e11-bg-skin-secondary hover:e11-bg-skin-primary': i + 1 === slide,
                    'e11-bg-skin-white  hover:e11-bg-skin-grey': i + 1 !== slide
                  }"
                ></span>
              </div>

              <span
                (click)="navigateSlides(slide + 1)"
                class="e11-cursor-pointer e11-inline-flex e11-items-center hover:e11-bg-skin-grey/20 e11-p-2 e11-rounded"
                [ngClass]="{
                  'e11-pointer-events-none e11-opacity-70 e11-cursor-default e11-text-skin-white': slide === slides,
                  'e11-text-skin-secondary': slide !== slides
                }"
              >
                <span class="material-icons-outlined"> arrow_forward_ios </span>
              </span>
            </div>
            <div class="e11-w-full e11-flex e11-justify-center">
              <e11-input-checkbox
                [size]="'sm'"
                label="{{ 'Do not show again' | translate }}"
                [color]="'primary-accent'"
                [fontColor]="'e11-text-skin-white'"
                (change)="showTipsCheckbox($event)"
              ></e11-input-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./ui-modal-intro.component.scss'],
})
export class UIModalIntroComponent {
  slides: number = 6
  slide: number = 1

  isMobile$ = this.viewportService.viewportSizeChanged$.pipe(
    map(data => data == 'xs'),
    filter(isNotNil)
  )

  appDownloadDynamicLink = environment.appDownloadDynamicLink

  @Output() dontShowAgainChecked: EventEmitter<boolean> = new EventEmitter<boolean>()

  #logger = inject(E11Logger)

  constructor(private viewportService: ViewportService) {}

  navigateSlides(slide: number) {
    this.slide = slide
    // Don't infinitely rotate. Dead end so the user knows there is a limited number of slides
    this.slide > this.slides ? (this.slide = this.slides) : null
    this.slide < 1 ? (this.slide = 1) : null
  }

  showTipsCheckbox(event: any) {
    this.dontShowAgainChecked.emit(event.target.checked)
  }
}

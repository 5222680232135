<div class="e11-w-full e11-flex e11-flex-row e11-flex-wrap e11-mb-4">
  <div class="e11-w-full e11-flex e11-pb-2 e11-mb-4">
    <div class="e11-flex e11-w-full e11-items-center e11-border-b e11-border-skin-app-borders" *ngIf="candidate">
      <div class="e11-flex e11-w-full e11-items-center e11-mb-2">
        <div class="e11-grow">
          <h4 class="e11-font-skin-secondary e11-font-bold">{{ candidate.firstName }} {{ candidate.lastName }}</h4>
        </div>
      </div>
    </div>

    <!-- Skeleton -->
    <h4 *ngIf="!candidate"></h4>
  </div>

  <div class="e11-mr-0 md:e11-mr-4">
    <e11-profile-pic [imageUrl]="candidate?.photoURL" [size]="'xxxl'" [type]="'circular'"></e11-profile-pic>
  </div>
  <div class="e11-flex-grow e11-flex e11-flex-col e11-gap-1">
    <div class="e11-flex e11-flex-row e11-gap-2">
      <div *ngIf="candidate && !fromCandidate" class="rating">
        <span *ngFor="let star of makeStarRatingArray($any(candidate).starRating); let i = index" class="stars">
          <i class="material-icons e11-text-skin-secondary e11-text-lg">star</i>
        </span>
      </div>
    </div>
    <div *ngIf="!candidate || candidate.email || !hideEmptyCards" class="e11-flex e11-items-center">
      <span class="material-icons-outlined e11-mr-2 md-18"> email </span>
      <span *ngIf="!candidate || candidate.email" class="e11-truncate e11-skeleton-empty">{{ candidate?.email }}</span>
      <span *ngIf="candidate && !candidate.email && !hideEmptyCards" class="e11-text-skin-light e11-italic e11-relative e11-top-[2px]">
        {{ 'No email' | translate }}
      </span>
    </div>
    <div *ngIf="!candidate || candidate.phoneNumber || !hideEmptyCards" class="e11-flex e11-items-center e11-truncate">
      <span class="material-icons-outlined e11-mr-2 md-18"> smartphone </span>
      <span *ngIf="!candidate || candidate.phoneNumber" class="e11-truncate e11-skeleton-empty">{{ candidate?.phoneNumber }}</span>
      <span *ngIf="candidate && !candidate.phoneNumber && !hideEmptyCards" class="e11-text-skin-light e11-italic e11-relative e11-top-[2px]">
        {{ 'No phone number' | translate }}
      </span>
    </div>
    <div *ngIf="!candidate || candidate?.address || !hideEmptyCards" class="e11-flex e11-items-center e11-truncate">
      <span class="material-icons-outlined e11-mr-2 md-18"> cottage </span>
      <span *ngIf="!candidate" class="e11-skeleton-empty"></span>
      <span
        *ngIf="candidate"
        [ngClass]="{
          'e11-text-skin-light e11-italic e11-relative e11-top-[2px] e11-truncate':
            !candidate?.address || (!candidate?.address?.city && candidate?.address?.state)
        }"
      >
        <span *ngIf="candidate?.address?.city">{{ candidate?.address?.city }}</span>
        <span *ngIf="candidate?.address?.state">, {{ candidate?.address?.state }}</span>
        <span *ngIf="!candidate?.address && !hideEmptyCards"> {{ 'Address not added.' | translate }} </span>
      </span>
    </div>
    <div class="e11-flex e11-items-center">
      <span class="material-icons-outlined e11-mr-2 md-18"> launch </span>
      <span *ngIf="!candidate" class="e11-skeleton-empty"></span>
      <a
        *ngIf="candidate?.website || !hideEmptyCards"
        class="e11-flex e11-items-center e11-truncate"
        href="{{ candidate?.website ?? '#' }}"
        target="_blank"
      >
        <span *ngIf="candidate" class="e11-cursor-pointer hover:e11-text-skin-primary-accent e11-truncate">
          <span>{{ candidate?.website }}</span>
        </span>
      </a>
      <span
        *ngIf="candidate && !candidate.website && !hideEmptyCards"
        class="e11-text-skin-light e11-italic e11-relative e11-top-[2px] e11-truncate e11-line-clamp-1"
      >
        {{ 'No website' | translate }}
      </span>
    </div>

    <!-- Military -->
    <div *ngIf="candidate && candidate.militaryAffiliation && candidate.militaryAffiliation.length > 0" class="e11-flex e11-items-center">
      <span class="e11-text-sm e11-mb-0 e11-truncate">{{ 'Military Affiliations' | translate }}</span>
      <span *ngIf="!candidate.militaryAffiliation" class="e11-text-skin-light e11-italic e11-relative e11-top-[2px]">
        {{ 'None' | translate }}
      </span>
    </div>
    <!-- Military tags -->
    <ng-container *ngIf="candidate && candidate.militaryAffiliation && candidate.militaryAffiliation.length > 0">
      <div class="e11-inline-flex">
        <e11-tag [value]="ma" [size]="'xs'" [color]="'neutral'" *ngFor="let ma of candidate?.militaryAffiliation; let i = index"></e11-tag>
      </div>
    </ng-container>

    <!-- Confirmations -->
    <!-- @Todo: Are these out of place here. Shouldn't these be application specific and not grouped with personal details? -->
    <job-application-confirmations
      *ngIf="candidate?.candidateResponses"
      [responses]="candidate!.candidateResponses"
      [userMode]="userMode"
    ></job-application-confirmations>
  </div>
</div>

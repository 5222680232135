import { ICandidateProfileContentItem } from '@candidate/app/models/candidate-profile/candidate-profile.model'
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { ProfileActions, ProfileActionTypes } from './profile.actions'

export interface ProfileState extends EntityState<ICandidateProfileContentItem> {
  loading: boolean
  errors: any[]
  candidateProfile: ICandidateProfileContentItem | null
}

export const adapter: EntityAdapter<ICandidateProfileContentItem> = createEntityAdapter<ICandidateProfileContentItem>()

export const initialState: ProfileState = adapter.getInitialState({
  loading: false,
  errors: [],
  candidateProfile: null,
})

export function reducer(state = initialState, action: ProfileActions): ProfileState {
  switch (action.type) {
    case ProfileActionTypes.getProfile:
      return { ...state, loading: true }
    case ProfileActionTypes.getProfileSuccess:
      return { ...state, candidateProfile: action.payload, loading: false }
    case ProfileActionTypes.updateProfileSuccess:
      return { ...state, candidateProfile: action.payload }
    case ProfileActionTypes.reset:
      return { ...initialState }
    case ProfileActionTypes.error:
      return { ...state, errors: action.payload }
    default:
      return state
  }
}

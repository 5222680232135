import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { INotificationHandler, INotificationView, UserAppNotificationService } from '@engineering11/notifications-web'
import { UserMentionedNotification } from 'shared-lib'

@Injectable({ providedIn: 'root' })
export class MessagingUserMentionedHandler implements INotificationHandler<UserMentionedNotification, INotificationView> {
  notificationType: string = 'messaging_user_mentioned'
  constructor(private router: Router, private userAppNotificationService: UserAppNotificationService) {}

  onClick(notification: UserMentionedNotification): boolean | Promise<boolean> {
    if (!notification.viewed) {
      this.userAppNotificationService.markNotificationAsRead(notification.id)
    }
    return this.router.navigate(['/c/m/messaging/conversation/', notification.conversationId])
  }
}

import { Component, Input } from '@angular/core'
import { CnectFeatures, ConversationTypes } from 'shared-lib'

@Component({
  selector: 'nav-item-conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.scss'],
})
export class ConversationComponent {
  @Input() features: CnectFeatures | undefined

  ConversationTypes = ConversationTypes
}

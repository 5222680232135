import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { LetDirective } from '@ngrx/component'
import { UiComponentLibraryModule } from '../_component-library/component-library.module'
import { SharedModule } from '../_shared/shared.module'
import { QuickApplyButtonComponent } from './components/quick-apply-button/quick-apply-button.component'
import { SearchFormComponent } from './components/search-form/search-form.component'
import { SearchResultDetailComponent } from './components/search-result-detail/search-result-detail.component'
import { SearchResultLineItemComponent } from './components/search-result-list/search-result-line-item.component'
import { SearchResultListComponent } from './components/search-result-list/search-result-list.component'
import { JobsComponent } from './jobs.component'
import { JobsRoutingModule } from './jobs.routing'
import { JobsSearchComponent } from './views/search/search.component'

@NgModule({
  declarations: [
    JobsComponent,
    JobsSearchComponent,
    SearchResultLineItemComponent,
    SearchResultListComponent,
    SearchFormComponent,
    SearchResultDetailComponent,
    QuickApplyButtonComponent,
  ],
  imports: [CommonModule, SharedModule, JobsRoutingModule, UiComponentLibraryModule, FormsModule, ReactiveFormsModule, LetDirective],
  exports: [],
  providers: [],
})
export class JobsModule {}

import { Injectable } from '@angular/core'
import { ICandidateUser } from '@cnect/user-shared'
import { AtLeast } from '@engineering11/types'
import { isNotNil } from '@engineering11/utility'
import { Store } from '@ngrx/store'
import { Observable, firstValueFrom } from 'rxjs'
import { delay, filter, map, mergeMap, take } from 'rxjs/operators'
import { getCurrentToken, getCurrentUser, IJobApplicationStage } from 'shared-lib'
import { CandidateApplicationStageRepository } from './candidate-application-stage.repository'
import { RestApiClient } from '@engineering11/web-api-rest'
import { environment } from '@candidate/environments/environment'
import { ICandidateResumeData } from '@candidate/app/models/candidate-resume.model'

// Need required IDs, anything else optional
type IJobApplicationStageSeed = Omit<AtLeast<IJobApplicationStage, 'jobPostId' | 'candidateId'>, 'id'>

@Injectable({ providedIn: 'root' })
export class CandidateApplicationStageService {
  private restApiClient: RestApiClient = new RestApiClient({
    baseUrl: environment.services.jobs,
    token: this.store.select(getCurrentToken).pipe(filter(isNotNil)),
  })
  constructor(private candidateApplicationStageRepository: CandidateApplicationStageRepository, private store: Store) {}

  getByUserAndJobPost(userId: string, jobPostId: string): Observable<IJobApplicationStage | undefined> {
    const id = buildApplicationStageId({ candidateId: userId, jobPostId })
    return this.candidateApplicationStageRepository.get(id)
  }

  create(model: IJobApplicationStageSeed): Observable<IJobApplicationStage> {
    return this.store.select(getCurrentUser).pipe(
      filter(isNotNil),
      take(1), // Prevent updates to user from refiring stage updates
      map(user => this.getDefaultData(model.jobPostId, user, model)),
      mergeMap(applicationStage => this.candidateApplicationStageRepository.add(applicationStage))
    )
  }

  upsert(model: Omit<IJobApplicationStage, 'id'>) {
    const modelToSave: IJobApplicationStage = {
      ...model,
      id: buildApplicationStageId(model),
    }
    return this.candidateApplicationStageRepository.upsert(modelToSave)
  }

  update(model: AtLeast<IJobApplicationStage, 'jobPostId' | 'candidateId'>) {
    const modelToSave = { ...model, id: buildApplicationStageId(model) }
    return this.candidateApplicationStageRepository.update(modelToSave)
  }

  async selectResume(resumeData: ICandidateResumeData, jobPostId: string): Promise<IJobApplicationStage> {
    const res = await firstValueFrom(this.restApiClient.put('candidate-application-stage/select-resume', { resumeData, jobPostId }))
    return res.data
  }

  deleteByUserAndJob(userId: string, jobPostId: string) {
    const id = buildApplicationStageId({ candidateId: userId, jobPostId })
    return this.candidateApplicationStageRepository.delete(id)
  }

  // Need enough to seed a valid application with data without selecting a resume
  private getDefaultData(jobPostId: string, user: ICandidateUser, seedData?: IJobApplicationStageSeed): IJobApplicationStage {
    // TODO: Consider how to get static typing for this
    const dataFromUser = {
      candidateId: user.id,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      //TODO: photoURL: user.photoURL,
    }

    return {
      workHistory: [],
      education: [],
      certifications: [],
      skills: [],
      portfolio: [],
      militaryAffiliation: [],
      references: [],
      languages: [],
      firstImpression: null,
      ...dataFromUser,
      ...seedData,
      id: buildApplicationStageId({ candidateId: user.id, jobPostId }),
      jobPostId,
    }
  }
}

export function buildApplicationStageId(idData: Pick<IJobApplicationStage, 'jobPostId' | 'candidateId'>) {
  return `${idData.candidateId}_${idData.jobPostId}`
}

export interface SelectResumeRequest {
  jobPostId: string
  resumeId: string
}

<div class="view e11-min-h-full e11-flex e11-flex-col e11-justify-center e11-py-6 e11-px-6">
  <div class="sm:e11-mx-auto sm:e11-w-full sm:e11-max-w-md">
    <div class="e11-w-full e11-flex e11-justify-center">
      <img [src]="logoUrl" class="e11-h-24" />
    </div>
    <h1 class="e11-text-skin-primary-accent e11-mb-4 e11-text-center">
      {{ 'Account Deactivated' | translate }}
    </h1>
  </div>

  <div class="sm:e11-mx-auto sm:e11-w-full sm:e11-max-w-md">
    <e11-panel [bgOpacity]="90">
      <div class="e11-w-full e11-text-center">
        <span class="material-icons-outlined e11-text-skin-success e11-text-6xl e11-mb-8"> task_alt </span>
        <h3 class="e11-mb-4">{{ 'Thank you for choosing Cnect' | translate }}</h3>
        <p class="e11-mb-4">{{ 'Your account has been completely deactivated and will no longer be available.' }}</p>
        <p class="e11-mb-12">{{ 'We hope you had a pleasant experience and that we can be of service to you in the near future.' }}</p>
      </div>
    </e11-panel>
  </div>
</div>

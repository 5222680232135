<e11-modal #modalItem [width]="'md'" [title]="headerTitle" [showHeader]="true" [closer]="true" [showFooter]="true" (onClose)="showTemplate = false">
  <div modal-body *ngIf="showTemplate">
    <ng-container *ngTemplateOutlet="uiTemplates['content']"></ng-container>
  </div>
  <div modal-footer>
    <div class="row">
      <div class="col-xs-12 e11-flex e11-justify-center">
        <e11-button
          *ngIf="showDeleteButton"
          color="secondary"
          [type]="'button'"
          value="{{ 'Delete' | translate }}"
          [icon]="'delete'"
          iconPosition="left"
          [buttonGroup]="true"
          [ghost]="true"
          (click)="removeItem.emit()"
        >
        </e11-button>
        <e11-button [value]="'Cancel' | translate" color="neutral" [ghost]="true" (click)="modalItem.close()" [buttonGroup]="true"> </e11-button>
        <e11-button *ngIf="showSubmitButton" (click)="submitItem.emit()" value="{{ 'Save' | translate }}" color="secondary" [buttonGroup]="true">
        </e11-button>
      </div>
    </div>
  </div>
</e11-modal>

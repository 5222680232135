import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { ICandidateResumeData } from '@candidate/app/models/candidate-resume.model'
import { stageHasResumeData, stageToSubmission } from '@candidate/app/services/candidate-application/application.util'
import { CandidateHomeNavigationService } from '@candidate/app/services/candidate-home-navigation.service'
import { ApplicationChooseResume, SubmitJobApplication } from '@candidate/app/store/application/application.actions'
import { getApplicationStageForSelectedJob } from '@candidate/app/store/application/application.selectors'
import { CreateCandidateJob, GetAllJobs, SelectJob } from '@candidate/app/store/job/job.actions'
import { getJobLoadingState, getSelectedJob } from '@candidate/app/store/job/job.selectors'
import { getResumeEntities, isGetAllResumesLoaded } from '@candidate/app/store/resume/resume.selectors'
import { selectors } from '@candidate/app/store/selectors'
import { convertKeysToLowerCase, getPandoUTMFromQuery } from '@candidate/app/util/pando-utm-from-params'
import { E11BackdropService } from '@engineering11/ui-lib/e11-backdrop'
import { isDeepEqual, isNotNil } from '@engineering11/utility'
import { E11Logger } from '@engineering11/web-api-error'
import { ViewportService } from '@engineering11/web-utilities'
import { select, Store } from '@ngrx/store'
import { UiScrollLockingService } from 'projects/shared-lib/src/lib/service/scroll-locking.service'
import { combineLatest, Observable, Subject } from 'rxjs'
import { distinctUntilChanged, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators'
import {
  CandidateJobStatus,
  CnectFeatures,
  ConfigStore,
  ICandidateJobSubmission,
  ICandidateJobVM,
  ICandidateSource,
  IJobApplicationStage,
} from 'shared-lib'
import { ApplicationConfirmationModalComponent } from '@candidate/app/modules/applications/components/application-confirmation-modal/application-confirmation-modal.component'

export enum ApplicationPage {
  JobDescription = 'Job Description',
  PopulateApplication = 'Pre-populate Application',
  EditApplication = 'Edit Application',
  CandidateResponses = 'Confirmations',
  ApplicationReview = 'Review & Submit',
}

/**
 * TODO: Refactor to use the store for all state - not this half-in half-out approach.
 */

@Component({
  selector: 'apply',
  templateUrl: './apply.component.html',
  styleUrls: ['./apply.component.scss'],
})
export class ApplyComponent implements OnInit, OnDestroy {
  @ViewChild('applicationConfirmationModal') applicationConfirmationModal?: ApplicationConfirmationModalComponent

  @Output() applicationClosed: EventEmitter<boolean> = new EventEmitter()

  candidateSource?: ICandidateSource
  selectedJob$: Observable<ICandidateJobVM | undefined> = this.store.select(getSelectedJob) // TODO: Add a loader
  selectedApplication$: Observable<IJobApplicationStage | undefined> = this.store.select(getApplicationStageForSelectedJob)
  selectedApplication?: IJobApplicationStage

  features$: Observable<CnectFeatures | undefined> = this.configStore.features$
  featuresLoaded$: Observable<boolean> = this.configStore.staticConfigLoaded$
  resumes$ = this.store.select(getResumeEntities)
  resumesLoaded$ = this.store.select(isGetAllResumesLoaded)

  // showPopulateApplication determines whether to enable PopulateApplication at all. skipPopulateApplication determines whether to jump to the next page
  showPopulateApplication$ = combineLatest([this.featuresLoaded$, this.resumesLoaded$]).pipe(
    filter(([featuresLoaded, resumesLoaded]) => featuresLoaded && resumesLoaded),
    distinctUntilChanged(),
    switchMap(_ => combineLatest([this.resumes$, this.features$])),
    map(([resumes, features]) => resumes.length || features?.resumeParsing)
  )
  skipPopulateApplication$ = this.selectedApplication$.pipe(
    filter(isNotNil),
    map(selectedApplication => stageHasResumeData(selectedApplication))
  )

  destroy$: Subject<boolean> = new Subject<boolean>()

  applicationPageEnum = ApplicationPage

  currentPage: ApplicationPage = ApplicationPage.EditApplication

  isMobile$ = this.viewportService.viewportSizeChanged$.pipe(
    map(data => data == 'sm' || data == 'xs'),
    filter(isNotNil)
  )

  selectedJobId$: Observable<string> = combineLatest([this.route.queryParams, this.route.params]).pipe(
    map(([queryParams, routeParams]) => routeParams.jobPostId || queryParams.jobPostId),
    filter(isNotNil),
    distinctUntilChanged(),
    map(jobPostId => jobPostId.split('?')[0]), // VERY important to filter out any query from the url
    tap(jobPostId => this.logger.log('ApplicationDetail jobPostId: ', jobPostId)),
    takeUntil(this.destroy$)
  )

  candidateJob$ = combineLatest([this.store.pipe(select(selectors.getJobEntities)), this.store.select(getJobLoadingState), this.selectedJobId$]).pipe(
    filter(([_jobs, loadingJobs, _jobPostId]) => !loadingJobs),
    map(([jobs, _, jobPostId]) => {
      const routedJob = jobs.find(job => job.jobId === jobPostId)
      return { routedJob, jobPostId }
    }),
    distinctUntilChanged<{ routedJob?: ICandidateJobVM; jobPostId: string }>(isDeepEqual),
    takeUntil(this.destroy$)
  )

  candidateSource$ = this.listenCandidateSource()

  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private logger: E11Logger,
    private backdropService: E11BackdropService,
    private scrollLockService: UiScrollLockingService,
    private viewportService: ViewportService,
    private configStore: ConfigStore,
    private candidateHomeNavigationService: CandidateHomeNavigationService
  ) {
    this.store.dispatch(new GetAllJobs())
  }

  async ngOnInit(): Promise<void> {
    this.candidateJob$.pipe(takeUntil(this.destroy$)).subscribe(job => {
      if (job.routedJob && job.routedJob.status !== CandidateJobStatus.Viewed) this.candidateHomeNavigationService.jobApplication(job.jobPostId)
    })

    this.candidateJob$.subscribe(({ routedJob, jobPostId }) => {
      if (routedJob) {
        return this.store.dispatch(new SelectJob(routedJob.jobId))
      }
      this.store.dispatch(new CreateCandidateJob(jobPostId))
    })

    this.selectedApplication$.pipe(takeUntil(this.destroy$)).subscribe(selectedApplication => {
      this.selectedApplication = selectedApplication
    })

    combineLatest([this.showPopulateApplication$, this.skipPopulateApplication$])
      .pipe(take(1)) // ! Important this only runs once when the necessary information is loaded
      .subscribe(([showPopulateApplication, skipPopulateApplication]) => {
        if (showPopulateApplication) {
          this.currentPage = ApplicationPage.EditApplication
        }
      })

    this.candidateSource$.subscribe(candidateSource => {
      this.candidateSource = candidateSource
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  private listenCandidateSource(): Observable<ICandidateSource | undefined> {
    return combineLatest([this.route.queryParams, this.route.params]).pipe(
      map(([queryParams, routeParams]) => {
        const [, queryString] = routeParams['jobPostId']?.split('?') || []
        if (queryString && queryString.length) {
          const params = new URLSearchParams(queryString)
          return getPandoUTMFromQuery(params)
        } else {
          const { source, integration, integrationid } = convertKeysToLowerCase(queryParams)
          if (source || integration || integrationid) {
            return {
              source,
              integration,
              integrationId: integrationid,
            }
          }
        }
        return undefined
      })
    )
  }

  // TODO: Determine how side page navigation can be integrated into the displayPager without hacking it
  toSidePage(page: ApplicationPage) {
    this.currentPage = page
  }

  navigateToApplications() {
    this.candidateHomeNavigationService.application()
  }

  backFromSidePage() {
    this.currentPage = this.applicationPageEnum.EditApplication
    this.scrollUp()
  }

  scrollUp() {
    window.scroll(0, 0)
  }

  confirmSubmission() {
    this.applicationConfirmationModal?.open()
  }

  submitApplication(stagedApplication: IJobApplicationStage) {
    const jobSubmission: ICandidateJobSubmission = stageToSubmission(stagedApplication)
    //@ts-ignore - hack to prevent validation error on application submission

    this.store.dispatch(new SubmitJobApplication({ ...jobSubmission, candidateSource: this.candidateSource }))
    this.applicationClosed.emit(true) // ? do we need to emit this?
    this.backdropService.backdropDisplay(false, '')
    this.scrollLockService.scrollLock(false)
    this.candidateHomeNavigationService.application()
  }

  chooseResumeForApplication(resume: ICandidateResumeData) {
    this.store.dispatch(new ApplicationChooseResume(resume))
  }
}

import { Injectable } from '@angular/core'
import { ICandidateUpdateModel } from '@candidate/app/models/candidate-job-update.model'
import { environment } from '@candidate/environments/environment'
import { E11Logger } from '@engineering11/web-api-error'
import { RestApiClient } from '@engineering11/web-api-rest'
import { Observable, switchMap } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { getCurrentUserId, IProcessedJobApplication, TokenStorage } from 'shared-lib'
import { CandidateApplicationRepository } from './candidate-application.repository'
import { isNotNil } from '@engineering11/utility'
import { Store } from '@ngrx/store'

@Injectable({ providedIn: 'root' })
export class CandidateApplicationService {
  private restApiClient: RestApiClient
  userId$ = this.store.pipe(getCurrentUserId).pipe(filter(isNotNil))

  constructor(tokenStorage: TokenStorage, public repository: CandidateApplicationRepository, private logger: E11Logger, private store: Store) {
    this.restApiClient = new RestApiClient({
      baseUrl: environment.services.jobs,
      token: () => tokenStorage.getAccessToken(),
    })
  }

  updateCandidateApplication(applicationId: string, updates: ICandidateUpdateModel) {
    // const applicationId = this.buildJobApplicationId(jobPostId, userId)
    this.logger.log('Updating application with: ', { updates })
    return this.restApiClient.put(`candidate-job-application/${applicationId}`, updates)
  }

  getCandidateApplication(jobPostId: string): Observable<IProcessedJobApplication | null> {
    return this.userId$.pipe(
      switchMap(userId => {
        const id = this.buildJobApplicationId(jobPostId, userId)
        return this.repository.get(id).pipe(map(item => (item ? item : null)))
      })
    )
  }

  buildJobApplicationId(jobPostId: string, userId: string) {
    return `${jobPostId}_${userId}`
  }
}

import { Component, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { IEmployerDetailsVM } from '@candidate/app/models/employer/employer.model'
import { PublicEmployerService } from '@candidate/app/services/employer/employer-public.service'
import * as fromReduce from '@candidate/app/store/reducers'
import { IRegistrationResult } from '@engineering11/registration-web'
import { E11ModalComponent } from '@engineering11/ui-lib/e11-modal'
import { isNotNil, valueOf } from '@engineering11/utility'
import { E11Logger } from '@engineering11/web-api-error'
import { Store } from '@ngrx/store'
import { of } from 'rxjs'
import { catchError, filter, map, take } from 'rxjs/operators'
import { OnRegistration, SessionStorageService, TokenStorage } from 'shared-lib'

@Component({
  selector: 'app-register-refer',
  templateUrl: './register-refer.component.html',
  styleUrls: ['./register-refer.component.scss'],
})
export class RegisterReferComponent implements OnInit {
  @ViewChild('modalTerms') modalTerms!: E11ModalComponent

  logoUrl?: string | null
  defaultLogoUrl = '/assets/images/logo/cnect-blue.png'

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sessionStorage: SessionStorageService,
    private employerService: PublicEmployerService,
    private store: Store<fromReduce.user.State>,
    private logger: E11Logger,
    private tokenStorage: TokenStorage
  ) {}

  ngOnInit(): void {
    this.route.queryParams.pipe(map(valueOf('referrerId')), filter(isNotNil), take(1)).subscribe(referrerId => {
      this.sessionStorage.setItem('referrerId', referrerId)
    })

    this.route.queryParams.pipe(map(valueOf('customerKey')), take(1)).subscribe(customerKey => {
      if (!customerKey) {
        this.logoUrl = this.defaultLogoUrl
      } else {
        this.employerService
          .get(customerKey)
          .pipe(
            take(1),
            catchError(err => {
              return of({ logoImageURL: this.defaultLogoUrl } as IEmployerDetailsVM)
            })
          )
          .subscribe(customer => {
            this.logoUrl = customer?.logoImageURL || this.defaultLogoUrl
          })
      }
    })
  }

  async onSubmit(result: IRegistrationResult) {
    // This is here to avoid a race condition using OnLogin from the user store
    this.tokenStorage.setAccessToken(result.authToken)
    this.tokenStorage.setItem('user', JSON.stringify(result.appUser))

    if (result) {
      this.store.dispatch(new OnRegistration({ appUserId: result.appUser.id, queryParams: {} }))
      this.router.navigate(['/network/cnections'])
    }
  }

  termsClicked() {
    this.modalTerms.open()
  }

  navigateToLogin() {
    this.router.navigate(['/auth/login'], { queryParamsHandling: 'merge' })
  }
}

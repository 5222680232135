import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ICandidateResume } from '@candidate/app/models/candidate-resume.model'
import { Option } from '@engineering11/ui-lib/e11-overflow'
import { Timestamp } from '@engineering11/web-api-firebase'
import { Observable, map } from 'rxjs'
import { CnectFeatures, ConfigStore } from 'shared-lib'

export enum ProfileTabActions {
  share = 'share',
  pdf = 'pdf',
  rename = 'rename',
  delete = 'delete',
  manageShares = 'manageShares',
}

@Component({
  selector: 'profile-tab',
  template: `
    <div
      class="e11-flex e11-items-center e11-justify-evenly e11-border md:e11-justify-normal e11-px-2 e11-py-0.5 e11-cursor-pointer e11-rounded e11-gap-2 e11-bg-skin-white"
      [ngClass]="{
    'e11-bg-skin-white e11-border-skin-primary-accent': selected,
    'e11-bg-skin-white/50 e11-border-skin-transparent hover:e11-shadow': !selected,
  }"
    >
      <profile-pic [imageUrl]="resume?.photo?.url" [size]="'xs'"></profile-pic>
      <span class="e11-text-xs md:e11-text-sm e11-line-clamp-1 e11-break-all e11-skeleton-empty e11-line" *ngIf="resume">{{ resume.title }} </span>
      <div class="e11-ml-2 e11-skeleton-empty !e11-w-28" *ngIf="!resume"></div>
      <e11-overflow
        #overflow
        [closeOnMouseOut]="true"
        [closeOnClickOutside]="true"
        [menuPosition]="'bottom left'"
        [menuMaxWidth]="180"
        (clickOptionEvent)="onOverflowSelect($event)"
      >
        <ng-container custom-option *ngFor="let option of overflowOptions$ | async">
          <li
            class="e11-flex e11-items-center e11-gap-2 e11-py-2 e11-px-4 e11-text-sm hover:e11-bg-skin-grey/20 e11-font-primary-medium e11-cursor-pointer"
            [ngClass]="{
         'e11-text-skin-secondary': option.value === ProfileTabActions.delete,
        }"
            *ngIf="!resume?.isPrimary || (option.allowOnPrimary && resume?.isPrimary)"
            (click)="overflow.clickOption(option.value, $event)"
          >
            <span class="material-icons material-icons-outlined e11-text-sm">{{ option.icon }}</span>
            <span class="e11-text-sm">{{ option.name }}</span>
          </li>
        </ng-container>
      </e11-overflow>
    </div>
  `,
})
export class ProfileTabComponent {
  @Input() resume?: Timestamp<ICandidateResume> // undefined means loading
  @Input() selected: boolean = false
  @Input() tabIndex: number = 0

  features$: Observable<CnectFeatures | undefined> = this.configStore.features$

  @Output() resumeSelected = new EventEmitter<string>()
  @Output() actionClicked = new EventEmitter<ProfileTabActions>()

  overflowOptions$ = this.features$.pipe(
    map(features => {
      const candidateProfilePDF = features?.candidateProfilePDF || false

      const overflowOptions = [
        {
          icon: 'share',
          name: 'Share Profile',
          value: ProfileTabActions.share,
          allowOnPrimary: true,
        },
        {
          icon: 'manage_accounts',
          name: 'Manage Shares',
          value: ProfileTabActions.manageShares,
          allowOnPrimary: true,
        },
        {
          icon: 'picture_as_pdf',
          name: 'Create PDF',
          value: ProfileTabActions.pdf,
          allowOnPrimary: true,
        },
        {
          icon: 'edit',
          name: 'Edit Profile Name',
          value: ProfileTabActions.rename,
          allowOnPrimary: false,
        },
        {
          icon: 'delete',
          name: 'Delete',
          value: ProfileTabActions.delete,
          allowOnPrimary: false,
        },
      ]

      return candidateProfilePDF ? overflowOptions : overflowOptions.filter(option => option.value !== ProfileTabActions.pdf)
    })
  )

  ProfileTabActions = ProfileTabActions

  constructor(private configStore: ConfigStore) {}

  onOverflowSelect(option: Option) {
    const allowOnPrimary = this.overflowOptions$.pipe(map(overflowOptions => overflowOptions.find(o => o.value === option)?.allowOnPrimary))

    if (!allowOnPrimary && this.resume?.isPrimary) {
      return
    }

    this.actionClicked.emit(option as ProfileTabActions)
  }
}

import { Component, Inject } from '@angular/core'
import { PRIMARY_OUTLET, Router, UrlTree } from '@angular/router'
import * as fromReduce from '@candidate/app/store/reducers'
import { AppConfigService } from '@engineering11/tenant-bootstrap-web'
import { ILogger, LOGGER_TOKEN } from '@engineering11/web-api-error'
import { Store } from '@ngrx/store'
import jwt_decode from 'jwt-decode'
import { OnLogIn } from 'shared-lib'

const DEFAULT_REDIRECT_PATH = 'profiles'

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class AuthenticationLoginComponent {
  logoUrl = this.appConfig.config.brand.logoImageDarkUrl
  constructor(
    public router: Router,
    private store: Store<fromReduce.user.State>,
    @Inject(LOGGER_TOKEN) private logger: ILogger,
    private appConfig: AppConfigService
  ) {}

  onError(error: Error): void {
    throw error
  }
  onForgottenPassword() {
    this.router.navigate(['/auth/recover'])
  }

  navigateToRegistration() {
    const redirectPath = this.buildRedirectPath()
    this.router.navigate(['/registration/register', redirectPath], { queryParamsHandling: 'merge' })
  }

  async onSubmit(token: string): Promise<void> {
    const redirectPath = this.buildRedirectPath()
    const decoded: any = jwt_decode(token)
    this.store.dispatch(new OnLogIn({ token, appUserId: decoded.appUserId, redirectPath }))
  }

  private buildRedirectPath() {
    /**
     * The below checks if there is a deep link on the route, if so set
     * the redirect to that. The onboarding chain should always propagate
     * the deep link forward
     */
    const urlTree: UrlTree = this.router.parseUrl(this.router.url)
    const primary = urlTree.root.children[PRIMARY_OUTLET]
    if (!primary.segments.length) return DEFAULT_REDIRECT_PATH
    const redirectPath = primary.segments[primary.segments.length - 1].path
    if (redirectPath === 'login') return DEFAULT_REDIRECT_PATH
    else return redirectPath
  }
}

import { Injectable } from '@angular/core'
import { Params, Router } from '@angular/router'
import { INavigationItem } from '@engineering11/ui-lib/e11-nav-left'
import { Subject } from 'rxjs'
import { scan } from 'rxjs/operators'
import { CnectFeatures, NOT_FOUND_ROUTE } from 'shared-lib'

export const CNECT_ROUTES = Object.freeze({
  DEFAULT: '/',
  ACCOUNT: '/account',
  APPLICATIONS: '/applications',
  APPLY: '/applications/apply',
  CONNECTIONS: '/network/cnections',
  COMMUNITIES: '/c/m/messaging/communities',
  INVITATIONS: '/network/invitations',
  JOB_SEARCH: '/jobs/search',
  NOT_FOUND: NOT_FOUND_ROUTE,
  PROFILES: '/home/profiles',
  RESUME_PARSING: '/home/resume-parsing',
  VIDEOS: '/home/videos',
  PRIVACY_POLICY: '/public/privacy',
  TERMS_OF_USE: '/public/terms',
})

export enum ApplicationDetailTabs {
  Messages = 'Messages',
  JobDetail = 'Job Detail',
  Application = 'Application',
  Dialogue = 'Interview Sessions',
}

@Injectable({ providedIn: 'root' })
export class CandidateHomeNavigationService {
  navigationItems: INavigationItem[] = [
    {
      id: 'jobs',
      name: 'Jobs',
      route: CNECT_ROUTES.JOB_SEARCH,
      icon: 'search',
    },
    {
      id: 'applications',
      name: 'Applications',
      route: CNECT_ROUTES.APPLICATIONS,
      icon: 'contact_page',
    },
    {
      id: 'profiles',
      name: 'Profiles',
      route: CNECT_ROUTES.PROFILES,
      icon: 'person_pin',
    },
    {
      id: 'videos',
      name: 'Videos',
      route: CNECT_ROUTES.VIDEOS,
      icon: 'smart_display',
    },
  ]

  mobileSideDrawerToggle$ = new Subject<boolean>()

  mobileSideDrawerToggledState$ = this.mobileSideDrawerToggle$.pipe(scan((acc, _) => !acc, false))

  constructor(private router: Router) {}

  canDisplayNavItem(navItem: INavigationItem, features: CnectFeatures) {
    if (navItem.route === CNECT_ROUTES.JOB_SEARCH) {
      return features?.jobSearch
    }

    return true
  }

  application(queryParams: Params = {}) {
    return this.router.navigate([CNECT_ROUTES.APPLICATIONS], { queryParams })
  }

  applyToJob(jobPostId: string) {
    return this.router.navigate([CNECT_ROUTES.APPLY, jobPostId])
  }

  jobApplication(jobPostId: string) {
    return this.toApplicationDetailTab(jobPostId, ApplicationDetailTabs.Application)
  }

  dialogueTab(jobPostId: string) {
    return this.toApplicationDetailTab(jobPostId, ApplicationDetailTabs.Dialogue)
  }

  requestedUpdates(jobPostId: string) {
    return this.toApplicationDetailTab(jobPostId, ApplicationDetailTabs.Application)
  }

  virtualDialogue(jobPostId: string, dialogueSessionId: string) {
    return this.toApplicationDetailTab(jobPostId, ApplicationDetailTabs.Dialogue, { dialogueSessionId })
  }

  networkConnections() {
    return this.router.navigate([CNECT_ROUTES.CONNECTIONS])
  }

  networkInvitations() {
    return this.router.navigate([CNECT_ROUTES.INVITATIONS])
  }

  resumeById(resumeId: string) {
    this.resumePage({ resumeId })
  }

  private resumePage(queryParams?: Params) {
    return this.router.navigate([CNECT_ROUTES.PROFILES], { queryParams, replaceUrl: true })
  }

  toApplicationDetailTab(jobPostId: string, tab: ApplicationDetailTabs = ApplicationDetailTabs.Messages, queryParams?: Params) {
    return this.router.navigate([CNECT_ROUTES.APPLICATIONS, jobPostId], { queryParams: { ...queryParams, tab } })
  }

  videos() {
    return this.router.navigate([CNECT_ROUTES.VIDEOS])
  }

  resumeParsing() {
    return this.router.navigate([CNECT_ROUTES.RESUME_PARSING])
  }
}

import { Injectable } from '@angular/core'
import { CandidateVideoRepository } from '@candidate/app/services/video/candidate-video.repository'
import { isNotNil } from '@engineering11/utility'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators'
import { getCurrentUserId, CANDIDATE_VIDEO_TYPE } from 'shared-lib'
import {
  AddFirstImpression,
  AddFirstImpressionSuccess,
  GetAllVideos,
  GetAllVideosSuccess,
  OnInitVideos,
  VideoActionTypes,
  RemoveVideo,
  RemoveVideoSuccess,
} from './video.actions'
import { from } from 'rxjs'

@Injectable()
export class VideoEffects {
  constructor(private actions$: Actions, private store: Store, private videoRepository: CandidateVideoRepository) {}

  ngrxOnInitEffects(): Action {
    return { type: VideoActionTypes.onInitVideos }
  }

  onInit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<OnInitVideos>(VideoActionTypes.onInitVideos),
      switchMap(_ => this.store.pipe(getCurrentUserId)),
      filter(isNotNil),
      map(userId => new GetAllVideos(userId))
    )
  })

  onGetAllVideos$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetAllVideos>(VideoActionTypes.getAllVideos),
      switchMap(({ userId }) => this.videoRepository.getAllForUser(userId)),
      map(videos => new GetAllVideosSuccess(videos))
    )
  )

  onAddFirstImpression$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddFirstImpression>(VideoActionTypes.addFirstImpression),
      withLatestFrom(this.store.pipe(getCurrentUserId).pipe(filter(isNotNil))),
      switchMap(([action, userId]) => this.videoRepository.add({ ...action.payload, userId, type: CANDIDATE_VIDEO_TYPE.FIRST_IMPRESSION })),
      map(video => new AddFirstImpressionSuccess(video))
    )
  )

  onRemoveVideo$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RemoveVideo>(VideoActionTypes.removeVideo),
      mergeMap(action => from(this.videoRepository.delete(action.payload.id)).pipe(map(_ => action))),
      map(action => new RemoveVideoSuccess(action.payload))
    )
  )
}

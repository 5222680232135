<div class="container-view">
  <ng-container *ngrxLet="currentUser$ as currentUser">
    <div *ngIf="!currentUser && !pdf_render">
      <app-header-unauthenticated></app-header-unauthenticated>
    </div>
    <div *ngIf="currentUser">
      <app-header *ngIf="!pdf_render"></app-header>
    </div>
  </ng-container>

  <main>
    <div [ngClass]="{ 'e11-container-full container-content block': !pdf_render }" class="e11-bg-white">
      <router-outlet></router-outlet>
    </div>
  </main>

  <app-footer *ngIf="!pdf_render"></app-footer>
</div>

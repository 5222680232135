import { createFeatureSelector, createSelector } from '@ngrx/store'
import { resume } from '../reducers'
import { adapter } from './resume.reducer'

const resumeEntitySelectors = adapter.getSelectors()

export const getResumeStore = createFeatureSelector<resume.State>('resume')

export const isSelectionLoading = createSelector(getResumeStore, store => store.loadingSelection)
export const isGetAllResumesLoaded = createSelector(getResumeStore, store => store.loadedGetAll)
export const newResumeThreshold = createSelector(getResumeStore, store => store.newResumeThreshold)
export const getResumeEntities = createSelector(getResumeStore, resumeEntitySelectors.selectAll)
export const getSelectedResume = createSelector(getResumeStore, store => {
  const entityDict = store.entities
  const selectedResumeId = store.selectedResumeId
  return selectedResumeId && entityDict && entityDict[selectedResumeId] ? entityDict[selectedResumeId] : null
})

export const getPrimaryResume = createSelector(getResumeEntities, resumes => resumes.find(resume => resume.isPrimary))

export const isGetShareTokensLoaded = createSelector(getResumeStore, store => store.loadedGetShareTokens)
export const isCreateShareTokenLoading = createSelector(getResumeStore, store => store.loadingCreateShareToken)
export const isDeleteShareTokenLoading = createSelector(getResumeStore, store => store.loadingDeleteShareToken)

const latestTokenId = createSelector(getResumeStore, store => store.latestShareTokenId)
export const latestShareToken = createSelector(getSelectedResume, latestTokenId, (resume, tokenId) =>
  resume?.shareTokens?.find(token => token.id === tokenId)
)
export const latestShareTokenLink = createSelector(latestShareToken, token => token?.dynamicLink)

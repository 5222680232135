import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core'
import { ViewportService } from '@candidate/app/services/viewport.service'
import { ICandidateUser } from '@cnect/user-shared'
import { AtLeast } from '@engineering11/types'
import { E11ModalComponent } from '@engineering11/ui-lib/e11-modal'
import { isNotNil } from '@engineering11/utility'
import { E11Logger } from '@engineering11/web-api-error'
import { Store, select } from '@ngrx/store'
import { Observable, firstValueFrom } from 'rxjs'
import { filter } from 'rxjs/operators'
import { TokenStorage, UpdateUser, getCurrentUser, getCurrentUserCandidate } from 'shared-lib'

interface CarouselItem {
  image: string
  title: string
  content: string
}

@Component({
  selector: 'first-impression-intro-modal',
  templateUrl: './first-impression-intro-modal.component.html',
  styleUrls: ['./first-impression-intro-modal.component.scss'],
})
export class FirstImpressionIntroModalComponent implements OnInit {
  @ViewChild('modalFirstImpression') modalFirstImpression!: E11ModalComponent
  @Output() introComplete = new EventEmitter<boolean>()

  user$ = this.store.pipe(getCurrentUserCandidate, filter(isNotNil))
  user?: ICandidateUser

  viewport$ = this.viewportService.viewportSizeChanged$

  // Carousel - need to refactor and let this object be passed into a ui-component
  // NOTE: The images here are static, but we should still make and use a ui-component
  carousel: CarouselItem[] = [
    {
      image: '/assets/images/first-impression-tips/1.jpg',
      title: 'Camera-Ready',
      content: 'Position yourself 2 to 3 feet from your device and ensure your entire face is visible on the screen.',
    },
    {
      image: '/assets/images/first-impression-tips/3.jpg',
      title: 'Illuminate your Settings',
      content:
        'Choose a well-lit space and face the light source. Natural lighting is best! Make sure you can see yourself clearly in the preview window.',
    },
    {
      image: '/assets/images/first-impression-tips/6.jpg',
      title: 'Dress for Success',
      content: 'When you look good, you feel good! Select tops with colors that compliment your skin tone and avoid busy patterns or bright whites.',
    },
    {
      image: '/assets/images/first-impression-tips/7.jpg',
      title: 'Posture and Presentation',
      content: 'Good posture communicates confidence, respect, and attentiveness, which potential employers will appreciate!',
    },
    {
      image: '/assets/images/first-impression-tips/10.jpg',
      title: 'Setting the Stage',
      content: 'Check your surroundings. A clean, tidy background is key to limit distractions in your video.',
    },
    {
      image: '/assets/images/first-impression-tips/4.jpg',
      title: 'Teleprompter Power',
      content:
        'Use the teleprompter feature to keep yourself on track and showcase your skills. Adjust size, speed, and more to where you are most comfortable.',
    },
    {
      image: '/assets/images/first-impression-tips/5.jpg',
      title: "That's a Wrap!",
      content:
        'Once you have finished recording, review to make sure it is the first impression you want to make! Remember, you can save up to 3 First Impression Videos in your library at a time.',
    },
  ]
  firstImpressionTutorialEnabled = true
  slideCurrent: number = 0
  cycle: boolean = false
  userTutorialsEnabledUI: boolean = true
  currentUser$: Observable<ICandidateUser | null> = this.store.pipe(select(getCurrentUser))
  constructor(private tokenStorage: TokenStorage, private store: Store, private logger: E11Logger, private viewportService: ViewportService) {}

  async ngOnInit(): Promise<void> {
    this.user = await firstValueFrom(this.user$)
    this.firstImpressionTutorialEnabled =
      this.user.settingsFirstImpression?.tutorialsEnabled === undefined || this.user.settingsFirstImpression?.tutorialsEnabled
  }

  // Carousel
  navigate(direction: string) {
    if (direction == 'next') {
      if (this.slideCurrent >= this.carousel.length - 1) {
        this.cycle ? (this.slideCurrent = 0) : null
      } else {
        this.slideCurrent++
      }
    } else {
      if (this.slideCurrent === 0) {
        this.cycle ? (this.slideCurrent = this.carousel.length - 1) : null
      } else {
        this.slideCurrent--
      }
    }
  }

  disableTipsCheckbox(event: any) {
    // NOTE: We are reversing the value because a checked value of true really means 'tutorialsEnabled' needs to be false
    const tutorialEnabled = !event.target.checked
    this.logger.log({ tutorialEnabled, event })
    this.updateUser(tutorialEnabled)
    this.firstImpressionTutorialEnabled = tutorialEnabled
    this.introComplete.emit(true)
  }

  openIfEnabled() {
    if (this.modalFirstImpression) this.modalFirstImpression.open()
    this.slideCurrent = 0
  }

  closeModal() {
    this.introComplete.emit(true)
    if (this.modalFirstImpression) this.modalFirstImpression.close()
  }

  updateUser(tutorialsEnabled = false) {
    const updateRecord: AtLeast<ICandidateUser, 'id'> = { id: this.user!.id, settingsFirstImpression: { tutorialsEnabled } }
    return this.store.dispatch(new UpdateUser(updateRecord))
  }
}

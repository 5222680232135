export enum DispositionCodes {
  /** Interviewing */
  SelectedForInterview = 'SelectedForInterview',
  /** Offered the Position */
  OfferSent = 'OfferSent',
  /** Withdrawn – Applicant Withdrew */
  ApplicantWithdrew = 'ApplicantWithdrew',
  /** Rejected - Basic Qualifications */
  BasicQualifications = 'BasicQualifications',
  /** Rejected - Not Best Qualified */
  NotBestQualified = 'NotBestQualified',
  /** Rejected - Failed Background Check */
  FailedBackgroundCheck = 'FailedBackgroundCheck',
  /** Rejected - Applicant does not meet Citizenship requirements */
  CitizenshipRequirements = 'CitizenshipRequirements',
  /** Rejected - Applicant has not submitted foreign citizen or e-verify eligibility requirements */
  NotSubmittedCitizenshipEVerify = 'NotSubmittedCitizenshipEVerify',
  /** Rejected - Job has already been filled from within or has been closed */
  JobFilledOrClosed = 'JobFilledOrClosed',
  /** Rejected - other */
  Other = 'Other',
}

export const rejectionReasons = [
  DispositionCodes.BasicQualifications,
  DispositionCodes.NotBestQualified,
  DispositionCodes.FailedBackgroundCheck,
  DispositionCodes.CitizenshipRequirements,
  DispositionCodes.NotSubmittedCitizenshipEVerify,
  DispositionCodes.Other,
]

export type RejectionReasons = (typeof rejectionReasons)[number]

export interface IDispositionCode {
  id: string
  applicationId: string
  dispositionCode: DispositionCodes
  senderId: string
  timestamp: Date
  /** Typed by the employer if they select 'Other' */
  otherInputString?: string
}

export interface IRejectionReason {
  rejectionReason?: RejectionReasons
  otherInputString?: string
}

import { Injectable } from '@angular/core'
import { FilesConfigProvider, IFilesConfig } from '@engineering11/files-web'
import { Store } from '@ngrx/store'
import { getCurrentToken, getCustomerKey$ } from 'shared-lib'
import { environment } from '../../environments/environment'

@Injectable({ providedIn: 'root' })
export class CnectFilesConfigProvider extends FilesConfigProvider {
  constructor(private store: Store) {
    super()
  }
  get config(): IFilesConfig {
    return {
      filesBaseURL: environment.services.files,
      CNAME: environment.cdnCname,
      customerKey$: this.store.pipe(getCustomerKey$), // the current user's customerKey
      token$: this.store.select(getCurrentToken),
      validationConfig: {
        types: undefined, // Undefined removes validation - until we figure out why FIV uploads don't have a type
      },
    }
  }
}

<div class="e11-w-full">
  <div class="e11-block md:e11-flex">
    <div class="e11-grow">
      <h3 class="e11-skeleton-empty">{{ jobDetail?.jobTitle }}</h3>
      <p class="e11-mb-2">
        {{ jobDetail?.companyName }}
      </p>
      <div class="e11-mb-2" *ngIf="!jobDetail || jobDetail.locationAddress">
        <p *ngIf="!jobDetail || jobDetail?.locationAddress?.address1" [ngClass]="{ 'e11-mb-2': !jobDetail, 'e11-mb-0': jobDetail }">
          {{ jobDetail?.locationAddress?.address1 }}
        </p>
        <p *ngIf="jobDetail && jobDetail.locationAddress?.address2" class="e11-mb-0">{{ jobDetail.locationAddress?.address2 }}</p>
        <p *ngIf="jobDetail && jobDetail.locationAddress?.city" class="e11-mb-0">
          {{ jobDetail.locationAddress?.city }} , {{ jobDetail.locationAddress?.state }} . {{ jobDetail.locationAddress?.zip }}
        </p>
        <p *ngIf="!jobDetail || jobDetail.locationAddress?.countryCode" class="e11-mb-0">{{ jobDetail?.locationAddress?.countryCode }}</p>
      </div>
    </div>
    <div class="">
      <div class="job-details">
        <div class="e11-flex e11-flex-wrap md:e11-gap-8 line-item e11-mb-2">
          <div *ngIf="jobDetail">{{ 'Employment Type' | translate }}:</div>
          <div *ngIf="!jobDetail" class="e11-skeleton-empty"></div>
          <div *ngIf="jobDetail && jobDetail.employmentType">
            {{ jobDetail.employmentType | employmentType | translate }}
          </div>
          <div *ngIf="jobDetail && !jobDetail.employmentType">{{ 'Not Specified' | translate }}</div>
        </div>
        <div *ngIf="jobDetail" class="e11-flex e11-flex-wrap md:e11-gap-6 line-item e11-mb-2">
          <div>{{ 'Remote or In Office' | translate }}:</div>
          <div *ngIf="jobDetail?.employeeLocation === EmployeeLocation.Both">{{ 'Hybrid' | translate }}</div>
          <div *ngIf="jobDetail?.employeeLocation === EmployeeLocation.InOffice">{{ 'In Office' | translate }}</div>
          <div *ngIf="jobDetail?.employeeLocation !== EmployeeLocation.Both || jobDetail?.employeeLocation !== EmployeeLocation.InOffice">
            {{ jobDetail?.employeeLocation }}
          </div>
        </div>
        <div *ngIf="jobDetail" class="e11-flex e11-flex-wrap md:e11-gap-10 line-item e11-mb-2">
          <div>{{ 'Experience Level' | translate }}:</div>
          <div *ngIf="jobDetail.experienceLevel">
            {{ jobDetail.experienceLevel | experienceLevel | translate }}
          </div>
          <div *ngIf="!jobDetail.experienceLevel">{{ 'Not Specified' | translate }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="e11-clear-both md:e11-clear-none"></div>
<div class="e11-w-full">
  <e11-panel
    [containerClassOverrides]="'e11-border-none e11-shadow-none'"
    [headerBgColor]="'e11-bg-skin-primary-accent/20'"
    [headerFontColor]="'e11-text-skin-dark'"
    [headerSize]="'h4'"
    [headerStyle]="'square'"
    headerText="{{ 'Job Description' | translate }}"
  >
    <div *ngIf="jobDetail" [ngxSummernoteView]="jobDetail.jobDescription || ''"></div>
    <ng-container *ngIf="!jobDetail">
      <p *ngFor="let i of 3 | range" class="e11-skeleton-empty"></p>
    </ng-container>
    <div class="spacer-2rem"></div>

    <h4>{{ 'Minimum Qualifications' | translate }}</h4>
    <div *ngIf="jobDetail" [ngxSummernoteView]="jobDetail.minimumQualifications || ''"></div>
    <ng-container *ngIf="!jobDetail">
      <p *ngFor="let i of 3 | range" class="e11-skeleton-empty"></p>
    </ng-container>
    <div class="spacer-2rem"></div>

    <div *ngIf="!jobDetail || jobDetail.responsibilities">
      <h4>{{ 'Responsibilities' | translate }}</h4>
      <div *ngIf="jobDetail" [ngxSummernoteView]="jobDetail.responsibilities || ''"></div>
      <ng-container *ngIf="!jobDetail">
        <p *ngFor="let i of 3 | range" class="e11-skeleton-empty"></p>
      </ng-container>
    </div>

    <div *ngIf="jobDetail" class="job-details">
      <!-- loop through job details -->
      <div *ngFor="let key of modelKeys" class="row line-item e11-mb-3">
        <div class="col-xs-12 col-md-3 nopadding-left">{{ translationModelKeys[key] | translate }}:</div>
        <div *ngIf="!jobDetail[key]" class="col-xs-12 col-md-8 nopadding-left">
          <span>{{ 'Not Specified' | translate }}</span>
        </div>
        <div *ngIf="jobDetail[key]" class="col-xs-12 col-md-8 nopadding-left">
          <span *ngIf="key === 'compensationLow' || key === 'compensationHigh'">
            {{ jobDetail[key] | currency }}
          </span>
          <span *ngIf="key === 'educationLevel'">
            {{ jobDetail[key] | degreeLevel | translate }}
          </span>
          <span *ngIf="key === 'benefits'">
            <div *ngIf="jobDetail" [ngxSummernoteView]="jobDetail.benefits || ''"></div>
          </span>
          <span
            *ngIf="
              key !== 'compensationLow' && key !== 'compensationHigh' && key !== 'scheduleText' && key !== 'educationLevel' && key !== 'benefits'
            "
          >
            {{ jobDetail[key] }}<span *ngIf="key === 'travelPercentage' && jobDetail[key]">%</span>
          </span>
          <ng-container *ngIf="key === 'scheduleText'">
            <job-schedule [schedule]="jobDetail.scheduleText || ''"></job-schedule>
          </ng-container>
        </div>
      </div>
    </div>
  </e11-panel>
</div>

<div *ngIf="!jobDetail || jobDetail.legalDisclaimer" class="e11-w-full disclaimer">
  <e11-panel
    [containerClassOverrides]="'e11-border-none e11-shadow-none'"
    [headerBgColor]="'e11-bg-skin-primary-accent/20'"
    [headerFontColor]="'e11-text-skin-dark'"
    [headerSize]="'h4'"
    [headerStyle]="'square'"
    headerText="{{ 'EEO Policy Statement' | translate }}"
  >
    <p *ngIf="!jobDetail" class="e11-skeleton-empty"></p>
    <div *ngIf="jobDetail && jobDetail.legalDisclaimer" [ngxSummernoteView]="jobDetail!.legalDisclaimer || ''"></div>
  </e11-panel>
</div>

import { Injectable } from '@angular/core'
import { IApplicationReactivatedNotification } from '@candidate/app/models/app-notification.model'
import { INotificationHandler, INotificationView, UserAppNotificationService } from '@engineering11/notifications-web'
import { CandidateHomeNavigationService } from '../candidate-home-navigation.service'

@Injectable({ providedIn: 'root' })
export class ApplicationReactivatedNotificationHandler implements INotificationHandler<IApplicationReactivatedNotification, INotificationView> {
  notificationType = 'candidate_rejection_reversed'
  constructor(
    private candidateHomeNavigationService: CandidateHomeNavigationService,
    private userAppNotificationService: UserAppNotificationService
  ) {}

  onClick(notification: IApplicationReactivatedNotification): boolean | Promise<boolean> {
    if (!notification.viewed) {
      this.userAppNotificationService.markNotificationAsRead(notification.id)
    }
    return this.candidateHomeNavigationService.jobApplication(notification.jobId)
  }
}

import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { EmployerNotInterestedNotification } from '@candidate/app/models/app-notification.model'
import { INotificationHandler, INotificationView, UserAppNotificationService } from '@engineering11/notifications-web'
import { CandidateHomeNavigationService } from '../candidate-home-navigation.service'

@Injectable({ providedIn: 'root' })
export class EmployerNotInterestedNotificationHandler implements INotificationHandler<EmployerNotInterestedNotification, INotificationView> {
  notificationType = 'candidate_employer_not_interested'
  constructor(
    private candidateHomeNavigationService: CandidateHomeNavigationService,
    private userAppNotificationService: UserAppNotificationService
  ) {}

  async onClick(notification: EmployerNotInterestedNotification): Promise<boolean> {
    if (!notification.viewed) {
      await this.userAppNotificationService.markNotificationAsRead(notification.id)
    }
    return this.candidateHomeNavigationService.jobApplication(notification.jobId)
  }
}

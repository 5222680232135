import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { UiComponentLibraryModule } from '../_component-library/component-library.module'
import { SharedModule } from '../_shared/shared.module'
import { FileNotFoundComponent } from './404.component'

@NgModule({
  declarations: [FileNotFoundComponent],
  imports: [CommonModule, SharedModule, UiComponentLibraryModule],
  exports: [],
  providers: [],
})
export class FileNotFoundModule {}

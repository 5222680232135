<div *ngIf="currentUser$ | async as currentUser">
  <ng-container *ngIf="currentUser.settingsFirstImpression?.tutorialsEnabled !== false">
    <e11-modal
      #modalFirstImpression
      [width]="(viewport$ | async) === 'xs' ? 'lg' : 'md'"
      title=""
      [showHeader]="false"
      [closer]="true"
      [showFooter]="false"
      [bodyHeight]="'large'"
      (onClose)="introComplete.emit()"
      [contentClassOverrides]="'e11-border-0 e11-bg-transparent e11-rounded-lg'"
    >
      <div modal-body>
        <div class="carousel">
          <div class="items">
            <div
              class="item"
              [ngClass]="{ active: i === slideCurrent }"
              *ngFor="let item of carousel; let i = index"
              [style.backgroundImage]="'url(' + item.image + ')'"
            >
              <div class="gradient"></div>
              <div class="content">
                <h3 class="text-white">{{ item.title }}</h3>
                <p class="text-white">{{ item.content }}</p>
              </div>
            </div>
          </div>
          <div class="indicators">
            <span class="indicator" *ngFor="let indicator of carousel; let i = index" [ngClass]="{ active: i === slideCurrent }"></span>
          </div>

          <div class="navigation">
            <div class="e11-flex e11-mx-8 e11-mb-4">
              <div class="e11-w-1/2">
                <span
                  class="control e11-text-white e11-font-primary-demibold e11-flex e11-items-center e11-cursor-pointer"
                  (click)="navigate('prev')"
                  *ngIf="slideCurrent > 0"
                >
                  <span class="material-icons-outlined e11-mr-2"> arrow_back_ios </span>{{ 'Previous' | translate }}
                </span>
              </div>
              <div class="e11-w-1/2">
                <span
                  *ngIf="slideCurrent + 1 < carousel.length"
                  class="control e11-text-white e11-font-primary-demibold e11-flex e11-items-center e11-justify-end e11-cursor-pointer"
                  (click)="navigate('next')"
                >
                  {{ 'Next' | translate }}<span class="material-icons-outlined e11-ml-2"> arrow_forward_ios </span>
                </span>

                <span
                  *ngIf="slideCurrent + 1 === carousel.length"
                  class="control e11-text-white e11-font-primary-demibold e11-flex e11-items-center e11-justify-end e11-cursor-pointer"
                  (click)="closeModal()"
                >
                  {{ 'Start Recording' | translate }}<span class="material-icons-outlined e11-ml-2"> arrow_forward_ios </span>
                </span>
              </div>
            </div>
            <div class="e11-mx-8 e11-justify-end e11-text-white e11-text-right e11-pr-8">
              <e11-input-checkbox label="Do not show again" (change)="disableTipsCheckbox($event)" *ngIf="userTutorialsEnabledUI">
              </e11-input-checkbox>
            </div>
          </div>
        </div>
      </div>
    </e11-modal>
  </ng-container>
</div>

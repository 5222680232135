import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { IAppNotification, INotificationHandler, INotificationView, UserAppNotificationService } from '@engineering11/notifications-web'
import { NetworkPage, SOCIAL_NOTIFICATION_IDS } from '@engineering11/social-web'

export const notificationType = SOCIAL_NOTIFICATION_IDS.social_connection_requested

export interface IConnectionRequestedNotification extends IAppNotification {
  type: typeof notificationType
  connectionId: string
  requestorDisplayName: string
  requestorFirstName: string
  requestorLastName: string
}
export type ConnectionRequestedNotificationVM = INotificationView

@Injectable({ providedIn: 'root' })
export class ConnectionRequestedNotificationHandler
  implements INotificationHandler<IConnectionRequestedNotification, ConnectionRequestedNotificationVM>
{
  notificationType = notificationType
  constructor(private router: Router, private userAppNotificationService: UserAppNotificationService) {}

  onClick(notification: IConnectionRequestedNotification): boolean | Promise<boolean> {
    if (!notification.viewed) {
      this.userAppNotificationService.markNotificationAsRead(notification.id)
    }
    this.router.navigate(['/network/cnections'], { queryParams: { networkPage: NetworkPage.Requests } })
    return true
  }
}

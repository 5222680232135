import { flow, property, toLower, uniqBy } from 'lodash'

/**
 * Should only be used on keys with string attributes
 * @param array
 * @param key
 * @returns
 */
export function removeDuplicatesByKeyIgnoreCase<T>(array: T[], key: keyof T): T[] {
  const byLowerCaseKey = flow(property(key), toLower)
  return uniqBy(array, byLowerCaseKey)
}

export function removeDuplicatesByNameIgnoreCase<T extends { name: string }>(array: T[]): T[] {
  return removeDuplicatesByKeyIgnoreCase(array, 'name')
}

<lib-maintenance-page></lib-maintenance-page>
<mobile-banner></mobile-banner>
<lib-version-checker [environment]="_environment"></lib-version-checker>
<e11-popover-global></e11-popover-global>
<router-outlet></router-outlet>
<e11-side-over></e11-side-over>
<e11-notifications></e11-notifications>
<e11-modal-global></e11-modal-global>
<e11-loader-global></e11-loader-global>
<app-toast></app-toast>

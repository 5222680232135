import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
// E11
import { E11AvatarModule } from '@engineering11/ui-lib/e11-avatar'
import { E11BackdropModule } from '@engineering11/ui-lib/e11-backdrop'
import { E11BadgeModule } from '@engineering11/ui-lib/e11-badge'
import { E11ButtonModule } from '@engineering11/ui-lib/e11-button'
import { E11ChartPercentageModule } from '@engineering11/ui-lib/e11-chart-percentage'
import { E11ChipModule } from '@engineering11/ui-lib/e11-chip'
import { E11ConfirmationModule } from '@engineering11/ui-lib/e11-confirmation'
import { E11DividerModule } from '@engineering11/ui-lib/e11-divider'
import { E11EmptyStateModule } from '@engineering11/ui-lib/e11-empty-state'
import { E11ErrorMessagesModule } from '@engineering11/ui-lib/e11-error-messages'
import { E11FileViewerModule } from '@engineering11/ui-lib/e11-file-viewer'
import { E11ImageViewerModule } from '@engineering11/ui-lib/e11-image-viewer'
import { E11IndicatorModule } from '@engineering11/ui-lib/e11-indicator'
import { E11InputModule } from '@engineering11/ui-lib/e11-input'
import { E11InputCheckboxModule } from '@engineering11/ui-lib/e11-input-checkbox'
import { E11InputErrorsModule } from '@engineering11/ui-lib/e11-input-errors'
import { E11LoaderDotsModule } from '@engineering11/ui-lib/e11-loader-dots'
import { E11LoaderEllipsisModule } from '@engineering11/ui-lib/e11-loader-ellipsis'
import { E11LoaderLineModule } from '@engineering11/ui-lib/e11-loader-line'
import { E11ModalModule } from '@engineering11/ui-lib/e11-modal'
import { E11OverflowModule } from '@engineering11/ui-lib/e11-overflow'
import { E11PaginationModule } from '@engineering11/ui-lib/e11-pagination'
import { E11PanelModule } from '@engineering11/ui-lib/e11-panel'
import { E11PopoverModule } from '@engineering11/ui-lib/e11-popover'
import { E11ProfilePicModule } from '@engineering11/ui-lib/e11-profile-pic'
import { E11RadioInputModule } from '@engineering11/ui-lib/e11-radio-input'
import { E11SelectModule } from '@engineering11/ui-lib/e11-select'
import { E11SwitchModule } from '@engineering11/ui-lib/e11-switch'
import { E11TabsModule } from '@engineering11/ui-lib/e11-tabs'
import { E11TagModule } from '@engineering11/ui-lib/e11-tag'
import { E11TextEditorModule } from '@engineering11/ui-lib/e11-text-editor'
import { E11TextareaModule } from '@engineering11/ui-lib/e11-textarea'
import { E11TitleBarModule } from '@engineering11/ui-lib/e11-title-bar'
import { E11TooltipModule } from '@engineering11/ui-lib/e11-tooltip'
import { E11UtilModule } from '@engineering11/ui-lib/e11-ui-utils'
import { E11ExternalLinkModule } from '@engineering11/ui-lib/e11-external-link'

const ImportExportModules = [
  E11AvatarModule,
  E11BackdropModule,
  E11BadgeModule,
  E11ButtonModule,
  E11ChartPercentageModule,
  E11DividerModule,
  E11EmptyStateModule,
  E11LoaderDotsModule,
  E11LoaderEllipsisModule,
  E11LoaderLineModule,
  E11PanelModule,
  E11TitleBarModule,
  E11ProfilePicModule,
  E11RadioInputModule,
  E11TextEditorModule,
  E11ModalModule,
  E11PopoverModule,
  E11IndicatorModule,
  E11OverflowModule,
  E11PaginationModule,
  E11InputCheckboxModule,
  E11TextareaModule,
  E11InputErrorsModule,
  E11TabsModule,
  E11TagModule,
  E11SelectModule,
  E11SwitchModule,
  E11InputModule,
  E11ErrorMessagesModule,
  E11TooltipModule,
  E11UtilModule,
  E11FileViewerModule,
  E11ConfirmationModule,
  E11ImageViewerModule,
  E11ChipModule,
  E11ExternalLinkModule,
]

@NgModule({
  declarations: [],
  imports: [CommonModule, ...ImportExportModules],
  exports: [...ImportExportModules],
})
export class UiComponentLibraryModule {}

import { Action } from '@ngrx/store'

import { IFirstImpression, ICandidateVideo } from 'shared-lib'

export enum VideoActionTypes {
  onInitVideos = '[Video Initial App Load] on init',
  getAllVideos = '[Video] get all',
  getAllVideosSuccess = '[Video] get all success',
  addFirstImpression = '[First Impression component] add first impression',
  addFirstImpressionSuccess = '[First Impression component] add first impression success',
  removeVideo = '[Video] remove video',
  removeVideoSuccess = '[Video] remove video success',
}

export class OnInitVideos implements Action {
  readonly type = VideoActionTypes.onInitVideos
  constructor() {}
}
export class GetAllVideos implements Action {
  readonly type = VideoActionTypes.getAllVideos
  constructor(public userId: string) {}
}

export class GetAllVideosSuccess implements Action {
  readonly type = VideoActionTypes.getAllVideosSuccess
  constructor(public payload: ICandidateVideo[]) {}
}

export class AddFirstImpression implements Action {
  readonly type = VideoActionTypes.addFirstImpression
  constructor(public payload: IFirstImpression) {}
}

export class AddFirstImpressionSuccess implements Action {
  readonly type = VideoActionTypes.addFirstImpressionSuccess
  constructor(public payload: ICandidateVideo) {}
}

export class RemoveVideo implements Action {
  readonly type = VideoActionTypes.removeVideo
  constructor(public payload: ICandidateVideo) {}
}

export class RemoveVideoSuccess implements Action {
  readonly type = VideoActionTypes.removeVideoSuccess
  constructor(public payload: ICandidateVideo) {}
}

export type VideoActions =
  | OnInitVideos
  | GetAllVideos
  | GetAllVideosSuccess
  | AddFirstImpression
  | AddFirstImpressionSuccess
  | RemoveVideo
  | RemoveVideoSuccess

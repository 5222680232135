import { environment } from '@candidate/environments/environment'
import { Injectable } from '@angular/core'
import { RestApiClient } from '@engineering11/web-api-rest'
import { Observable, of, throwError } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { IEmployerDetailsVM } from '@candidate/app/models/employer/employer.model'

@Injectable({
  providedIn: 'root',
})
export class PublicEmployerService {
  private restApiClient: RestApiClient
  constructor() {
    this.restApiClient = new RestApiClient({
      baseUrl: environment.services.employer,
      token: '',
    })
  }

  get(customerKey: string): Observable<IEmployerDetailsVM> {
    return this.restApiClient.get<IEmployerDetailsVM>(`/employer/${customerKey}`).pipe(
      map(item => item.data),
      catchError(err => throwError(err))
    )
  }
}

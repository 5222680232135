import { Inject, Injectable } from '@angular/core'
import { CommunityStore } from '@engineering11/community-web'
import { ConversationStore } from '@engineering11/messaging-web'
import { AppConfigService, FAV_ICON_TYPES } from '@engineering11/tenant-bootstrap-web'
import { ILogger, LOGGER_TOKEN } from '@engineering11/web-api-error'
import { combineLatest } from 'rxjs'
import { distinctUntilChanged, map } from 'rxjs/operators'

@Injectable({ providedIn: 'root' })
export class FaviconSwitchingService {
  displayNotificationIcon$ = combineLatest([this.communityStore.hasNotification$, this.conversationStore.hasNotification$]).pipe(
    map(hasNotificationArray => hasNotificationArray.some(hasNotification => hasNotification)),
    distinctUntilChanged()
  )
  constructor(
    private appConfigService: AppConfigService,
    private conversationStore: ConversationStore,
    private communityStore: CommunityStore,
    @Inject(LOGGER_TOKEN) private logger: ILogger
  ) {
    this.initNotificationsIcon() // Where should this belong so that one subscription lives as long as the application is open
  }

  private initNotificationsIcon() {
    this.logger.log('Initializing favicon service')

    // ? How is this subscription terminated? Can it be? Can we prevent this from running more than once?
    this.displayNotificationIcon$.subscribe(displayNotification => {
      this.logger.log('switching favicon', { displayNotification })
      const isNotificationIcon = displayNotification ? FAV_ICON_TYPES.NOTIFICATION : FAV_ICON_TYPES.STANDARD
      this.appConfigService.setFavIcon(isNotificationIcon)
    })
  }
}

import { Injectable } from '@angular/core'
import { IJobPostClosedNotification } from '@candidate/app/models/app-notification.model'
import { INotificationHandler, INotificationView, UserAppNotificationService } from '@engineering11/notifications-web'
import { CandidateHomeNavigationService } from '../candidate-home-navigation.service'

@Injectable({ providedIn: 'root' })
export class JobPostClosedNotificationHandler implements INotificationHandler<IJobPostClosedNotification, INotificationView> {
  notificationType = 'candidate_job_post_closed'
  constructor(
    private candidateHomeNavigationService: CandidateHomeNavigationService,
    private userAppNotificationService: UserAppNotificationService
  ) {}

  async onClick(notification: IJobPostClosedNotification): Promise<boolean> {
    if (!notification.viewed) {
      this.userAppNotificationService.markNotificationAsRead(notification.id)
    }
    return this.candidateHomeNavigationService.jobApplication(notification.jobPostId)
  }
}

import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'job-schedule',
  template: `
    <div>
      <div [ngxSummernoteView]="schedule!"></div>
    </div>
  `,
})
export class JobScheduleComponent {
  @Input() schedule?: string

  constructor() {}
}

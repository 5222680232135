import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { ERROR_HANDLING } from '@candidate/app/app.constants'
import { InfoContentHeaderComponent } from '@candidate/app/components/informational-content/header/info-content-header.component'
import { MainLayoutComponent } from '@candidate/app/components/layout/main-layout.component'
import { MobileSideDrawerMenuComponent } from '@candidate/app/components/mobile-side-drawer-menu/mobile-side-drawer-menu.component'
import { ModalBrowserDetectionComponent } from '@candidate/app/components/modals/browser-detection/browser-detection.component'
import { ConversationGroupComponent } from '@candidate/app/components/nav-components/conversation-group/conversation-group.component'
import { ConversationComponent } from '@candidate/app/components/nav-components/conversation/conversation.component'
import { AppNavContainerComponent } from '@candidate/app/components/nav-container/nav-container.component'
import { AppNavLeftInformationalComponent } from '@candidate/app/components/nav-left-informational/nav-left-informational.component'
import { AppNavLeftComponent } from '@candidate/app/components/nav-left/nav-left.component'
import { WarmLayoutComponent } from '@candidate/app/components/warm-layout/warm-layout.component'
import { AccessConfigProvider } from '@candidate/app/config/access.config'
import { CnectCommunityConfigProvider } from '@candidate/app/config/community.config'
import { CandidateApplicationConversationProvider } from '@candidate/app/config/conversation-config-provider/application-conversation-config.provider'
import { CandidateApplicationConversationDMProvider } from '@candidate/app/config/conversation-config-provider/application-conversation-dm-config.provider'
import { ConversationCustomDataProvider, MessagingConfigFromApp } from '@candidate/app/config/messaging.config'
import { CanDisplayNavItemPipe } from '@candidate/app/pipes/can-display-nav-item.pipe'
import { IdenticonPipe } from '@candidate/app/pipes/identicon.pipe'
import { SkillsByLevelPipe } from '@candidate/app/pipes/skills-by-level.pipe'
import { ApplicationReactivatedNotificationHandler } from '@candidate/app/services/notifications/application-reactivated-notification.handler'
import { CandidateApplicationResubmittedHandler } from '@candidate/app/services/notifications/candidate-application-resubmitted.handler'
import { CommunityMemberMentionedNotificationHandler } from '@candidate/app/services/notifications/community-member-mentioned-notification.handler'
import { ConnectionAcceptedNotificationHandler } from '@candidate/app/services/notifications/connection-accepted-notification.handler'
import { ConnectionRequestedNotificationHandler } from '@candidate/app/services/notifications/connection-requested-notification.handler'
import { ContentReportedNotificationHandler } from '@candidate/app/services/notifications/content-reported-notification.handler'
import { EmployerNotInterestedNotificationHandler } from '@candidate/app/services/notifications/employer-not-interested-notification.handler.ts'
import { JobPostClosedNotificationHandler } from '@candidate/app/services/notifications/job-post-closed-notification.handler'
import { JobPostReactivatedNotificationHandler } from '@candidate/app/services/notifications/job-post-reactivated-notification.handler'
import { MessagingUserMentionedHandler } from '@candidate/app/services/notifications/messaging-user-mentioned.handler'
import { RequestedUpdateNotificationHandler } from '@candidate/app/services/notifications/requested-update-notification-handler'
import { ResumeGeneratedNotificationHandler } from '@candidate/app/services/notifications/resume-generated-notification.handler'
import { VirtualDialogueSubmittedCandidate } from '@candidate/app/services/notifications/virtual-dialogue-submitted-candidate.handler'
import { environment } from '@candidate/environments/environment'
import { CloudflareStreamModule } from '@cloudflare/stream-angular'
import { AccessModule } from '@engineering11/access-web'
import { CommunityModule } from '@engineering11/community-web'
import { DirectMessageConversationConfigProvider, MessagingConfigurationProvider, MessagingModule } from '@engineering11/messaging-web'
import { MultimediaModule } from '@engineering11/multimedia-web'
import { NotificationHandlerModule, UiNotificationComponent, UiNotificationLongComponent } from '@engineering11/notifications-web'
import { E11InputErrorsModule } from '@engineering11/ui-lib/e11-input-errors'
import { WebUtilityModule } from '@engineering11/web-utilities'
import { LetDirective } from '@ngrx/component'
import { TranslateModule } from '@ngx-translate/core'
import { QRCodeModule } from 'angularx-qrcode'
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input'
import { NgxSummernoteModule } from 'node_modules/ngx-summernote'
import { NotificationConfigProvider, SharedLibModule, SocialDmConfigProvider } from 'shared-lib'
import { AppFooterComponent } from '../../components/footer/footer.component'
import { AppHeaderSecondaryComponent } from '../../components/header-secondary/header-secondary.component'
import { AppHeaderUnauthenticatedComponent } from '../../components/header-unauthenticated/header-unauthenticated.component'
import { AppHeaderComponent } from '../../components/header/header.component'
import { ModalPrivacyPolicyComponent } from '../../components/modals/privacy-policy/privacy-policy.component'
import { ModalTermsOfUseComponent } from '../../components/modals/terms-of-use/terms-of-use.component'
import { UiComponentLibraryModule } from '../_component-library/component-library.module'
import { FirstImpressionIntroModalComponent } from '../videos/components/first-impression-intro-modal/first-impression-intro-modal.component'
import { ProfileBannerRequestComponent } from './components/banner-requests/banner.component'
import { CandidateQuestionResponsesComponent } from './components/candidate-question-responses/candidate-question-responses.component'
import { FirstImpressionFreeFormComponent } from './components/first-impression-form/first-impression-form.component'
import { FormSideoverContainerComponent } from './components/form-sideover-container/form-sideover-container.component'
import { JobDetailDisplayComponent } from './components/job-detail-display/job-detail-display.component'
import { ModalApplicationsComponent } from './components/modal-applications/modal-applications.component'
import { FormModalContainerComponent } from './components/modal-container/form-modal-container.component'
import { UiTemplate } from './components/modal-container/ui-template'
import { ModalIntroComponent } from './components/modal-intro/modal-intro.component'
import { ModalResumeParseComponent } from './components/modal-resume-parse/modal-resume-parse.component'
import { ProfileTabComponent } from './components/profile-tab/profile-tab.component'
import { ReferralLinkCopyComponent } from './components/referral-link-copy/referral-link-copy.component'
import { ResponsiveWarningComponent } from './components/responsive-warning/responsive-warning.component'
import { ResumeCardComponent } from './components/resume-card/resume-card.component'
import { ResumeDocumentParseComponent } from './components/resume-parse/resume-document-parse/resume-document-parse.component'
import { UIModalIntroComponent } from './components/ui-modal-intro/ui-modal-intro.component'

const DeclareExportComponents = [
  AppFooterComponent,
  AppHeaderComponent,
  AppHeaderSecondaryComponent,
  AppHeaderUnauthenticatedComponent,
  AppNavLeftComponent,
  AppNavLeftInformationalComponent,
  AppNavContainerComponent,
  MobileSideDrawerMenuComponent,
  ResponsiveWarningComponent,
  WarmLayoutComponent,
  MainLayoutComponent,

  //Nav Items
  ConversationComponent,
  ConversationGroupComponent,

  // Modals
  ModalPrivacyPolicyComponent,
  ModalTermsOfUseComponent,

  // Candidate management
  CandidateQuestionResponsesComponent,
  ProfileBannerRequestComponent,

  // Resume
  ResumeCardComponent,

  // Profile
  ProfileTabComponent,

  // Applications
  JobDetailDisplayComponent,

  // Global forms & form containers
  FormSideoverContainerComponent,
  FormModalContainerComponent,
  UiTemplate,
  FirstImpressionFreeFormComponent,

  // Global modals
  ModalApplicationsComponent,
  ModalIntroComponent,
  ModalBrowserDetectionComponent,
  ModalResumeParseComponent,

  IdenticonPipe,
  ResumeDocumentParseComponent,
  InfoContentHeaderComponent,
  FirstImpressionIntroModalComponent,
  SkillsByLevelPipe,
  CanDisplayNavItemPipe,
]

@NgModule({
  declarations: [...DeclareExportComponents, UIModalIntroComponent, ReferralLinkCopyComponent],
  imports: [
    RouterModule,
    CommonModule,
    ReactiveFormsModule, // For header login modal
    UiComponentLibraryModule,
    SharedLibModule.forRoot(environment, ERROR_HANDLING),
    TranslateModule,
    LetDirective,
    CloudflareStreamModule,
    NgxSummernoteModule,
    MultimediaModule,
    E11InputErrorsModule.forRoot({
      additional: {
        phoneNumber: 'Please enter a valid phone number',
        invalidDate: 'Please enter a valid date',
        invalidStartDate: 'Must be before end date',
        invalidEndDate: 'Must be after start date',
        DateFuture: 'Cannot be a future date', // TODO consider how to get static type checking on these
        DateEmptyYear: 'Please pick a year',
        DateOrder: 'End date is earlier than start date',
        shareProfile: 'Please name your profile share',
      },
    }),
    MessagingModule.forRoot({
      config: {
        provide: MessagingConfigurationProvider,
        useClass: MessagingConfigFromApp,
      },
      conversationCustomDataProvider: ConversationCustomDataProvider,
      cacheDBName: 'cnect-storage',
      conversationConfigProviders: [
        CandidateApplicationConversationDMProvider,
        CandidateApplicationConversationProvider,
        DirectMessageConversationConfigProvider,
        SocialDmConfigProvider,
      ],
    }),
    CommunityModule.forRoot({
      configProvider: CnectCommunityConfigProvider,
      cacheDBName: 'cnect-storage',
    }),
    NgxIntlTelInputModule,
    NotificationHandlerModule.forRoot({
      notificationHandlers: [
        RequestedUpdateNotificationHandler,
        VirtualDialogueSubmittedCandidate,
        JobPostClosedNotificationHandler,
        JobPostReactivatedNotificationHandler,
        EmployerNotInterestedNotificationHandler,
        ApplicationReactivatedNotificationHandler,
        ResumeGeneratedNotificationHandler,
        ContentReportedNotificationHandler,
        ConnectionAcceptedNotificationHandler,
        ConnectionRequestedNotificationHandler,
        MessagingUserMentionedHandler,
        CommunityMemberMentionedNotificationHandler,
        CandidateApplicationResubmittedHandler,
      ],
      configProvider: NotificationConfigProvider,
    }),
    WebUtilityModule,
    AccessModule.forRoot({ configProvider: AccessConfigProvider }),
    QRCodeModule,
  ],
  providers: [],
  exports: [
    ...DeclareExportComponents,
    UiComponentLibraryModule,
    TranslateModule,
    SharedLibModule,
    MessagingModule,
    NgxSummernoteModule,
    UiNotificationComponent,
    UiNotificationLongComponent,
    MobileSideDrawerMenuComponent,
    WebUtilityModule,
    MultimediaModule,
  ],
})
export class SharedModule {}

import { ICandidateProfile } from '@candidate/app/models/candidate-profile/candidate-profile.model'
import { isNotNil } from '@engineering11/utility'
import { createFeatureSelector, createSelector, select } from '@ngrx/store'
import { pipe } from 'rxjs'
import { distinctUntilChanged, filter, map } from 'rxjs/operators'
import { ProfileState } from './profile.reducer'

export const getProfileStore = createFeatureSelector<ProfileState>('profile')

export const getCandidateProfile = createSelector(getProfileStore, state => state.candidateProfile)
export const getCandidateProfileDocument = createSelector(getProfileStore, state => state.candidateProfile?.document ?? null)
// export const getFirstImpressions = createSelector(getProfileStore, state => state.candidateProfile?.document?.firstImpressions)

/**
 * Only re-emits if firstImpressions array changes, not if candidate profile changes
 * Does not emit if profile is nil
 */
export const getFirstImpressions = pipe(
  select(getCandidateProfileDocument),
  filter(isNotNil),
  map(profile => profile.firstImpressions),
  distinctUntilChanged()
)

import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core'
import { IJobSearchResultVM } from '@candidate/app/models/jobs/job-search.model'
import { ViewportService } from '@engineering11/web-utilities'
import { map } from 'rxjs/operators'

@Component({
  selector: 'search-result-list',
  templateUrl: './search-result-list.component.html',
  styleUrls: ['./search-result-list.component.scss'],
})
export class SearchResultListComponent implements AfterViewInit, OnDestroy {
  @ViewChild('endOfListEl') endOfListEl: ElementRef<HTMLElement> | undefined
  @ViewChild('searchResults') searchResults: ElementRef<HTMLElement> | undefined

  @Input() jobs: IJobSearchResultVM[] | undefined

  @Input() selectedJob: IJobSearchResultVM | null = null
  @Input() isLoadingMoreJobs: boolean = false
  @Input() hasMoreResults: boolean = false
  @Input() isLoadingResults: boolean = false
  @Output() jobSelected = new EventEmitter<IJobSearchResultVM>()

  @Output() loadMoreJobs = new EventEmitter()

  isMobile$ = this.viewportService.belowBreakpoint('md')
  observer!: IntersectionObserver

  constructor(private viewportService: ViewportService) {}

  ngAfterViewInit(): void {
    this.initializeInfiniteLoading()
  }

  initializeInfiniteLoading() {
    const options = { root: this.searchResults!.nativeElement }

    this.observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting && this.jobs?.length && !this.isLoadingMoreJobs && this.hasMoreResults) {
        this.loadMoreJobs.emit()
      }
    }, options)

    this.observer.observe(this.endOfListEl!.nativeElement)
  }

  ngOnDestroy() {
    if (this.observer) {
      this.observer.disconnect()
    }
  }
}

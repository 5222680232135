<ng-container *ngrxLet="searchResultLoading$ as isLoading">
  <ng-container *ngrxLet="nextSearchResultLoading$ as isLoadingMoreResults">
    <ng-container *ngrxLet="jobSearchResult$ as searchResult">
      <ng-container *ngrxLet="selectedJob$ as selectedJob">
        <ng-container *ngrxLet="isMobile$ as isMobile">
          <div class="e11-block lg:e11-flex e11-h-auto sm:e11-h-20 e11-mb-6">
            <!-- first row -->
            <!-- left -->
            <div
              class="e11-w-full e11-h-full e11-hidden lg:e11-flex e11-items-center lg:e11-w-[320px] lg:e11-min-w-[320px] lg:e11-max-w-[320px] e11-mb-6"
            >
              <!-- Mr Dark Blue-->
              <div class="e11-w-full e11-justify-between e11-border e11-p-4 e11-border-skin-grey e11-bg-skin-primary e11-rounded e11-text-skin-white">
                <div class="e11-w-full">
                  <h3>{{ 'Results' | translate }}</h3>
                </div>
                <div class="e11-w-full e11-flex e11-items-center">
                  <!-- <div class="e11-grow e11-text-sm e11-cursor-default">Save search</div> (Wait for save search results backend) -->
                  <div class="e11-grow e11-flex e11-justify-end">
                    <span class="e11-text-sm">{{ searchResult?.totalResults ?? 0 }} {{ 'Results' | translate }}</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- right -->
            <div class="e11-w-full lg:e11-grow e11-ml-0 lg:e11-ml-6 e11-mb-6">
              <search-form (search)="onSearch($event); showDetailsOnMobile = false"></search-form>
            </div>
          </div>

          <!-- second row -->
          <!-- left -->
          <div class="e11-block lg:e11-flex e11-mb-16">
            <div class="e11-w-full lg:e11-w-[320px] lg:e11-min-w-[320px] lg:e11-max-w-[320px] e11-mb-6">
              <div
                (click)="hideDetails()"
                *ngIf="isMobile && showDetailsOnMobile"
                class="e11-flex e11-w-full e11-items-center e11-mb-3 e11-text-skin-primary-accent"
                id="mobileBackButton"
              >
                <span class="material-icons-outlined e11-mr-1">arrow_back</span>
                {{ 'Back to Jobs List' | translate }}
              </div>

              <div *ngIf="(isMobile && !showDetailsOnMobile) || !isMobile" class="e11-w-full">
                <search-result-list
                  (jobSelected)="selectJob($event)"
                  (loadMoreJobs)="loadMoreJobs(searchResult?.nextPageToken ?? '')"
                  [hasMoreResults]="searchResult?.nextPageToken !== ''"
                  [isLoadingMoreJobs]="isLoadingMoreResults"
                  [isLoadingResults]="isLoading"
                  [jobs]="searchResult?.jobs"
                  [selectedJob]="selectedJob"
                ></search-result-list>
              </div>

              <!-- Conditions -->
            </div>
            <!-- right -->
            <div class="e11-w-full lg:e11-grow e11-ml-0 lg:e11-ml-6">
              <search-result-detail
                [loading]="isLoading"
                [selectedJob]="selectedJob"
                *ngIf="(isMobile && showDetailsOnMobile) || !isMobile"
              ></search-result-detail>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>

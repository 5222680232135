import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { UserConfigProvider } from '@candidate/app/config/user.config'
import { IUserRestService, UserModule, UserRestService } from '@engineering11/user-web'
import { LetDirective } from '@ngrx/component'
import { UiComponentLibraryModule } from '../_component-library/component-library.module'
import { SharedModule } from '../_shared/shared.module'
import { PublicComponent } from './public.component'
import { PublicRoutingModule } from './public.routing'
import { PublicPrivacyComponent } from './views/privacy/privacy.component'
import { PublicTermsComponent } from './views/terms/terms.component'

@NgModule({
  declarations: [PublicComponent, PublicTermsComponent, PublicPrivacyComponent],
  imports: [
    CommonModule,
    SharedModule,
    PublicRoutingModule,
    UiComponentLibraryModule,
    FormsModule,
    ReactiveFormsModule,
    UserModule.forRoot({ configProvider: UserConfigProvider }),
    LetDirective,
  ],
  exports: [],
  providers: [{ provide: IUserRestService, useClass: UserRestService }],
})
export class PublicModule {}

import { Pipe, PipeTransform } from '@angular/core'
import { IdenticonService } from '../services/identicon.service'

@Pipe({ name: 'toIdenticon' })
export class IdenticonPipe implements PipeTransform {
  constructor(private identiconService: IdenticonService) {}
  transform(userId: string): string {
    return this.identiconService.buildPath(userId)
  }
}

import { Component, EventEmitter, Output } from '@angular/core'
import { CandidateNavigationInfoContentService, InfoContentRoutes } from '@candidate/app/services/candidate-navigation-info-content.service'
import { Store } from '@ngrx/store'
import { AnimationFading } from 'projects/shared-lib/src/lib/e11-animations'
import { Observable, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { CnectFeatures, ConfigStore } from 'shared-lib'

@Component({
  selector: 'info-content-header',
  templateUrl: './info-content-header.component.html',
  styleUrls: ['./info-content-header.component.scss'],
  animations: [AnimationFading.shrinkFadeOut, AnimationFading.scaleUp],
})
export class InfoContentHeaderComponent {
  @Output() closeInfoContent = new EventEmitter<boolean>()
  features$: Observable<CnectFeatures | undefined> = this.configStore.features$
  features?: CnectFeatures
  destroy$: Subject<boolean> = new Subject<boolean>()

  infoContentRoutes = InfoContentRoutes

  constructor(private store: Store, private navService: CandidateNavigationInfoContentService, private configStore: ConfigStore) {
    this.features$.pipe(takeUntil(this.destroy$)).subscribe(features => (this.features = features))
  }

  navigateTo(route: string) {
    this.navService.navigateTo(route)
  }
}

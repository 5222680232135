import { Injectable } from '@angular/core'
import { BaseLocalStorageService } from '@engineering11/web-utilities'
import { ICnectUser } from '../model/interfaces'
import { SwimlaneConfig } from '../model/swimlane.model'
import { ViewModes } from '../constants'

export interface ILocalStorage {
  user: ICnectUser
  candidateListSwimlaneConfig: SwimlaneConfig
  jobPostsViewMode: { [jobPostId: string]: ViewModes }
  token: string
}

@Injectable({ providedIn: 'root' })
export class LocalStorageService extends BaseLocalStorageService<ILocalStorage> {
  public getAccessToken(): string | null {
    return this.getItem('token')
  }
}

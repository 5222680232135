import { Color } from '@engineering11/ui-lib/e11-ui-config'

export enum ChipType {
  Incomplete = 'Incomplete',
  UpdateRequested = 'Update Requested',
  DialogueAvailable = 'Interview Session Available',
  Withdrawn = 'Withdrawn',
  ApplicationDeclined = 'Application Declined',
  ClosedByEmployer = 'Closed By Employer',
}

export interface IApplicationChip {
  type: ChipType
  color: Color
  icon?: string
}

export const typeToColor: { [key: string]: Color } = {
  [ChipType.Incomplete]: 'complimentary',
  [ChipType.UpdateRequested]: 'purple',
  [ChipType.DialogueAvailable]: 'secondary',
  [ChipType.Withdrawn]: 'neutral',
  [ChipType.ApplicationDeclined]: 'error',
  [ChipType.ClosedByEmployer]: 'neutral',
}

export const typeToIcon: { [key: string]: string } = {
  [ChipType.Incomplete]: '',
  [ChipType.UpdateRequested]: 'quiz',
  [ChipType.DialogueAvailable]: 'question_answer',
  [ChipType.Withdrawn]: '',
  [ChipType.ApplicationDeclined]: 'not_interested',
  [ChipType.ClosedByEmployer]: '',
}

export type ApplicationChipsDict = { [jobPostId: string]: IApplicationChip[] }

import { NgModule } from '@angular/core'
import { canActivate } from '@angular/fire/compat/auth-guard'
import { RouterModule, Routes } from '@angular/router'
import { loggedInOnlyGuard } from '@engineering11/auth-web'
import { ConfigModule } from '@engineering11/config-web'
import { CustomerClientDataNotFoundComponent } from '@engineering11/customer-web'
import { AccountDeactivationComponent } from './components/deactivation/deactivation.component'
import { FileNotFoundComponent } from './modules/_404/404.component'

const routes: Routes = [
  {
    path: 'public',
    loadChildren: () => import('./modules/public/public.module').then(m => m.PublicModule),
    // canActivate: [AuthGuard],
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule),
  },
  {
    path: 'jobs',
    loadChildren: () => import('./modules/jobs/jobs.module').then(m => m.JobsModule),
    ...canActivate(loggedInOnlyGuard('/auth/login')),
  },
  {
    path: 'landing',
    loadChildren: () => import('./modules/landing/landing.module').then(m => m.LandingModule),
  },
  {
    path: 'actions',
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/email-actions/email-actions.module').then(m => m.EmailActionsModule),
      },
    ],
  },
  {
    path: 'account',
    loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule),
    ...canActivate(loggedInOnlyGuard('/auth/login')),
  },
  {
    path: 'preferences',
    loadChildren: () => import('./modules/preferences/preferences.module').then(m => m.PreferencesModule),
    ...canActivate(loggedInOnlyGuard('/auth/login')),
  },
  {
    path: 'info',
    loadChildren: () => import('./modules/info/info.module').then(m => m.InfoModule),
    ...canActivate(loggedInOnlyGuard('/auth/login')),
  },
  {
    path: 'applications',
    loadChildren: () => import('./modules/applications/applications.module').then(m => m.ApplicationsModule),
  },
  {
    path: 'home/applications',
    redirectTo: '/applications',
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
    ...canActivate(loggedInOnlyGuard('/auth/login')),
  },
  {
    path: 'c',
    loadChildren: () => import('./modules/layout-container/layout-container.module').then(m => m.LayoutContainerModule),
    ...canActivate(loggedInOnlyGuard('/auth/login')),
  },
  {
    path: 'network',
    loadChildren: () => import('./modules/network/network.module').then(m => m.NetworkModule),
    ...canActivate(loggedInOnlyGuard('/auth/login')),
  },
  {
    path: 'account-deactivated',
    component: AccountDeactivationComponent,
  },
  {
    path: 'about',
    redirectTo: 'home/about',
  },
  {
    path: 'notification',
    loadChildren: () => import('./modules/notification/notification.module').then(m => m.NotificationModule),
    ...canActivate(loggedInOnlyGuard('/auth/login')),
  },
  {
    path: 'resume',
    loadChildren: () => import('./modules/resume/resume.module').then(m => m.ResumeModule),
    ...canActivate(loggedInOnlyGuard('/auth/login')),
  },
  {
    path: 'registration',
    loadChildren: () => import('./modules/registration/registration.module').then(m => m.RegistrationModule),
    ...canActivate(loggedInOnlyGuard('/auth/login')),
  },
  {
    path: 'account-deactivated',
    redirectTo: 'auth/account-deactivated',
    pathMatch: 'full',
  },
  { path: 'config', loadChildren: () => ConfigModule },
  {
    path: 'tenant-not-found',
    component: CustomerClientDataNotFoundComponent,
  },
  { path: '404', component: FileNotFoundComponent },
  {
    path: '',
    redirectTo: '/home/profiles',
    pathMatch: 'full',
  },
  { path: '**', redirectTo: '404' },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: 'enabled',
      scrollOffset: [0, 120],
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
      enableTracing: false,
      urlUpdateStrategy: 'eager',
    }),
  ],
  exports: [RouterModule],

  providers: [
    // Commenting out because the new UX subscribes to the route
    // { provide: RouteReuseStrategy, useClass: E11RouteReuseStrategy }
  ],
})
export class AppRoutingModule {}

<div *ngIf="jobDetail && jobDetail?.status === 'Published'" class="e11-container-full e11-mx-auto">
  <div class="e11-flex e11-px-4 e11-mb-4 e11-mt-8 e11-pt-4">
    <div class="e11-w-3/4">
      <h1>{{ jobDetail.jobTitle }}</h1>
    </div>
    <div class="e11-w-1/4 e11-text-right">
      <e11-button id="button_apply1" name="button_apply1" color="primary" (click)="beginApply()" value="{{ 'Apply' | translate }}"> </e11-button>
    </div>
  </div>

  <div class="e11-mb-16 e11-pb-16">
    <e11-panel>
      <div class="row">
        <div class="col-lg-8 col-md-12 e11-border-r e11-border-skin-app-borders">
          <h4 class="text-accent-blue">{{ 'Job Description' | translate }}</h4>
          <div [ngxSummernoteView]="jobDetail.description || ''"></div>

          <e11-divider [margin]="2" [width]="100"></e11-divider>

          <h4 class="text-accent-blue">{{ 'Minimum Qualifications' | translate }}</h4>
          <div *ngIf="jobDetail.minimumQualifications" [ngxSummernoteView]="jobDetail?.minimumQualifications || ''"></div>

          <div class="spacer-2rem"></div>
          <div *ngIf="jobDetail.responsibilities">
            <h4 class="text-accent-blue">{{ 'Responsibilities' | translate }}</h4>
            <div *ngIf="jobDetail.responsibilities" [ngxSummernoteView]="jobDetail?.responsibilities || ''"></div>
          </div>

          <div class="e11-mt-10" *ngIf="jobDetail.selectedSkills && jobDetail.selectedSkills.length">
            <h4 class="text-accent-blue">{{ 'Skills' | translate }}</h4>
            <div class="row line-item" *ngFor="let skill of jobDetail.selectedSkills">
              <div>
                <span class="e11-capitalize"> {{ skill.name }} </span> - <span class="e11-capitalize"> {{ skill.level }} </span
                ><span *ngIf="skill.required"> (Required) </span>
              </div>
            </div>
          </div>

          <div class="e11-mt-10" *ngIf="jobDetail.selectedCertifications && jobDetail.selectedCertifications.length">
            <h4 class="text-accent-blue">{{ 'Certifications' | translate }}</h4>
            <div class="row line-item" *ngFor="let certification of jobDetail.selectedCertifications">
              <div>
                <span class="e11-capitalize"> {{ certification.name }} </span> <span *ngIf="certification.required"> (Required) </span>
              </div>
            </div>
          </div>
          <div class="job-details">
            <div class="row line-item" *ngFor="let key of modelKeys">
              <div class="col-xs-12 col-md-3 nopadding-left">{{ translationModelKeys[key] | translate }}:</div>
              <div class="col-xs-12 col-md-8" *ngIf="!jobDetail[key]">
                <span>{{ 'Not Specified' | translate }}</span>
              </div>
              <div class="col-xs-12 col-md-8" *ngIf="jobDetail[key]">
                <span *ngIf="key === 'compensationLow' || key === 'compensationHigh'">
                  {{ jobDetail[key] | currency }}
                </span>
                <span *ngIf="key === 'educationLevel'">
                  {{ jobDetail[key] | degreeLevel | translate }}
                </span>
                <span *ngIf="key !== 'compensationLow' && key !== 'compensationHigh' && key !== 'educationLevel'">
                  {{ jobDetail[key] }}<span *ngIf="key === 'travelPercentage' && jobDetail[key]">%</span>
                </span>
              </div>
            </div>
          </div>

          <div class="spacer-2rem"></div>

          <div class="row" *ngIf="jobDetail.legalDisclaimer">
            <div class="col-xs-12 nopadding">
              {{ 'EEO Policy Statement' | translate }}:
              <div *ngIf="jobDetail.legalDisclaimer" [ngxSummernoteView]="jobDetail!.legalDisclaimer || ''"></div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-12 e11-pl-2">
          <h4 class="text-accent-blue">{{ 'Additional Information' | translate }}</h4>
          <p class="e11-flex e11-items-center">
            <span class="material-icons-outlined e11-mr-2 e11-text-skin-primary-accent"> schedule </span> &nbsp; {{ jobDetail.employmentType }}
          </p>
          <p class="e11-flex e11-items-center" *ngIf="jobDetail.requiredTravel">
            <span class="material-icons-outlined e11-mr-2 e11-text-skin-primary-accent"> flight_takeoff </span>
            {{ 'Travel required' | translate }}: {{ jobDetail.travelPercentage }}%
          </p>
          <p class="e11-flex e11-items-center" *ngIf="isScheduleAvailable">
            <span class="material-icons-outlined e11-mr-2 e11-text-skin-primary-accent"> date_range </span>
            {{ 'Work Schedule' | translate }}:
          </p>
          <!-- @Todo - make component, it at least three places  -->
          <ul class="e11-ml-8 e11-text-sm">
            <job-schedule [schedule]="jobDetail?.schedule"></job-schedule>
          </ul>
          <div class="spacer-2rem"></div>
          <div *ngIf="employer && employer.about">
            <h4 class="text-accent-blue">{{ 'About Us' | translate }}</h4>
            <h4>{{ employer.name }}</h4>
            <img
              *ngIf="employer.logoImageURL"
              [routerLink]="['/home']"
              src="{{ employer.logoImageURL }}"
              class="e11-h-16 e11-max-h-16 e11-object-contain e11-object-left"
            />
            <div class="spacer-2rem"></div>
            <div class="about">
              <e11-read-more [content]="employer.about" [isSummerNoteContent]="true" [characterLimit]="300"></e11-read-more>
              <!--            {{ employer.about }}-->
            </div>
          </div>
        </div>
      </div>

      <div class="e11-w-full e11-text-center">
        <div class="spacer-2rem"></div>
        <e11-button id="button_apply1" name="button_apply1" color="primary" (click)="beginApply()" value="{{ 'Apply' | translate }}"> </e11-button>
      </div>
      <div class="spacer-2rem"></div>
    </e11-panel>
  </div>
</div>

<div *ngIf="jobDetail && jobDetail?.status !== 'Published'" class="e11-container-full e11-mx-auto e11-text-center">
  <div class="e11-flex e11-px-4 e11-mb-4 e11-mt-8 e11-pt-4"></div>
  <img src="assets/images/applications-empty.png" alt="Cnect" class="e11-mx-auto" />
  <p class="e11-font-primary-demibold e11-text-2xl">{{ 'Sorry this job has been closed' }}</p>
  <p class="e11-py-0 e11-my-0">{{ 'Unfortunately this job has either been filled or closed by the employer' }}</p>
  <a [routerLink]="['/landing/search', jobDetail?.customerKey]" class="e11-text-skin-primary-accent">
    {{ 'click here to see other available positions from this company' }}</a
  >
</div>

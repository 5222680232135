import { Injectable } from '@angular/core'
import { RequestedUpdateNotification } from '@candidate/app/models/app-notification.model'
import { GetJob } from '@candidate/app/store/job/job.actions'
import { INotificationView, UserAppNotificationService } from '@engineering11/notifications-web'
import { INotificationHandler } from '@engineering11/notifications-web/lib/models/notification-handler.model'
import { Store } from '@ngrx/store'
import { CandidateHomeNavigationService } from '../candidate-home-navigation.service'

@Injectable({ providedIn: 'root' })
export class RequestedUpdateNotificationHandler implements INotificationHandler<RequestedUpdateNotification, INotificationView> {
  notificationType = 'candidate_application_update_requested'
  constructor(
    private candidateHomeNavigationService: CandidateHomeNavigationService,
    private userAppNotificationService: UserAppNotificationService,
    private store: Store
  ) {}

  onClick(notification: RequestedUpdateNotification): boolean | Promise<boolean> {
    this.store.dispatch(new GetJob(notification.jobId))
    this.markAsRead(notification)
    return this.candidateHomeNavigationService.requestedUpdates(notification.jobId)
  }

  markAsRead(notification: RequestedUpdateNotification) {
    if (notification.viewed) {
      return
    }
    this.userAppNotificationService.markNotificationAsRead(notification.id)
  }
}

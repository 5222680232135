import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { selectors } from '@candidate/app/store/selectors'
import { ICandidateUser } from '@cnect/user-shared'
import { Store, select } from '@ngrx/store'
import { Observable } from 'rxjs'

@Component({
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.scss'],
})
export class PublicComponent implements OnInit {
  currentUser$: Observable<ICandidateUser | null> = this.store.pipe(select(selectors.getCurrentUser))
  pdf_render = this.activatedRoute.snapshot.queryParams['pdf'] || false

  constructor(private store: Store, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {}
}

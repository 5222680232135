import { IEducation, IPortfolio, IReference, IWorkHistory, SkillsAndCerts } from 'shared-lib'
import { ICandidateProfile } from '../models/candidate-profile/candidate-profile.model'
import { removeDuplicatesByNameIgnoreCase } from './remove-duplicates'

export function addProfileSkillsAndCerts(currentProfile: ICandidateProfile, skillsAndCerts: SkillsAndCerts): ICandidateProfile {
  const skills = removeDuplicatesByNameIgnoreCase([...currentProfile.skills, ...skillsAndCerts.skills])
  const certifications = removeDuplicatesByNameIgnoreCase([...currentProfile.certifications, ...skillsAndCerts.certifications])
  return { ...currentProfile, skills, certifications }
}

export function updateProfileWorkHistory(currentProfile: ICandidateProfile, workHistory: IWorkHistory[]): ICandidateProfile {
  return { ...currentProfile, workHistory }
}

export function updateProfileEducation(currentProfile: ICandidateProfile, education: IEducation[]): ICandidateProfile {
  return { ...currentProfile, education }
}

export function updateProfileReferences(currentProfile: ICandidateProfile, references: IReference[]): ICandidateProfile {
  return { ...currentProfile, references }
}

export function updateProfilePortfolio(currentProfile: ICandidateProfile, portfolio: IPortfolio[]): ICandidateProfile {
  return { ...currentProfile, portfolio }
}

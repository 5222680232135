import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { IJobSearchResultVM } from '@candidate/app/models/jobs/job-search.model'

@Component({
  selector: 'search-line-item',
  template: `
    <div
      (click)="job && jobSelected.emit(job)"
      [ngClass]="{ 'e11-bg-skin-primary-accent/10 e11-border-skin-primary-accent': isSelected, 'hover:e11-border-skin-primary-accent': job }"
      class="e11-w-full e11-border e11-rounded-md e11-border-skin-grey e11-bg-skin-white e11-p-4 e11-cursor-pointer"
    >
      <p class="e11-font-primary-demibold e11-mb-0 e11-line-clamp-2 e11-skeleton-empty e11-min-w-full">{{ job?.jobTitle }}</p>
      <div [ngClass]="{ 'e11-mb-4': job }">
        <p class="e11-mb-2 e11-text-sm e11-skeleton-empty">{{ job?.companyName }}</p>
        <p *ngIf="!job" class="e11-skeleton-empty e11-mb-2"></p>
        <div *ngIf="!job" class="e11-w-1/2">
          <p class="e11-skeleton-empty e11-mb-0"></p>
        </div>
        <p *ngIf="job && job.displayLocation" class="e11-skeleton-empty e11-flex e11-items-start e11-mb-1 e11-text-sm e11-gap-1">
          <span class="material-icons-outlined e11-text-sm">location_on</span>
          {{ job.displayLocation }}
        </p>

        <p *ngIf="job && job.postPublishedDate" class="e11-flex e11-items-start e11-text-sm e11-gap-1">
          <span class="material-icons-outlined e11-text-sm">event</span> {{ job.postPublishedDate | date : 'MMM d, yyyy' }}
        </p>
      </div>
      <button class=" e11-rounded-lg e11-cursor-pointer hover:e11-bg-skin-primary-accent/10">
        <quick-apply-button *ngIf="job?.isInternalJob" [inline]="true"></quick-apply-button>
      </button>
    </div>
  `,
})
export class SearchResultLineItemComponent implements OnInit {
  @Input() job: IJobSearchResultVM | undefined = undefined
  @Input() isSelected: boolean = false
  @Output() jobSelected = new EventEmitter<IJobSearchResultVM>()

  constructor() {}

  ngOnInit(): void {}
}

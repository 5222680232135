import { Injectable } from '@angular/core'
import { environment } from '@candidate/environments/environment'
import { BaseTenantBootstrapConfigProvider } from '@engineering11/tenant-bootstrap-web'

@Injectable({ providedIn: 'root' })
export class CandidateTenantBootstrapConfigProvider implements BaseTenantBootstrapConfigProvider {
  get config() {
    return {
      appConfigBaseURL: environment.tenant.appConfigBaseURL,
      defaultTenant: environment.tenant.defaultTenant,
      notFoundRoute: '/tenant-not-found',
      hostPattern: /^([a-zA-Z0-9-]+-[a-zA-Z0-9-]+--[a-zA-Z0-9-]+\.web\.app|localhost(:\d+)?)$/,
    }
  }
}

<div class="bg"></div>
<div class="view e11-min-h-full e11-flex e11-flex-col e11-justify-center e11-py-6 e11-px-6">
  <div class="sm:e11-mx-auto sm:e11-w-full sm:e11-max-w-md">
    <div class="e11-w-full e11-flex e11-justify-center">
      <img [src]="logoUrl" class="e11-h-24" />
    </div>
    <h1 class="e11-mb-2 e11-text-center">
      {{ 'Sign in to your account' | translate }}
    </h1>
    <p class="e11-text-center e11-text-sm">
      {{ 'Not signed up?' | translate }}
      <a (click)="navigateToRegistration()" class="e11-cursor-pointer e11-text-skin-secondary hover:e11-text-skin-secondary">
        {{ 'Create an account' | translate }}
      </a>
    </p>
  </div>

  <div class="sm:e11-mx-auto sm:e11-w-full sm:e11-max-w-md">
    <e11-panel [bgOpacity]="90">
      <auth-login (onSubmit)="onSubmit($event)" (onForgottenPassword)="onForgottenPassword()"></auth-login>
    </e11-panel>
  </div>
</div>

import { Component, OnDestroy, OnInit } from '@angular/core'
import { InstantSearchConfig } from '@engineering11/angular-instantsearch/instantsearch/instantsearch'
import { environment } from '@candidate/environments/environment'
import * as algoliasearch from 'algoliasearch/lite'

import { BehaviorSubject, Subject } from 'rxjs'
import { ActivatedRoute, Router } from '@angular/router'
import { SEARCH_INDICES } from 'shared-lib'
import { PublicJobPostService } from '@candidate/app/services/jobs/jobs-public.service'
import { IEmployerDetailsVM } from '@candidate/app/models/employer/employer.model'
import { PublicEmployerService } from '@candidate/app/services/employer/employer-public.service'
import { LandingStateService } from '../../state.service'
import { takeUntil } from 'rxjs/operators'
import { JobPostStatus } from '@employer/app/models/job-post.model'

@Component({
  selector: 'app-job-search',
  templateUrl: './job-search.component.html',
  styleUrls: ['./job-search.component.scss'],
})
export class JobSearchComponent implements OnInit, OnDestroy {
  startAt: BehaviorSubject<string> = new BehaviorSubject('')

  config?: InstantSearchConfig

  searchParams: any = {
    hitsPerPage: 15,
    filters: `status: ${JobPostStatus.Published}`,
  }

  employer?: IEmployerDetailsVM | null
  customerKey?: string
  destroy$: Subject<boolean> = new Subject<boolean>()

  constructor(
    private route: ActivatedRoute,
    private jobPostService: PublicJobPostService,
    private employerService: PublicEmployerService,
    private stateService: LandingStateService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.customerKey = params['customerKey']
      if (this.customerKey) {
        this.jobPostService.getJobPostSearchKey(this.customerKey).subscribe(key => {
          this.config = {
            indexName: SEARCH_INDICES.JOB_POST,
            searchClient: algoliasearch(environment.algoliaAppId, key) as any,
          }
        })

        this.employerService
          .get(this.customerKey)
          .pipe(takeUntil(this.destroy$))
          .subscribe(employer => {
            this.employer = employer
            this.stateService.employer$?.next(employer)
          })
      }
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.unsubscribe()
  }

  search(searchText: string) {
    this.startAt.next(searchText)
  }
}

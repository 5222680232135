<div class="e11-min-h-full e11-flex e11-flex-col e11-justify-center e11-py-12 sm:e11-px-6 lg:e11-px-8">
  <div class="sm:e11-mx-auto sm:e11-w-full sm:e11-max-w-md">
    <img class="e11-mx-auto e11-h-24 e11-w-auto" src="/assets/images/logo/cnect-blue.png" alt="Cnect" />
  </div>

  <div class="e11-mt-4 sm:e11-mx-auto sm:e11-w-full sm:e11-max-w-xl">
    <div class="e11-bg-white e11-py-8 e11-px-4 e11-shadow sm:e11-rounded-lg sm:e11-px-10">
      <h2 class="e11-mt-1 e11-text-center e11-text-3xl e11-font-extrabold e11-text-skin-primary">
        {{ 'Terms of Use' | translate }}
      </h2>
      <sdk-terms-of-use [userId]="userId" (onSubmit)="onAccept($event)" (onDecline)="onDecline()"></sdk-terms-of-use>
    </div>
  </div>
</div>

import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  HostListener,
  Input,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core'
import { E11SideOverService } from '@engineering11/ui-lib/e11-side-over'
import { UiScrollLockingService } from 'projects/shared-lib/src/lib/service/scroll-locking.service'
import { UiTemplate } from '../modal-container/ui-template'
import { E11Logger } from '@engineering11/web-api-error'

@Component({
  selector: 'form-sideover-container',
  template: `
    <ng-template #sideOverContent>
      <div class="e11-h-full e11-divide-y e11-divide-gray-200 e11-flex e11-flex-col e11-bg-white e11-shadow-xl">
        <div class="e11-flex-1 e11-h-0 e11-overflow-y-auto e11-overscroll-none">
          <div class="e11-py-6 e11-px-4 e11-bg-skin-primary sm:e11-px-6">
            <div class="e11-flex e11-items-center e11-justify-between e11-mb-2">
              <h3 class="e11-text-white e11-mb-0 e11-flex e11-items-center" id="slide-over-title">
                <div *ngIf="closeIconLocation === 'left'" class="e11-mr-3 e11-h-7 e11-flex e11-items-center">
                  <button
                    (click)="close()"
                    type="button"
                    class="e11-flex e11-items-center e11-justify-center e11-bg-skin-primary e11-h-6 e11-w-6 e11-rounded-md e11-text-white/70 hover:e11-text-white focus:e11-outline-none focus:e11-ring-1 focus:e11-ring-white hover:e11-ring-1 hover:e11-ring-white"
                    [ngClass]="{ 'e11-cursor-wait': loading }"
                  >
                    <span class="e11-sr-only">Close panel</span>
                    <span class="material-icons-outlined md-18"> arrow_back </span>
                  </button>
                </div>
                {{ headerTitle | translate }}
              </h3>
              <div *ngIf="closeIconLocation === 'right'" class="e11-ml-3 e11-h-7 e11-flex e11-items-center">
                <button
                  (click)="close()"
                  type="button"
                  class="e11-flex e11-items-center e11-justify-center e11-bg-skin-primary e11-h-6 e11-w-6 e11-rounded-md e11-text-white/70 hover:e11-text-white focus:e11-outline-none focus:e11-ring-1 focus:e11-ring-white hover:e11-ring-1 hover:e11-ring-white"
                  [ngClass]="{ 'e11-cursor-wait': loading }"
                >
                  <span class="e11-sr-only">Close panel</span>
                  <span class="material-icons-outlined md-18"> close </span>
                </button>
              </div>
            </div>
            <div class="">
              <p class="e11-text-sm e11-text-white/70">
                {{ subTitle | translate }}
              </p>
            </div>
          </div>

          <ng-container *ngIf="isOpen">
            <ng-container *ngTemplateOutlet="uiTemplates['content']"></ng-container>
          </ng-container>
        </div>

        <div *ngIf="showFooter" class="e11-flex e11-justify-end e11-p-4 e11-flex-shrink-0">
          <e11-button [value]="'Cancel' | translate" color="neutral" [ghost]="true" (click)="close()" [buttonGroup]="true"> </e11-button>
          <e11-button
            *ngIf="showDeleteButton"
            (click)="removeItem.emit()"
            value="{{ 'Delete' | translate }}"
            color="secondary"
            [buttonGroup]="true"
            [ghost]="true"
          >
          </e11-button>

          <e11-button
            *ngIf="showSubmitButton"
            (click)="submitItem.emit()"
            value="{{ 'Save' | translate }}"
            [color]="'primary-accent'"
            [disabled]="submitDisabled"
          >
          </e11-button>
        </div>
      </div>
    </ng-template>
  `,
})
export class FormSideoverContainerComponent implements AfterContentInit {
  @Input() headerTitle = ''
  @Input() subTitle = ''
  @Input() showDeleteButton: boolean = false
  @Input() showSubmitButton: boolean = true
  @Input() showFooter: boolean = true
  @Input() loading: boolean = false
  @Input() submitDisabled: boolean = false // Consider reaching into the form in a standardised way
  @Input() closeIconLocation: 'left' | 'right' = 'right'

  @Output() removeItem = new EventEmitter()
  @Output() closeSideOver = new EventEmitter()
  @Output() submitItem = new EventEmitter()

  @ViewChild('sideOverContent') sideOverContent!: TemplateRef<any>
  @ContentChildren(UiTemplate) templates!: QueryList<any>

  uiTemplates: any = {}
  isOpen = false

  constructor(private sideOverService: E11SideOverService, private scrollLockService: UiScrollLockingService) {}

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    this.close()
  }

  ngAfterContentInit() {
    this.templates.forEach(item => {
      this.uiTemplates[item.getType()] = item.template
    })
  }

  open() {
    this.isOpen = true
    this.sideOverService.overlayToggle(true, this.sideOverContent, { size: 'lg' })
    this.scrollLockService.scrollLock(true)
  }

  close() {
    this.closeSideOver.emit(false)
    this.sideOverService.overlayToggle(false)
    this.scrollLockService.scrollLock(false)
    // TODO: How to maintain the animation - setTimeout doesn't work because it allows the user to break their state by closing and quickly reopening
    this.isOpen = false // rip contents out of the DOM on close
  }
}

import { ICandidateDetails, ICandidateProfile, ICandidateProfileContentItem } from '@candidate/app/models/candidate-profile/candidate-profile.model'
import { Action } from '@ngrx/store'
import { ICertification, IEducation, IPortfolio, IReference, ISkill, IWorkHistory, MilitaryAffiliation, SkillsAndCerts } from 'shared-lib'

export enum ProfileActionTypes {
  getProfile = '[Candidate Profile] get',
  getProfileSuccess = '[Candidate Profile] get success',
  updateProfile = '[Candidate Profile] update',
  updateProfileSuccess = '[Candidate Profile] update success',
  updateProfileCandidateDetails = '[Candidate Profile] update candidate details',
  updatePhoto = '[Candidate Profile] update photo',
  updateProfileMilitaryAffiliations = '[Candidate Profile] update military affiliations',
  addProfileSkillsAndCerts = '[Candidate Profile] add skills and certs',
  updateProfileSkills = '[Candidate Profile] update skills',
  updateProfileCertifications = '[Candidate Profile] update certifications',
  updateProfileWorkHistory = '[Candidate Profile] update work history',
  updateProfileEducation = '[Candidate Profile] update education',
  updateProfileReferences = '[Candidate Profile] update references',
  updateProfilePortfolio = '[Candidate Profile] update portfolio',
  reset = '[Candidate Profile] reset',
  error = '[Candidate Profile] error',
}

export class GetProfile implements Action {
  readonly type = ProfileActionTypes.getProfile
  constructor() {}
}

export class GetProfileSuccess implements Action {
  readonly type = ProfileActionTypes.getProfileSuccess
  constructor(public payload: ICandidateProfileContentItem) {}
}

export class UpdateProfile implements Action {
  readonly type = ProfileActionTypes.updateProfile
  constructor(public payload: ICandidateProfile) {}
}

export class UpdateProfileSuccess implements Action {
  readonly type = ProfileActionTypes.updateProfileSuccess
  constructor(public payload: ICandidateProfileContentItem) {}
}

export class UpdateProfileCandidateDetails implements Action {
  readonly type = ProfileActionTypes.updateProfileCandidateDetails
  constructor(public payload: ICandidateDetails) {}
}

export class UpdatePhoto implements Action {
  readonly type = ProfileActionTypes.updatePhoto
  constructor(public payload: string | null) {}
}

export class UpdateProfileMilitaryAffiliations implements Action {
  readonly type = ProfileActionTypes.updateProfileMilitaryAffiliations
  constructor(public payload: MilitaryAffiliation[]) {}
}

export class UpdateProfileSkills implements Action {
  readonly type = ProfileActionTypes.updateProfileSkills
  constructor(public payload: ISkill[]) {}
}

export class UpdateProfileCertifications implements Action {
  readonly type = ProfileActionTypes.updateProfileCertifications
  constructor(public payload: ICertification[]) {}
}

export class AddProfileSkillsAndCerts implements Action {
  readonly type = ProfileActionTypes.addProfileSkillsAndCerts
  constructor(public payload: SkillsAndCerts) {}
}

export class UpdateProfileWorkHistory implements Action {
  readonly type = ProfileActionTypes.updateProfileWorkHistory
  constructor(public payload: IWorkHistory[]) {}
}

export class UpdateProfileEducation implements Action {
  readonly type = ProfileActionTypes.updateProfileEducation
  constructor(public payload: IEducation[]) {}
}

export class UpdateProfileReferences implements Action {
  readonly type = ProfileActionTypes.updateProfileReferences
  constructor(public payload: IReference[]) {}
}

export class UpdateProfilePortfolio implements Action {
  readonly type = ProfileActionTypes.updateProfilePortfolio
  constructor(public payload: IPortfolio[]) {}
}

export class Reset implements Action {
  readonly type = ProfileActionTypes.reset
  constructor() {}
}

export class ErrorAction implements Action {
  readonly type = ProfileActionTypes.error
  constructor(public payload: any[]) {}
}

export type ProfileActions =
  | GetProfile
  | GetProfileSuccess
  | UpdateProfile
  | UpdateProfileSuccess
  | UpdateProfileCandidateDetails
  | AddProfileSkillsAndCerts
  | UpdateProfileSkills
  | UpdateProfileCertifications
  | UpdateProfileWorkHistory
  | UpdateProfileEducation
  | UpdateProfileReferences
  | UpdateProfilePortfolio
  | Reset
  | ErrorAction

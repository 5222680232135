import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { IEmployerDetailsVM } from '@candidate/app/models/employer/employer.model'
import { IPublicJobPostVM, IPublicJobPostVMKeys } from '@candidate/app/models/jobs/job-post-public.model'
import { PublicEmployerService } from '@candidate/app/services/employer/employer-public.service'
import { PublicJobPostService } from '@candidate/app/services/jobs/jobs-public.service'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { LandingStateService } from '../../state.service'
import { CandidateHomeNavigationService } from '@candidate/app/services/candidate-home-navigation.service'

@Component({
  selector: 'app-job-post',
  templateUrl: './job-post.component.html',
  styleUrls: ['./job-post.component.scss'],
})
export class JobPostComponent implements OnInit, OnDestroy {
  jobPostId?: string
  jobDetail?: IPublicJobPostVM
  destroy$: Subject<boolean> = new Subject<boolean>()
  employer?: IEmployerDetailsVM | null

  modelKeys: IPublicJobPostVMKeys[] = ['experienceLevel', 'educationLevel', 'benefits']

  translationModelKeys: any = {
    experienceLevel: 'Experience Level',
    educationLevel: 'Education Level',
    travelPercentage: 'Department',
    compensationLow: 'Compensation Low',
    compensationHigh: 'Compensation High',
    benefits: 'Benefits',
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private jobPostService: PublicJobPostService,
    private employerService: PublicEmployerService,
    private stateService: LandingStateService,
    private candidateNavigationService: CandidateHomeNavigationService
  ) {}
  get isScheduleAvailable() {
    return this.jobDetail?.schedule && this.jobDetail.schedule.length
  }
  async ngOnInit() {
    this.route.params.subscribe(params => {
      this.jobPostId = params['jobPostId']
      if (this.jobPostId) {
        this.jobPostService
          .get(this.jobPostId)
          .pipe(takeUntil(this.destroy$))
          .subscribe(post => {
            this.jobDetail = post
            this.employerService
              .get(this.jobDetail.customerKey)
              .pipe(takeUntil(this.destroy$))
              .subscribe(employer => {
                this.employer = employer
                this.stateService.employer$?.next(employer)
              })
          })
      } else {
        throw new Error('Missing parameter: jobPostId')
      }
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.unsubscribe()
  }

  beginApply() {
    if (this.jobPostId) {
      this.candidateNavigationService.applyToJob(this.jobPostId)
    }
  }
}

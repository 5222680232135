<div
  class="e11-flex e11-items-center e11-profile-card e11-flex e11-gap-2 e11-px-4 e11-py-2 hover:e11-bg-skin-primary-accent/10 e11-cursor-pointer"
  [ngClass]="{
    'e11-profile-card-selected e11-bg-skin-primary-accent/10 e11-border-skin-primary-accent': selectedResumeId === resume.id
  }"
>
  <div>
    <e11-avatar [imageUrl]="resume.photo?.url" [size]="'md'" [hasRing]="true" [hasBorder]="false"></e11-avatar>
  </div>
  <div class="e11-w-full">
    <div class="e11-line-clamp-1 e11-text-skin-primary-accent">{{ resume.title }}</div>
    <div class="e11-w-full e11-text-xs e11-text-skin-light">
      {{ resume.__updatedAt | date }}
    </div>
  </div>
</div>

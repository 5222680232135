import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { ISocialConfig, ISocialConfigProvider } from '@engineering11/social-web'
import { getCurrentToken, getCurrentUserId } from 'shared-lib'
import { environment } from '@candidate/environments/environment'
import { filter, map } from 'rxjs/operators'
import { isNotNil } from '@engineering11/utility'

@Injectable({ providedIn: 'root' })
export class SocialConfigProvider implements ISocialConfigProvider {
  constructor(private store: Store) {}
  /**
   * Attempting new pattern where all config is included in config provider
   * @returns
   */
  getConfig(): ISocialConfig {
    return {
      userId$: this.store.pipe(getCurrentUserId),
      baseUrl: environment.services.social,
      tokenFunction: () => localStorage.getItem('token') as string,
      token$: this.store.select(getCurrentToken).pipe(filter(isNotNil)),
    }
  }
}

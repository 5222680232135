import { Component, OnDestroy, OnInit } from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { SubscriptionManagementService } from '@candidate/app/services/subscription.management.service'
import { PrivacyPolicyService } from '@engineering11/user-web'
import { IPrivacyPolicy } from '@engineering11/user-web/lib/model/privacy.model'
@Component({
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss'],
})
export class PublicPrivacyComponent implements OnInit, OnDestroy {
  themeCurrent: any
  policy?: SafeHtml
  subs = new SubscriptionManagementService()
  isLoading: boolean = true

  constructor(private _sanitizer: DomSanitizer, private privacyService: PrivacyPolicyService) {}

  ngOnInit(): void {
    this.subs.next = this.privacyService.getMostRecentPolicy().subscribe((policy: IPrivacyPolicy) => {
      this.policy = this._sanitizer.bypassSecurityTrustHtml(policy.policy)
      this.isLoading = false
    })
  }

  ngOnDestroy(): void {
    this.subs.stop
  }
}

<ng-container *ngrxLet="user$ as currentUser">
  <ng-container *ngIf="currentUser">
    <input type="file" class="file-input" [accept]="allowedFileTypes" (change)="filePicked($event, currentUser)" #fileUpload />
    <span (click)="fileUpload.click()">
      <ng-content></ng-content>
    </span>
  </ng-container>
</ng-container>

<ng-template #resumeParseModal>
  <div class="e11-mb-4">
    <modal-resume-parse [resume]="parsedResume" (closeModal)="closeModal()"></modal-resume-parse>
  </div>
</ng-template>

<ng-container *ngrxLet="features$ as features">
  <div class="e11-mb-5 e11-hidden lg:e11-block app-nav-left e11-w-full">
    <div
      class="nav-prototype e11-border e11-border-skin-app-borders e11-text-skin-base e11-overflow-hidden e11-rounded"
      #navContainer
      style="top: {{ navTop + 'px' }}"
    >
      <div
        class="top e11-flex e11-items-center e11-h-12 e11-px-4 hover:e11-bg-skin-primary-accent/20-accent/20 e11-cursor-pointer e11-border-b e11-border-b-skin-grey/30"
        [routerLink]="[routes.APPLICATIONS]"
      >
        <span class="material-icons e11-text-skin-base e11-mr-2 md-18"> home </span>
        {{ 'Home' | translate }}
      </div>
      <div
        class="top e11-flex e11-items-center e11-h-12 e11-px-4 hover:e11-bg-skin-primary-accent/20 e11-cursor-pointer e11-border-b e11-border-b-skin-grey/30"
        [routerLink]="[routes.CONNECTIONS]"
      >
        <span class="material-icons e11-text-skin-base e11-mr-2 md-18"> groups </span>
        {{ 'My Network' | translate }}
      </div>
      <div
        class="top e11-flex e11-items-center e11-h-12 e11-px-4 hover:e11-bg-skin-primary-accent/20 e11-cursor-pointer e11-border-b e11-border-b-skin-grey/30"
        [routerLink]="[routes.COMMUNITIES]"
      >
        <span class="material-icons e11-text-skin-base e11-mr-2 md-18"> forum </span>
        {{ 'Communities' | translate }}
      </div>

      <div
        class="middle e11-relative e11-overscroll-contain e11-overflow-y-scroll e11-scrollbar-thumb-blue e11-scrollbar-thumb-rounded e11-scrollbar-track-blue-lighter e11-scrollbar-w-2 e11-scrolling-touch"
      >
        <div class="bg e11-absolute"></div>

        <!-- Applications -->
        <ul *ngIf="features?.network" class="e11-border-b e11-border-b-skin-grey/30">
          <li
            class="e11-flex e11-h-12 e11-px-4 e11-justify-between e11-items-center e11-cursor-pointer hover:e11-bg-skin-primary-accent/80 hover:e11-text-white"
            (click)="toggleSectionApplications()"
            [ngClass]="{ 'e11-bg-skin-primary-accent e11-text-white': showSectionApplications }"
          >
            <span class="e11-font-medium e11-text-md">{{ 'Applications' | translate }}</span>
            <span
              class="material-icons-outlined nav-icon"
              [ngClass]="{ 'nav-icon-active e11-text-white': showSectionApplications, 'e11-text-skin-light': !showSectionApplications }"
            >
              expand_more
            </span>
          </li>
          <div class="nav-messages-container e11-px-2" [ngClass]="{ 'nav-messages-container-active': showSectionApplications }">
            <ul class="e11-my-2">
              <li
                class="e11-flex e11-items-center e11-h-10 e11-px-6 e11-truncate e11-cursor-pointer hover:e11-bg-skin-primary-accent/20-accent/30 e11-rounded-sm"
                [routerLink]="['../info/applications']"
                [routerLinkActive]="'e11-bg-skin-primary-accent/20'"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                {{ 'Basics' | translate }}
              </li>
              <li
                class="e11-flex e11-items-center e11-h-10 e11-px-6 e11-truncate e11-cursor-pointer hover:e11-bg-skin-primary-accent/20-accent/30 e11-rounded-sm"
                [routerLink]="['../info/applications-communications']"
                [routerLinkActive]="'e11-bg-skin-primary-accent/20'"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                {{ 'Communications' | translate }}
              </li>
            </ul>
          </div>
        </ul>

        <!-- Profiles -->
        <ul *ngIf="features?.network" class="e11-border-b e11-border-b-skin-grey/30">
          <li
            class="e11-flex e11-h-12 e11-px-4 e11-justify-between e11-items-center e11-cursor-pointer hover:e11-bg-skin-primary-accent/80 hover:e11-text-white"
            (click)="toggleSectionProfiles()"
            [ngClass]="{ 'e11-bg-skin-primary-accent e11-text-white e11-border-b-transparent': showSectionProfiles }"
          >
            <span class="e11-font-medium e11-text-md">{{ 'Profiles' | translate }}</span>
            <span
              class="material-icons-outlined nav-icon"
              [ngClass]="{ 'nav-icon-active e11-text-white': showSectionProfiles, 'e11-text-skin-light': !showSectionProfiles }"
            >
              expand_more
            </span>
          </li>
          <div class="nav-messages-container e11-px-2" [ngClass]="{ 'nav-messages-container-active': showSectionProfiles }">
            <ul class="e11-my-2">
              <li
                class="e11-flex e11-items-center e11-h-10 e11-px-6 e11-truncate e11-cursor-pointer hover:e11-bg-skin-primary-accent/20-accent/30 e11-rounded-sm"
                [routerLink]="['../info/profiles/']"
                [routerLinkActive]="'e11-bg-skin-primary-accent/20'"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                {{ 'Profile Basics' | translate }}
              </li>
              <li
                class="e11-flex e11-items-center e11-h-10 e11-px-6 e11-truncate e11-cursor-pointer hover:e11-bg-skin-primary-accent/20-accent/30 e11-rounded-sm"
                [routerLink]="['../info/profiles/resume-parsing']"
                [routerLinkActive]="'e11-bg-skin-primary-accent/20'"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                {{ 'Resume Parsing' | translate }}
              </li>
            </ul>
          </div>
        </ul>

        <!-- Videos -->
        <ul *ngIf="features?.network" class="e11-border-b e11-border-b-skin-grey/30">
          <li
            class="e11-flex e11-h-12 e11-px-4 e11-justify-between e11-items-center e11-cursor-pointer hover:e11-bg-skin-primary-accent/80 hover:e11-text-white"
            (click)="toggleSectionVideos()"
            [ngClass]="{ 'e11-bg-skin-primary-accent e11-text-white e11-border-b-transparent': showSectionVideos }"
          >
            <span class="e11-font-medium e11-text-md">{{ 'Videos' | translate }}</span>
            <span
              class="material-icons-outlined nav-icon"
              [ngClass]="{ 'nav-icon-active e11-text-white': showSectionVideos, 'e11-text-skin-light': !showSectionVideos }"
            >
              expand_more
            </span>
          </li>
          <div class="nav-messages-container e11-px-2" [ngClass]="{ 'nav-messages-container-active': showSectionVideos }">
            <ul class="e11-my-2">
              <li
                class="e11-flex e11-items-center e11-h-10 e11-px-6 e11-truncate e11-cursor-pointer hover:e11-bg-skin-primary-accent/20-accent/30 e11-rounded-sm"
                [routerLink]="['../info/videos/']"
                [routerLinkActive]="'e11-bg-skin-primary-accent/20'"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                {{ 'Video Basics' | translate }}
              </li>
              <li
                class="e11-flex e11-items-center e11-h-10 e11-px-6 e11-truncate e11-cursor-pointer hover:e11-bg-skin-primary-accent/20-accent/30 e11-rounded-sm"
                [routerLink]="['../info/videos/fiv']"
                [routerLinkActive]="'e11-bg-skin-primary-accent/20'"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                {{ 'First Impressions' | translate }}
              </li>
            </ul>
          </div>
        </ul>

        <!-- Network -->
        <ul *ngIf="features?.network" class="e11-border-b e11-border-b-skin-grey/30">
          <li
            class="e11-flex e11-h-12 e11-px-4 e11-justify-between e11-items-center e11-cursor-pointer hover:e11-bg-skin-primary-accent/80 hover:e11-text-white"
            (click)="toggleSectionNetwork()"
            [ngClass]="{ 'e11-bg-skin-primary-accent e11-text-white e11-border-b-transparent': showSectionNetwork }"
          >
            <span class="e11-font-medium e11-text-md">{{ 'Network' | translate }}</span>
            <span
              class="material-icons-outlined nav-icon"
              [ngClass]="{ 'nav-icon-active e11-text-white': showSectionNetwork, 'e11-text-skin-light': !showSectionNetwork }"
            >
              expand_more
            </span>
          </li>
          <div class="nav-messages-container e11-px-2" [ngClass]="{ 'nav-messages-container-active': showSectionNetwork }">
            <ul class="e11-my-2">
              <li
                class="e11-flex e11-items-center e11-h-10 e11-px-6 e11-truncate e11-cursor-pointer hover:e11-bg-skin-primary-accent/20-accent/30 e11-rounded-sm"
                [routerLink]="['../info/network/']"
                [routerLinkActive]="'e11-bg-skin-primary-accent/20'"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                {{ 'Basics' | translate }}
              </li>
            </ul>
          </div>
        </ul>

        <!-- Communities -->
        <ul *ngIf="features?.network" class="e11-border-b e11-border-b-skin-grey/30">
          <li
            class="e11-flex e11-h-12 e11-px-4 e11-justify-between e11-items-center e11-cursor-pointer hover:e11-bg-skin-primary-accent/80 hover:e11-text-white"
            (click)="toggleSectionCommunities()"
            [ngClass]="{ 'e11-bg-skin-primary-accent e11-text-white e11-border-b-transparent': showSectionCommunities }"
          >
            <span class="e11-font-medium e11-text-md">{{ 'Communities' | translate }}</span>
            <span
              class="material-icons-outlined nav-icon"
              [ngClass]="{ 'nav-icon-active e11-text-white': showSectionCommunities, 'e11-text-skin-light': !showSectionCommunities }"
            >
              expand_more
            </span>
          </li>
          <div class="nav-messages-container e11-px-2" [ngClass]="{ 'nav-messages-container-active': showSectionCommunities }">
            <ul class="e11-my-2">
              <li
                class="e11-flex e11-items-center e11-h-10 e11-px-6 e11-truncate e11-cursor-pointer hover:e11-bg-skin-primary-accent/20-accent/30 e11-rounded-sm"
                [routerLink]="['../info/communities/']"
                [routerLinkActive]="'e11-bg-skin-primary-accent/20'"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                {{ 'Basics' | translate }}
              </li>
              <li
                class="e11-flex e11-items-center e11-h-10 e11-px-6 e11-truncate e11-cursor-pointer hover:e11-bg-skin-primary-accent/20-accent/30 e11-rounded-sm"
                [routerLink]="['../info/communities/advanced']"
                [routerLinkActive]="'e11-bg-skin-primary-accent/20'"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                {{ 'Advanced' | translate }}
              </li>
            </ul>
          </div>
        </ul>
      </div>
      <!-- / middle -->

      <!-- bottom -->
      <!-- IF infoTips feature is on it will override the 'My Account' link at the bottom -->
      <div
        class="bottom e11-flex e11-items-center e11-h-12 e11-px-4 e11-bg-skin-app-bg-dark e11-text-skin-light hover:e11-bg-skin-black hover:e11-text-white e11-cursor-pointer e11-border-t e11-border-t-skin-grey/30"
        [routerLink]="[routes.ACCOUNT]"
      >
        <ng-container *ngIf="currentUser$ | async as currentUser">
          <e11-avatar [imageUrl]="currentUser.id | toIdenticon" [size]="'sm'" [hasRing]="false" [hasBorder]="false"></e11-avatar>
        </ng-container>
        <span class="e11-ml-2 e11-text-sm">
          {{ 'My Account' | translate }}
        </span>
      </div>
    </div>
  </div>
</ng-container>

import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { AppConfigService } from '@engineering11/tenant-bootstrap-web'

@Component({
  selector: 'quick-apply-button',
  templateUrl: './quick-apply-button.component.html',
  styleUrls: ['./quick-apply-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickApplyButtonComponent {
  logoUrl = this.appConfig.config.brand.logoImageDarkUrl
  @Input() showTooltip: boolean = false
  @Input() inline: boolean = false
  constructor(private appConfig: AppConfigService) {}
}

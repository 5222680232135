import { DragDropModule } from '@angular/cdk/drag-drop'
import { CdkScrollableModule } from '@angular/cdk/scrolling'
import { CommonModule } from '@angular/common'
import { ModuleWithProviders, NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { CloudflareStreamModule } from '@cloudflare/stream-angular'
import { ConfigModule } from '@engineering11/config-web'
import { MultimediaModule } from '@engineering11/multimedia-web'
import { WebUtilityModule } from '@engineering11/web-utilities'
import { LetDirective } from '@ngrx/component'
import { TranslateModule } from '@ngx-translate/core'
import { NgxSummernoteModule } from 'ngx-summernote'
import { AboutComponent } from './components/about/about.component'
import { MyJobsApplicationConfirmationsComponent } from './components/application-confirmations/application-confirmations.component'
import { ApplicationHistoryComponent } from './components/application-history/application-history.component'
import { CandidateEducationComponent } from './components/candidate-education/candidate-education.component'
import { CandidateSocialComponent } from './components/candidate-social/candidate-social.component'
import { CandidatePortfolioComponent } from './components/candidate-portfolio/candidate-portfolio.component'
import { CandidateLanguageComponent } from './components/candidate-language/candidate-language.component'
import { CandidatePersonalComponent } from './components/candidate-personal/candidate-personal.component'
import { CandidateReferencesComponent } from './components/candidate-references/candidate-references.component'
import { CandidateWorkHistoryComponent } from './components/candidate-work-history/candidate-work-history.component'
import { CopyrightComponent } from './components/copyright/copyright.component'
import { DateDisplayStartEndComponent } from './components/date-display-start-end/date-display-start-end.component'
import { AccountDeactivateAccountComponent } from './components/deactivate-account/deactivate-account.component'
import { EmptyAvatarComponent } from './components/empty-avatar/empty-avatar.component'
import { FeedbackGathererComponent } from './components/feedback-gatherer/feedback-gatherer.component'
import { RatingSliderComponent } from './components/feedback-gatherer/rating-slider.component'
import { CandidateJobMatchingComponent } from './components/job-matching-chart/job-matching-chart.component'
import { JobScheduleComponent } from './components/job-schedule/job-schedule.component'
import { AppLogoBlueComponent } from './components/logo/blue/logo.component'
import { AppLogoCnectBoostComponent } from './components/logo/cnect-boost/logo.component'
import { AppLogoJobsBlueComponent } from './components/logo/cnect-jobs-blue/logo.component'
import { AppLogoJobsWhiteComponent } from './components/logo/cnect-jobs-white/logo.component'
import { AppLogoWhiteComponent } from './components/logo/white/logo.component'
import { MaintenancePageComponent } from './components/maintenance-page/maintenance-page.component'
import { UiMaintenancePageComponent } from './components/maintenance-page/ui-maintenance-page.component'
import { ManageCertificationsComponent } from './components/manage-certifications/manage-certifications.component'
import { AccountManageProfilePhotoComponent } from './components/manage-profile-photo/manage-profile-photo.component'
import { ManageSkillsComponent } from './components/manage-skills/manage-skills.component'
import { PhotoEditComponent } from './components/photo-edit/photo-edit.component'
import { PortfolioClearComponent } from './components/portfolio/portfolio-clear.component'
import { PortfolioDocumentLineItemComponent } from './components/portfolio/portfolio-documents/porfolio-document-line-item.component'
import { PortfolioDocumentEmptyStateComponent } from './components/portfolio/portfolio-documents/portfolio-document-empty-state.component'
import { PortfolioDocumentsComponent } from './components/portfolio/portfolio-documents/portfolio-documents.component'
import { PortfolioItemHeaderComponent } from './components/portfolio/portfolio-item-header.component'
import { PortfolioLinksFormComponent } from './components/portfolio/portfolio-links/portfolio-links-form.component'
import { PortfolioLinksComponent } from './components/portfolio/portfolio-links/portfolio-links.component'
import { PortfolioLinksLineItemComponent } from './components/portfolio/portfolio-links/portoflio-links-line-item.component'
import { PortfolioPhotoLineItemComponent } from './components/portfolio/portfolio-photos/portfolio-photo-line-item.component'
import { PortfolioPhotosComponent } from './components/portfolio/portfolio-photos/portfolio-photos.component'
import { PortfolioUploadEmptyComponent } from './components/portfolio/portfolio-upload-empty.component'
import { PortfolioVideoLineItemComponent } from './components/portfolio/portfolio-videos/portfolio-video-line-item.component'
import { PortfolioVideosComponent } from './components/portfolio/portfolio-videos/portfolio-videos.component'
import { PortfolioComponent } from './components/portfolio/portfolio.component'
import { UiPortfolioCardComponent } from './components/portfolio/ui-portfolio-card.component'
import { ProfilePicComponent } from './components/profile-pic/profile-pic.component'
import { CandidateEducationProfileShareComponent } from './components/profile-share/candidate-education/candidate-education.component'
import { CandidateReferencesProfileShareComponent } from './components/profile-share/candidate-references/candidate-references.component'
import { CandidateWorkHistoryProfileShareComponent } from './components/profile-share/candidate-work-history/candidate-work-history.component'
import { QuickInfoComponent } from './components/quick-info/quick-info.component'
import { CandidateRequestOpenComponent } from './components/request-open/request-open.component'
import { RequestedUpdateMessageComponent } from './components/requested-update-message/requested-update-message.component'
import { SingleFirstImpressionCardComponent } from './components/single-first-impression-card/single-first-impression-card.component'
import { SingleFirstImpressionComponent } from './components/single-first-impression/single-first-impression.component'
import { CandidateCertificationsResultComponent } from './components/skills-certification/certifications/certifications.component'
import { CandidateSkillsCertificationResultComponent } from './components/skills-certification/skills-certification.component'
import { CandidateSkillsResultComponent } from './components/skills-certification/skills/skills.component'
import {
  SwimlaneColumnComponent,
  SwimlaneColumnHeaderDirective,
  SwimlaneColumnItemDirective,
  SwimlaneControlComponent,
} from './components/swimlane/partials'
import { SwimlaneComponent } from './components/swimlane/swimlane.component'
import { UiEducationLineItemComponent } from './components/ui-education/ui-education-line-item'
import { UiEducationLineItemCardComponent } from './components/ui-education/ui-education-line-item-card'
import { UiEducationSingleCardHorizontalComponent } from './components/ui-education/ui-education-single-card-horizontal'
import { UiEducationSingleCardVerticalComponent } from './components/ui-education/ui-education-single-card-vertical'
import { UiEducationsComponent } from './components/ui-education/ui-education.component'
import { LanguageChipComponent } from './components/ui-languages/language-chip.component'
import { UiReferenceLineItemComponent } from './components/ui-reference/ui-reference-line-item'
import { UiReferenceLineItemCardComponent } from './components/ui-reference/ui-reference-line-item-card'
import { UiReferenceSingleCardHorizontalComponent } from './components/ui-reference/ui-reference-single-card-horizontal'
import { UiReferenceSingleCardVerticalComponent } from './components/ui-reference/ui-reference-single-card-vertical'
import { UiReferencesComponent } from './components/ui-reference/ui-reference.component'
import { UISkeletonCardComponent } from './components/ui-skeleton/ui-skeleton-card'
import { UiSkillCountComponent } from './components/ui-skill-count/skill-count.component'
import { UiSkillComponent } from './components/ui-skill/skill.component'
import { UiTagCandidateComponent } from './components/ui-tag-candidate/ui-tag.component'
import { UiVideoCardComponent } from './components/ui-video/ui-video-card/ui-video-card.component'
import { UiVideoDetailComponent } from './components/ui-video/ui-video-detail/ui-video-detail.component'
import { UiVideoSkeletonCardComponent } from './components/ui-video/ui-video-skeleton-card/ui-video-skeleton-card.component'
import { UiVideoStandAloneComponent } from './components/ui-video/ui-video-stand-alone/ui-video-stand-alone.component'
import { UiWorkHistoryLineItemComponent } from './components/ui-work-history/ui-work-history-line-item'
import { UiWorkHistoryLineItemCardComponent } from './components/ui-work-history/ui-work-history-line-item-card'
import { UiWorkHistorySingleCardHorizontalComponent } from './components/ui-work-history/ui-work-history-single-card-horizontal'
import { UiWorkHistorySingleCardVerticalComponent } from './components/ui-work-history/ui-work-history-single-card-vertical'
import { UiWorkHistoryComponent } from './components/ui-work-history/ui-work-history.component'
import { VersionCheckerComponent } from './components/version-checker/version-checker.component'
import { VideoFileUploadComponent } from './components/video-file-upload/video-file-upload.component'
import { VideoRecordingControlsComponent } from './components/video-recording/partials/controls/controls.component'
import { VideoRecordingSettingsComponent } from './components/video-recording/partials/settings/settings.component'
import { VideoRecordingComponent } from './components/video-recording/video-recording.component'
import { VideoUploadFileFormComponent } from './components/video-upload-file-form/video-upload-file-form.component'
import { VideoUploadFileComponent } from './components/video-upload-file/video-upload-file.component'
import { ViewMilitaryAffiliationsComponent } from './components/view-military-affilations/view-military-affiliations.component'
import { ViewSocialMediaComponent } from './components/view-social-media/view-social-media.component'
import { CnectConfig } from './config'
import { ConfigProvider } from './config/config'
import { ENVIRONMENT, ERROR_HANDLING } from './constants'
import { FreeDraggingHandleDirective } from './directive/free-dragging-handle.directive'
import { FreeDraggingDirective } from './directive/free-dragging.directive'
import { OnVisibleDirective } from './directive/on-visible.directive'
import { IEnvironment, IPlatformAdminEnvironment } from './model/environment.model'
import { ErrorHandlingConfig } from './model/error-handling-config-model'
import { UiComponentLibraryModule } from './modules/_component-library/component-library.module'
import { AddressFormatPipe } from './pipe/address-format.pipe'
import {
  ApplicationFieldPipe,
  ApplicationStateDescriptionPipe,
  ApplicationStatePipe,
  DegreeLevelPipe,
  DialogueQuestionTypePipe,
  EmploymentTypePipe,
  ExperienceLevelPipe,
} from './pipe/enum.pipes'
import { InArrayPipe } from './pipe/in-array.pipe'
import { PortfolioItemTypeIsFilePipe } from './pipe/portfolio-item-type-is-file.pipe'
import { PortfolioFilterPipes } from './pipe/portfolio-items-by-type.pipe'
import { SharedLibComponent } from './shared-lib.component'
import { FilesModule } from '@engineering11/files-web'
import { PortfolioDocumentsPreviewComponent } from './components/portfolio/portfolio-documents/portfolio-documents-preview.component'
import { PortfolioVideosPreviewComponent } from './components/portfolio/portfolio-videos/portfolio-videos-preview.component'
import { PortfolioLinksPreviewComponent } from './components/portfolio/portfolio-links/portfolio-links-preview.component'
import { PortfolioPhotosPreviewComponent } from './components/portfolio/portfolio-photos/portfolio-photos-preview.component'
import { ConditionalLinkWrapperComponent } from './components/conditional-link-wrapper.component'
import { ProfileSectionPipe } from './pipe/profile-section.pipe'

const DeclareExportComponents = [
  MaintenancePageComponent,
  SharedLibComponent,
  EmptyAvatarComponent,
  VersionCheckerComponent,
  DateDisplayStartEndComponent,
  CandidateSkillsCertificationResultComponent,
  CandidateSkillsResultComponent,
  CandidateCertificationsResultComponent,
  CandidateRequestOpenComponent,
  RequestedUpdateMessageComponent,
  PhotoEditComponent,
  AccountManageProfilePhotoComponent,
  AccountDeactivateAccountComponent,
  ProfilePicComponent,
  QuickInfoComponent,
  ConditionalLinkWrapperComponent,

  UiSkillComponent,
  UiSkillCountComponent,
  UiEducationSingleCardVerticalComponent,
  UiEducationSingleCardHorizontalComponent,
  UiEducationLineItemComponent,
  UiEducationLineItemCardComponent,
  UiEducationsComponent,
  UiReferenceSingleCardVerticalComponent,
  UiReferenceLineItemComponent,
  UiReferenceLineItemCardComponent,
  UiReferenceSingleCardHorizontalComponent,
  UiReferencesComponent,
  UiWorkHistorySingleCardVerticalComponent,
  UiWorkHistoryLineItemComponent,
  UiWorkHistoryLineItemCardComponent,
  UISkeletonCardComponent,
  UiWorkHistorySingleCardHorizontalComponent,
  UiWorkHistoryComponent,
  UiVideoCardComponent,
  UiVideoStandAloneComponent,
  UiVideoSkeletonCardComponent,
  UiVideoDetailComponent,
  SingleFirstImpressionCardComponent,
  SingleFirstImpressionComponent,
  LanguageChipComponent,

  CandidateJobMatchingComponent,
  CandidatePersonalComponent,
  CandidateWorkHistoryComponent,
  CandidateEducationComponent,
  CandidateSocialComponent,
  CandidatePortfolioComponent,
  MyJobsApplicationConfirmationsComponent,
  CandidateReferencesComponent,
  CopyrightComponent,
  ApplicationHistoryComponent,
  AppLogoWhiteComponent,
  AppLogoBlueComponent,
  AppLogoJobsBlueComponent,
  AppLogoJobsWhiteComponent,
  AppLogoCnectBoostComponent,
  ViewSocialMediaComponent,
  ViewMilitaryAffiliationsComponent,
  CandidateLanguageComponent,

  //Portfolio
  PortfolioComponent,

  // ModalBrowserDetectionComponent,

  //Video
  VideoRecordingComponent,
  VideoFileUploadComponent,
  VideoUploadFileComponent,
  VideoUploadFileFormComponent,
  VideoRecordingSettingsComponent,
  VideoRecordingControlsComponent,

  // Pipes
  EmploymentTypePipe,
  ExperienceLevelPipe,
  DegreeLevelPipe,
  ApplicationStatePipe,
  ApplicationStateDescriptionPipe,
  DialogueQuestionTypePipe,
  ApplicationFieldPipe,
  AddressFormatPipe,

  // Directive
  FreeDraggingDirective,
  FreeDraggingHandleDirective,
  OnVisibleDirective,

  // New for shared profile
  CandidateWorkHistoryProfileShareComponent,
  CandidateEducationProfileShareComponent,
  CandidateReferencesProfileShareComponent,

  AboutComponent,
  JobScheduleComponent,
  FeedbackGathererComponent,

  UiTagCandidateComponent,
  ManageSkillsComponent,
  ManageCertificationsComponent,

  SwimlaneComponent,
  SwimlaneColumnComponent,
  SwimlaneColumnItemDirective,
  SwimlaneColumnHeaderDirective,
  SwimlaneControlComponent,

  InArrayPipe,
  ProfileSectionPipe,
]

const PortfolioHelperComponents = [
  PortfolioLinksComponent,
  PortfolioDocumentEmptyStateComponent,
  PortfolioDocumentLineItemComponent,
  PortfolioDocumentsComponent,
  PortfolioItemHeaderComponent,
  PortfolioUploadEmptyComponent,
  PortfolioClearComponent,
  PortfolioPhotosComponent,
  PortfolioPhotoLineItemComponent,
  PortfolioVideoLineItemComponent,
  PortfolioVideosComponent,
  UiPortfolioCardComponent,
  PortfolioLinksLineItemComponent,
  PortfolioLinksFormComponent,
  PortfolioItemTypeIsFilePipe,
  PortfolioDocumentsPreviewComponent,
  PortfolioVideosPreviewComponent,
  PortfolioLinksPreviewComponent,
  PortfolioPhotosPreviewComponent,
  ...PortfolioFilterPipes,
]

@NgModule({
  declarations: [...DeclareExportComponents, ...PortfolioHelperComponents, AboutComponent, RatingSliderComponent, UiMaintenancePageComponent],
  exports: [...DeclareExportComponents, MultimediaModule, NgxSummernoteModule],
  imports: [
    LetDirective,
    CommonModule,
    FormsModule,
    TranslateModule,
    RouterModule,
    MultimediaModule,
    NgxSummernoteModule,
    ReactiveFormsModule,
    CloudflareStreamModule,
    UiComponentLibraryModule,
    WebUtilityModule,
    ConfigModule.forRoot({ configModel: CnectConfig, configProvider: ConfigProvider }),
    DragDropModule,
    CdkScrollableModule,
    FilesModule,
  ],
})
export class SharedLibModule {
  public static forRoot(env: IEnvironment | IPlatformAdminEnvironment, errorHandling: ErrorHandlingConfig): ModuleWithProviders<SharedLibModule> {
    return {
      ngModule: SharedLibModule,
      providers: [
        { provide: ENVIRONMENT, useValue: env },
        { provide: ERROR_HANDLING, useValue: errorHandling },
      ],
    }
  }
}

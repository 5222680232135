<div class="ui-video-stand-alone e11-block e11-w-full e11-relative">
  <!-- Video -->
  <div class="e11-relative">
    <div class="e11-relative e11-h-[180px]">
      <!-- Loader -->
      <div
        class="e11-flex e11-items-center e11-justify-center e11-h-[180px] e11-absolute e11-w-full e11-z-2 e11-bg-skin-black"
        *ngIf="loadingSingleVideo"
        [@animationFadeOutSlow]
      >
        <e11-loader-ellipsis [fontColor]="'white'"></e11-loader-ellipsis>
      </div>
      <!-- Video -->
      <div class="e11-h-[180px] e11-absolute e11-w-full" *ngIf="_candidateFirstImpression && !loadingSingleVideo" [@animationFadeInSlow]>
        <single-first-impression [firstImpression]="_candidateFirstImpression"></single-first-impression>
      </div>
    </div>

    <div
      *ngIf="showFooter"
      class="e11-flex e11-w-full e11-px-2 e11-h-8 e11-border e11-border-skin-app-borders e11-border-t-transparent e11-relative e11--top-[1px]"
    >
      <div class="e11-grow e11-flex e11-items-center">
        <span *ngIf="showName">
          <span *ngIf="loadingSingleVideo">
            <p class="e11-skeleton-empty !e11-w-20 e11-mb-0"></p>
          </span>
          <span *ngIf="!loadingSingleVideo" class="e11-text-sm"> {{ 'Name:' | translate }} {{ _candidateFirstImpression?.name }} </span>
        </span>
        <span *ngIf="showTime">
          <span
            class="e11-flex e11-items-center e11-bg-skin-app-bg-dark e11-text-skin-white e11-text-xs e11-px-2 e11-py-1 e11-rounded e11-ml-4 e11-h-6"
          >
            {{ _candidateFirstImpression?.videoLength || '--:--' }}
          </span>
        </span>
      </div>
      <div *ngIf="showRemove" class="e11-flex e11-items-center">
        <p *ngIf="loadingSingleVideo" class="e11-skeleton-empty !e11-w-24 e11-mb-0"></p>
        <e11-tooltip
          *ngIf="!loadingSingleVideo"
          [color]="'dark'"
          [title]="'First Impression Video'"
          tooltipText="{{
            'Remove this First Impression video from this profile. The video will not be removed from your video library.' | translate
          }}"
        >
          <span
            class="e11-cursor-pointer e11-flex e11-items-center e11-justify-center e11-p-1 hover:e11-text-skin-dark"
            title="{{ 'Remove this First Impression video from this profile. The video will not be removed from your video library.' | translate }}"
          >
            <div
              class="e11-flex e11-items-start e11-justify-end e11-text-skin-secondary e11-cursor-pointer hover:e11-brightness-70 e11-px-2 e11-py-1 hover:e11-bg-skin-grey/10 e11-text-sm"
              (click)="removeVideo()"
            >
              {{ 'Remove video' | translate }}
            </div>
          </span>
        </e11-tooltip>
      </div>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core'
import { CollectionRepository, FirestoreQueryBuilder } from '@engineering11/web-api-firebase'
import { COLLECTIONS, ICandidateVideo } from 'shared-lib'

@Injectable({ providedIn: 'root' })
export class CandidateVideoRepository extends CollectionRepository<ICandidateVideo> {
  protected COLLECTION = COLLECTIONS.CANDIDATE_VIDEO

  getAllForUser(userId: string) {
    const query = new FirestoreQueryBuilder().where('userId', '==', userId).build()
    return this.queryValueChanges(query)
  }
}

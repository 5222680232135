import { Component, OnInit, OnDestroy } from '@angular/core'
import { selectors } from '@candidate/app/store/selectors'
import { IAppNotification, UserAppNotificationService } from '@engineering11/notifications-web'
import { IUser } from '@engineering11/user-shared'
import { select, Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { TokenStorage } from 'shared-lib'

import { SubscriptionManagementService } from '@candidate/app/services/subscription.management.service'
import { ViewportService } from '@candidate/app/services/viewport.service'

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit, OnDestroy {
  navCollased: boolean = false
  navControls: boolean = true

  // notifications$: Observable<IAppNotification[]> = this.store.pipe(select(selectors.getNotificationEntities))
  paginationSettings = {
    enabled: true,
    pageLinks: 5,
    rowCount: 25,
    serverSide: false,
    rowsPerPageOptions: [25, 50, 100],
  }
  subs = new SubscriptionManagementService()
  user: IUser
  isMobile: boolean = false

  constructor(
    private store: Store,
    private appNotificationService: UserAppNotificationService,
    tokenStorage: TokenStorage,
    private viewportService: ViewportService
  ) {
    this.user = tokenStorage.getItem('user') as IUser
  }

  ngOnInit(): void {
    this.subs.next = this.viewportService.viewportSizeChanged$.subscribe(data => {
      this.isMobile = data == 'sm' || data == 'xs'
    })
  }

  navToggle() {
    this.navCollased = !this.navCollased
  }

  ngOnDestroy(): void {
    this.subs.stop()
  }

  async markAllAsRead() {
    await this.appNotificationService.markAllAsRead(this.user.id)
  }

  async clearAll() {
    await this.appNotificationService.deleteAllNotifications(this.user.id)
  }
}

import { Injectable } from '@angular/core'
import { EMPLOYER_USER_ROLES } from '@cnect/user-shared'
import { BusinessSizeRange, GICSIndustryGroup, IndustrySector } from '@engineering11/customer-web'
import { IMultiSelectOption } from '@engineering11/ui-lib/e11-multi-select'
import { IRadioOptions } from '@engineering11/ui-lib/e11-radio-input'
import { ISelectOption } from '@engineering11/ui-lib/e11-select'
import { FormSelectModel } from '@engineering11/web-utilities'
import { TranslateService } from '@ngx-translate/core'
import { DisabilityStatus, Ethnicity, Gender, MilitaryStatus, VeteranStatus } from '../model/candidate-compliance.model'
import {
  APPLICATION_STATE,
  CandidateReferrals,
  EmployeeLocation,
  PayRateTypeEnum,
  SkillLevel,
  applicationStateMappings,
  degreeLevelMappings,
  employmentTypeMappings,
  experienceLevelMapping,
} from '../model/enum'

@Injectable({
  providedIn: 'root',
})
export class FormSelectService {
  pleaseSelect = ''
  selectLevel = ''
  pleaseSelectState: string = ' ' // to be translated if needed

  constructor(private translate: TranslateService) {
    translate.get(this.pleaseSelectState).subscribe((res: string) => {
      this.pleaseSelectState = res
    })
    translate.get(this.pleaseSelectState).subscribe((res: string) => {
      this.pleaseSelect = res
    })
    translate.get(this.pleaseSelectState).subscribe((res: string) => {
      this.selectLevel = res
    })
  }

  /** @deprecated use formSelectOptionsMonths  */
  getSelectMonth() {
    return [
      new FormSelectModel('01', 'Jan'),
      new FormSelectModel('02', 'Feb'),
      new FormSelectModel('03', 'Mar'),
      new FormSelectModel('04', 'Apr'),
      new FormSelectModel('05', 'May'),
      new FormSelectModel('06', 'Jun'),
      new FormSelectModel('07', 'Jul'),
      new FormSelectModel('08', 'Aug'),
      new FormSelectModel('09', 'Sep'),
      new FormSelectModel('10', 'Oct'),
      new FormSelectModel('11', 'Nov'),
      new FormSelectModel('12', 'Dec'),
    ]
  }

  /** @deprecated use formSelectOptionsStates  */
  getAddressStates() {
    return [
      new FormSelectModel('', this.pleaseSelectState),
      new FormSelectModel('AL', 'AL'),
      new FormSelectModel('AK', 'AK'),
      new FormSelectModel('AZ', 'AZ'),
      new FormSelectModel('AR', 'AR'),
      new FormSelectModel('CA', 'CA'),
      new FormSelectModel('CO', 'CO'),
      new FormSelectModel('CT', 'CT'),
      new FormSelectModel('DE', 'DE'),
      new FormSelectModel('DC', 'DC'),
      new FormSelectModel('FL', 'FL'),
      new FormSelectModel('GA', 'GA'),
      new FormSelectModel('HI', 'HI'),
      new FormSelectModel('ID', 'ID'),
      new FormSelectModel('IL', 'IL'),
      new FormSelectModel('IN', 'IN'),
      new FormSelectModel('IA', 'IA'),
      new FormSelectModel('KS', 'KS'),
      new FormSelectModel('KY', 'KY'),
      new FormSelectModel('LA', 'LA'),
      new FormSelectModel('ME', 'ME'),
      new FormSelectModel('MD', 'MD'),
      new FormSelectModel('MA', 'MA'),
      new FormSelectModel('MI', 'MI'),
      new FormSelectModel('MN', 'MN'),
      new FormSelectModel('MS', 'MS'),
      new FormSelectModel('MO', 'MO'),
      new FormSelectModel('MT', 'MT'),
      new FormSelectModel('NE', 'NE'),
      new FormSelectModel('NV', 'NV'),
      new FormSelectModel('NH', 'NH'),
      new FormSelectModel('NJ', 'NJ'),
      new FormSelectModel('NM', 'NM'),
      new FormSelectModel('NY', 'NY'),
      new FormSelectModel('NC', 'NC'),
      new FormSelectModel('ND', 'ND'),
      new FormSelectModel('OH', 'OH'),
      new FormSelectModel('OK', 'OK'),
      new FormSelectModel('OR', 'OR'),
      new FormSelectModel('PA', 'PA'),
      new FormSelectModel('RI', 'RI'),
      new FormSelectModel('SC', 'SC'),
      new FormSelectModel('SD', 'SD'),
      new FormSelectModel('TN', 'TN'),
      new FormSelectModel('TX', 'TX'),
      new FormSelectModel('UT', 'UT'),
      new FormSelectModel('VT', 'VT'),
      new FormSelectModel('VA', 'VA'),
      new FormSelectModel('WA', 'WA'),
      new FormSelectModel('WV', 'WV'),
      new FormSelectModel('WI', 'WI'),
      new FormSelectModel('WY', 'WY'),
    ]
  }

  //Languages
  //@TODO Need story to refactor this service in utilities
  getSelectLanguages() {
    return [
      new FormSelectModel('ab', 'Abkhazian'),
      new FormSelectModel('aa', 'Afar'),
      new FormSelectModel('af', 'Afrikaans'),
      new FormSelectModel('ak', 'Akan'),
      new FormSelectModel('sq', 'Albanian'),
      new FormSelectModel('am', 'Amharic'),
      new FormSelectModel('ar', 'Arabic'),
      new FormSelectModel('an', 'Aragonese'),
      new FormSelectModel('hy', 'Armenian'),
      new FormSelectModel('as', 'Assamese'),
      new FormSelectModel('av', 'Avaric'),
      new FormSelectModel('ae', 'Avestan'),
      new FormSelectModel('ay', 'Aymara'),
      new FormSelectModel('az', 'Azerbaijani'),
      new FormSelectModel('bm', 'Bambara'),
      new FormSelectModel('ba', 'Bashkir'),
      new FormSelectModel('eu', 'Basque'),
      new FormSelectModel('be', 'Belarusian'),
      new FormSelectModel('bn', 'Bengali'),
      new FormSelectModel('bi', 'Bislama'),
      new FormSelectModel('bs', 'Bosnian'),
      new FormSelectModel('br', 'Breton'),
      new FormSelectModel('bg', 'Bulgarian'),
      new FormSelectModel('my', 'Burmese'),
      new FormSelectModel('ca', 'Catalan, Valencian'),
      new FormSelectModel('km', 'Central Khmer'),
      new FormSelectModel('ch', 'Chamorro'),
      new FormSelectModel('ce', 'Chechen'),
      new FormSelectModel('ny', 'Chichewa, Chewa, Nyanja'),
      new FormSelectModel('zh', 'Chinese'),
      new FormSelectModel('cu', 'Church Slavonic, Old Slavonic, Old Church Slavonic'),
      new FormSelectModel('cv', 'Chuvash'),
      new FormSelectModel('kw', 'Cornish'),
      new FormSelectModel('co', 'Corsican'),
      new FormSelectModel('cr', 'Cree'),
      new FormSelectModel('hr', 'Croatian'),
      new FormSelectModel('cs', 'Czech'),
      new FormSelectModel('da', 'Danish'),
      new FormSelectModel('dv', 'Divehi, Dhivehi, Maldivian'),
      new FormSelectModel('nl', 'Dutch, Flemish'),
      new FormSelectModel('dz', 'Dzongkha'),
      new FormSelectModel('en', 'English'),
      new FormSelectModel('eo', 'Esperanto'),
      new FormSelectModel('et', 'Estonian'),
      new FormSelectModel('ee', 'Ewe'),
      new FormSelectModel('fo', 'Faroese'),
      new FormSelectModel('fj', 'Fijian'),
      new FormSelectModel('fi', 'Finnish'),
      new FormSelectModel('fr', 'French'),
      new FormSelectModel('ff', 'Fulah'),
      new FormSelectModel('gd', 'Gaelic, Scottish Gaelic'),
      new FormSelectModel('gl', 'Galician'),
      new FormSelectModel('lg', 'Ganda'),
      new FormSelectModel('ka', 'Georgian'),
      new FormSelectModel('de', 'German'),
      new FormSelectModel('el', 'Greek, Modern (1453–)'),
      new FormSelectModel('gn', 'Guarani'),
      new FormSelectModel('gu', 'Gujarati'),
      new FormSelectModel('ht', 'Haitian, Haitian Creole'),
      new FormSelectModel('ha', 'Hausa'),
      new FormSelectModel('he', 'Hebrew'),
      new FormSelectModel('hz', 'Herero'),
      new FormSelectModel('hi', 'Hindi'),
      new FormSelectModel('ho', 'Hiri Motu'),
      new FormSelectModel('hu', 'Hungarian'),
      new FormSelectModel('is', 'Icelandic'),
      new FormSelectModel('io', 'Ido'),
      new FormSelectModel('ig', 'Igbo'),
      new FormSelectModel('id', 'Indonesian'),
      new FormSelectModel('ia', 'Interlingua (International Auxiliary Language Association)'),
      new FormSelectModel('ie', 'Interlingue, Occidental'),
      new FormSelectModel('iu', 'Inuktitut'),
      new FormSelectModel('ik', 'Inupiaq'),
      new FormSelectModel('ga', 'Irish'),
      new FormSelectModel('it', 'Italian'),
      new FormSelectModel('ja', 'Japanese'),
      new FormSelectModel('jv', 'Javanese'),
      new FormSelectModel('kl', 'Kalaallisut, Greenlandic'),
      new FormSelectModel('kn', 'Kannada'),
      new FormSelectModel('kr', 'Kanuri'),
      new FormSelectModel('ks', 'Kashmiri'),
      new FormSelectModel('kk', 'Kazakh'),
      new FormSelectModel('ki', 'Kikuyu, Gikuyu'),
      new FormSelectModel('rw', 'Kinyarwanda'),
      new FormSelectModel('ky', 'Kirghiz, Kyrgyz'),
      new FormSelectModel('kv', 'Komi'),
      new FormSelectModel('kg', 'Kongo'),
      new FormSelectModel('ko', 'Korean'),
      new FormSelectModel('kj', 'Kuanyama, Kwanyama'),
      new FormSelectModel('ku', 'Kurdish'),
      new FormSelectModel('lo', 'Lao'),
      new FormSelectModel('la', 'Latin'),
      new FormSelectModel('lv', 'Latvian'),
      new FormSelectModel('li', 'Limburgan, Limburger, Limburgish'),
      new FormSelectModel('ln', 'Lingala'),
      new FormSelectModel('lt', 'Lithuanian'),
      new FormSelectModel('lu', 'Luba-Katanga'),
      new FormSelectModel('lb', 'Luxembourgish, Letzeburgesch'),
      new FormSelectModel('mk', 'Macedonian'),
      new FormSelectModel('mg', 'Malagasy'),
      new FormSelectModel('ms', 'Malay'),
      new FormSelectModel('ml', 'Malayalam'),
      new FormSelectModel('mt', 'Maltese'),
      new FormSelectModel('gv', 'Manx'),
      new FormSelectModel('mi', 'Maori'),
      new FormSelectModel('mr', 'Marathi'),
      new FormSelectModel('mh', 'Marshallese'),
      new FormSelectModel('mn', 'Mongolian'),
      new FormSelectModel('na', 'Nauru'),
      new FormSelectModel('nv', 'Navajo, Navaho'),
      new FormSelectModel('ng', 'Ndonga'),
      new FormSelectModel('ne', 'Nepali'),
      new FormSelectModel('nd', 'North Ndebele'),
      new FormSelectModel('se', 'Northern Sami'),
      new FormSelectModel('no', 'Norwegian'),
      new FormSelectModel('nb', 'Norwegian Bokmål'),
      new FormSelectModel('nn', 'Norwegian Nynorsk'),
      new FormSelectModel('oc', 'Occitan'),
      new FormSelectModel('oj', 'Ojibwa'),
      new FormSelectModel('or', 'Oriya'),
      new FormSelectModel('om', 'Oromo'),
      new FormSelectModel('os', 'Ossetian, Ossetic'),
      new FormSelectModel('pi', 'Pali'),
      new FormSelectModel('ps', 'Pashto, Pushto'),
      new FormSelectModel('fa', 'Persian'),
      new FormSelectModel('pl', 'Polish'),
      new FormSelectModel('pt', 'Portuguese'),
      new FormSelectModel('pa', 'Punjabi, Panjabi'),
      new FormSelectModel('qu', 'Quechua'),
      new FormSelectModel('ro', 'Romanian, Moldavian, Moldovan'),
      new FormSelectModel('rm', 'Romansh'),
      new FormSelectModel('rn', 'Rundi'),
      new FormSelectModel('ru', 'Russian'),
      new FormSelectModel('sm', 'Samoan'),
      new FormSelectModel('sg', 'Sango'),
      new FormSelectModel('sa', 'Sanskrit'),
      new FormSelectModel('sc', 'Sardinian'),
      new FormSelectModel('sr', 'Serbian'),
      new FormSelectModel('sn', 'Shona'),
      new FormSelectModel('ii', 'Sichuan Yi, Nuosu'),
      new FormSelectModel('sd', 'Sindhi'),
      new FormSelectModel('si', 'Sinhala, Sinhalese'),
      new FormSelectModel('sk', 'Slovak'),
      new FormSelectModel('sl', 'Slovenian'),
      new FormSelectModel('so', 'Somali'),
      new FormSelectModel('nr', 'South Ndebele'),
      new FormSelectModel('st', 'Southern Sotho'),
      new FormSelectModel('es', 'Spanish, Castilian'),
      new FormSelectModel('su', 'Sundanese'),
      new FormSelectModel('sw', 'Swahili'),
      new FormSelectModel('ss', 'Swati'),
      new FormSelectModel('sv', 'Swedish'),
      new FormSelectModel('tl', 'Tagalog'),
      new FormSelectModel('ty', 'Tahitian'),
      new FormSelectModel('tg', 'Tajik'),
      new FormSelectModel('ta', 'Tamil'),
      new FormSelectModel('tt', 'Tatar'),
      new FormSelectModel('te', 'Telugu'),
      new FormSelectModel('th', 'Thai'),
      new FormSelectModel('bo', 'Tibetan'),
      new FormSelectModel('ti', 'Tigrinya'),
      new FormSelectModel('to', 'Tonga (Tonga Islands)'),
      new FormSelectModel('ts', 'Tsonga'),
      new FormSelectModel('tn', 'Tswana'),
      new FormSelectModel('tr', 'Turkish'),
      new FormSelectModel('tk', 'Turkmen'),
      new FormSelectModel('tw', 'Twi'),
      new FormSelectModel('ug', 'Uighur, Uyghur'),
      new FormSelectModel('uk', 'Ukrainian'),
      new FormSelectModel('ur', 'Urdu'),
      new FormSelectModel('uz', 'Uzbek'),
      new FormSelectModel('ve', 'Venda'),
      new FormSelectModel('vi', 'Vietnamese'),
      new FormSelectModel('vo', 'Volapük'),
      new FormSelectModel('wa', 'Walloon'),
      new FormSelectModel('cy', 'Welsh'),
      new FormSelectModel('fy', 'Western Frisian'),
      new FormSelectModel('wo', 'Wolof'),
      new FormSelectModel('xh', 'Xhosa'),
      new FormSelectModel('yi', 'Yiddish'),
      new FormSelectModel('yo', 'Yoruba'),
      new FormSelectModel('za', 'Zhuang, Chuang'),
      new FormSelectModel('zu', 'Zulu'),
    ]
  }

  getSelectProficiencies(): ISelectOption<number>[] {
    return [
      { name: 'No Proficiency', value: 0 },
      { name: 'Elementary / Beginner', value: 1 },
      { name: 'Limited / Casual Conversational', value: 2 },
      { name: 'Professional / Advanced', value: 3 },
      { name: 'Full Professional / Translator', value: 4 },
      { name: 'Native / Bilingual Proficiency', value: 5 },
    ]
  }

  getMarketSectors() {
    return [new FormSelectModel('', this.pleaseSelectState)].concat(enumToFormSelectModelArray(IndustrySector))
  }

  getIndustryGroups() {
    return [new FormSelectModel('', this.pleaseSelectState)].concat(GICSIndustryGroup.map(value => new FormSelectModel(value, value)))
  }

  getBusinessSizesList() {
    return [new FormSelectModel('', this.pleaseSelectState)].concat(BusinessSizeRange.map(value => new FormSelectModel(value, value)))
  }

  getBusinessTypes() {
    return [new FormSelectModel('', this.pleaseSelectState), new FormSelectModel('Public', 'Public'), new FormSelectModel('Private', 'Private')]
  }

  getEmploymentTypes() {
    const models: FormSelectModel[] = []
    employmentTypeMappings.forEach((value, key) => {
      this.translate.get(value).subscribe(translatedValue => {
        models.push(new FormSelectModel(key, translatedValue))
      })
    })
    return models
  }

  getEmployeeLocationList() {
    return [
      new FormSelectModel(EmployeeLocation.Unspecified, 'Unspecified'),
      new FormSelectModel(EmployeeLocation.Remote, 'Remote'),
      new FormSelectModel(EmployeeLocation.InOffice, 'In Office'),
      new FormSelectModel(EmployeeLocation.Both, 'Hybrid'),
    ]
  }

  getExperienceLevelList() {
    const models: FormSelectModel[] = []
    experienceLevelMapping.forEach((value, key) => {
      this.translate.get(value).subscribe(translatedValue => {
        models.push(new FormSelectModel(key, translatedValue))
      })
    })
    return models
  }

  getEducationList() {
    const models: FormSelectModel[] = []
    degreeLevelMappings.forEach((value, key) => {
      this.translate.get(value).subscribe(translatedValue => {
        models.push(new FormSelectModel(key, translatedValue))
      })
    })
    return models
  }

  getSkillLevelList() {
    return [
      new FormSelectModel(SkillLevel.Beginner, 'Beginner'),
      new FormSelectModel(SkillLevel.Intermediate, 'Intermediate'),
      new FormSelectModel(SkillLevel.Expert, 'Expert'),
    ]
  }

  getPayrateList() {
    return [
      new FormSelectModel('', this.pleaseSelect),
      new FormSelectModel(PayRateTypeEnum.Hourly, 'Hourly'),
      new FormSelectModel(PayRateTypeEnum.Salary, 'Salary'),
    ]
  }

  getCandidateSwimLaneList() {
    const models: FormSelectModel[] = []
    applicationStateMappings.forEach((value, key) => {
      if (key === APPLICATION_STATE.APPLIED) {
        return
      }
      this.translate.get(value).subscribe(translatedValue => {
        models.push(new FormSelectModel(key, translatedValue))
      })
    })
    return models
  }

  getCandidateReferralList() {
    return [
      new FormSelectModel(CandidateReferrals.Cnect, 'cnect'),
      new FormSelectModel(CandidateReferrals.Google, 'Google'),
      new FormSelectModel(CandidateReferrals.Referral, 'Referral'),
      new FormSelectModel(CandidateReferrals.SocialMedia, 'Social Media'),
      new FormSelectModel(CandidateReferrals.Other, 'Other'),
    ]
  }

  static getAllRolesMultiList(): IMultiSelectOption[] {
    return [
      {
        label: EMPLOYER_USER_ROLES.ADMINISTRATOR,
        value: EMPLOYER_USER_ROLES.ADMINISTRATOR,
        description: 'The Administrator role has access to all features including user management and billing.',
      },
      {
        label: EMPLOYER_USER_ROLES.CONTRIBUTOR,
        value: EMPLOYER_USER_ROLES.CONTRIBUTOR,
        description: 'The contributor role has access to jobs where they are on the hiring team.',
      },
      {
        label: EMPLOYER_USER_ROLES.INTERNAL_RECRUITER,
        value: EMPLOYER_USER_ROLES.INTERNAL_RECRUITER,
        description: 'The internal recruiter role has access to all features related to jobs and candidates.',
      },
    ]
  }

  getGenderSelection(): ISelectOption[] {
    const genderSelect: Record<Gender, string> = {
      [Gender.Male]: 'Male',
      [Gender.Female]: 'Female',
      [Gender.NonBinary]: 'Gender Non-Conforming',
      [Gender.PreferNotToSay]: 'I prefer not to answer',
    }

    return enumToISelectOptionArray(genderSelect)
  }

  getRaceEthnicitySelection(): ISelectOption[] {
    const displayValues: Record<Ethnicity, string> = {
      [Ethnicity.NativeAmerican]: 'American Indian or Alaskan Native (Not Hispanic or Latino)',
      [Ethnicity.Asian]: 'Asian (Not Hispanic or Latino)',
      [Ethnicity.Black]: 'Black or African American (Not Hispanic or Latino)',
      [Ethnicity.Hispanic]: 'Hispanic or Latino',
      [Ethnicity.PacificIslander]: 'Native Hawaiian or Other Pacific Islander (Not Hispanic or Latino)',
      [Ethnicity.TwoOrMore]: 'Two or More Races (Not Hispanic or Latino)',
      [Ethnicity.White]: 'White (Not Hispanic or Latino)',
      [Ethnicity.PreferNotToSay]: 'I prefer not to answer',
    }

    return enumToISelectOptionArray(displayValues)
  }

  getMilitaryStatusSelection() {
    const displayValues: Record<MilitaryStatus, string> = {
      [MilitaryStatus.None]: 'No',
      [MilitaryStatus.Yes]: 'Yes',
      [MilitaryStatus.PreferNotToSay]: 'I prefer not to answer',
    }

    return enumToIRadioOptionArray<MilitaryStatus>(displayValues)
  }

  getVeteranStatusSelection() {
    const veteranStatusOptions: Record<VeteranStatus, string> = {
      [VeteranStatus.No]: 'No',
      [VeteranStatus.Yes]: 'Yes',
      [VeteranStatus.PreferNotToSay]: 'I prefer not to answer',
    }

    return enumToIRadioOptionArray<VeteranStatus>(veteranStatusOptions)
  }

  getDisabilityStatusSelection() {
    const disabilityStatusOptions: Record<DisabilityStatus, string> = {
      [DisabilityStatus.No]: 'No',
      [DisabilityStatus.Yes]: 'Yes',
      [DisabilityStatus.PreferNotToSay]: 'I prefer not to answer',
    }

    return enumToIRadioOptionArray<DisabilityStatus>(disabilityStatusOptions)
  }
}

function enumToFormSelectModelArray(enumerator: any) {
  return Object.keys(enumerator).map(key => new FormSelectModel(key, enumerator[key]))
}

function enumToISelectOptionArray(displayValues: Record<string, string>): ISelectOption[] {
  return Object.keys(displayValues).map(key => ({ value: key, name: displayValues[key] }))
}

function enumToIRadioOptionArray<T>(displayValues: Record<string, string>): IRadioOptions<T>[] {
  return Object.keys(displayValues).map(key => ({ value: key as T, name: displayValues[key] }))
}

import { Injectable } from '@angular/core'
import { AngularFirestore } from '@angular/fire/compat/firestore'
import { CollectionRepository, FirestoreQueryBuilder } from '@engineering11/web-api-firebase'
import { ICandidateJobVM } from 'shared-lib'
import { COLLECTIONS } from 'shared-lib'
@Injectable({ providedIn: 'root' })
export class CandidateJobVMRepository extends CollectionRepository<ICandidateJobVM> {
  constructor(afs: AngularFirestore) {
    super(afs)
  }
  protected COLLECTION = COLLECTIONS.CANDIDATE_JOB_VM

  get(key: string) {
    return super.get(key)
  }

  getAll() {
    return super.getAll()
  }

  getAllForUserValueChanges(userId: string) {
    const query = new FirestoreQueryBuilder().where('userId', '==', userId).build()
    return super._queryValueChanges(this.COLLECTION, query)
  }

  getAllForUser(userId: string) {
    const query = new FirestoreQueryBuilder().where('userId', '==', userId).build()
    return super._query(this.COLLECTION, query)
  }
}

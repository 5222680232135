<header>
  <nav class="e11-bg-skin-primary e11-fixed e11-w-full e11-z-[12]">
    <div class="e11-container-full e11-mx-auto e11-flex e11-px-6">
      <div [routerLink]="CNECT_ROUTES.PROFILES" class="e11-hidden e11-header-logo lg:e11-flex e11-items-center e11-cursor-pointer">
        <img [src]="logoUrl" class="e11-h-16" />
      </div>

      <div class="e11-grow e11--mr-2 e11-flex e11-items-center lg:e11-hidden">
        <!-- Mobile menu button -->
        <button
          (click)="toggleMobileSideDrawer()"
          *ngIf="currentUser$ | async as currentUser"
          aria-controls="mobile-menu"
          aria-expanded="false"
          class="e11-inline-flex e11-items-center e11-justify-center e11-p-2 e11-rounded-md e11-text-gray-100 hover:e11-text-gray-100 focus:e11-outline-none focus:e11-ring-2 focus:e11-ring-offset-2"
          type="button"
        >
          <span class="e11-sr-only">Open main menu</span>
          <span *ngIf="(mobileSideDrawerOpened$ | async) !== true" class="material-icons-outlined">menu</span>
          <span *ngIf="mobileSideDrawerOpened$ | async" class="material-icons-outlined">close</span>
        </button>

        <div class="e11-flex e11-items-center e11-ml-2">
          <e11-avatar
            (click)="onClickAccount()"
            *ngIf="currentUser$ | async as currentUser"
            [imageUrl]="currentUser.photoURL"
            [initials]="currentUser.firstName.charAt(0) + currentUser.lastName.charAt(0)"
            [size]="'md'"
          ></e11-avatar>

          <span
            (click)="onClickAccount()"
            *ngIf="currentUser$ | async as currentUser"
            class="e11-flex e11-items-center e11-px-3 e11-py-2 e11-rounded-md e11-text-md e11-text-white/80 e11-cursor-pointer"
          >
            {{ currentUser.firstName }} {{ currentUser.lastName }}
            <span class="material-icons">expand_more</span>
          </span>
        </div>
      </div>

      <div class="e11-flex e11-items-center e11-grow e11-hidden lg:e11-flex e11-text-white e11-gap-2 e11-pl-6">
        <ng-container *ngFor="let item of navigationItems">
          <a
            *ngIf="(item | canDisplayNavItem : features!) === true"
            [routerLink]="item.route"
            [routerLinkActive]="'e11-bg-skin-primary-accent !e11-text-skin-white e11-font-primary-medium'"
            class="e11-flex e11-items-center e11-gap-1 e11-text-white/70 hover:e11-text-white e11-px-3 e11-h-8 e11-rounded-md"
          >
            <span class="material-icons md-18">{{ item.icon }}</span> {{ item.name | translate }}
          </a>
        </ng-container>
      </div>

      <div class="e11-hidden lg:e11-ml-6 lg:e11-flex lg:e11-items-center lg:e11-grow lg:e11-justify-end e11-relative">
        <referral-link-copy *ngIf="features?.referrals && currentUser"></referral-link-copy>

        <!-- Info Tip Initial Implementation -->
        <span
          (click)="infoTipStatus = !infoTipStatus"
          *ngIf="features?.informationalContent && currentUser"
          class="material-icons-outlined e11-text-white e11-cursor-pointer"
        >
          tips_and_updates
        </span>

        <!-- Ad Banner -->
        <info-content-header
          (closeInfoContent)="infoTipStatus = !infoTipStatus"
          *ngIf="features?.informationalContent && infoTipStatus"
        ></info-content-header>

        <ui-notification *ngIf="features?.notifications && currentUser"></ui-notification>

        <!-- Profile dropdown -->
        <div class="e11-ml-6">
          <e11-avatar
            (click)="onClickAccount()"
            *ngIf="currentUser$ | async as currentUser"
            [imageUrl]="currentUser.photoURL"
            [initials]="currentUser.firstName.charAt(0) + currentUser.lastName.charAt(0)"
            [shape]="'circular'"
            [size]="'md'"
          ></e11-avatar>

          <div
            *ngIf="dropDownOpen"
            [ngClass]="{
              'e11-transition e11-ease-out e11-duration-200 e11-transform e11-opacity-0 e11-scale-95 e11-invisible': !dropDownOpen,
              'e11-transition e11-ease-in duration-75 e11-e11-transform e11-opacity-100 e11-scale-100': dropDownOpen
            }"
            aria-labelledby="user-menu-button"
            aria-orientation="vertical"
            class="e11-origin-top-right e11-absolute e11-right-0 e11-mt-2 e11-w-48 e11-rounded-md e11-shadow-lg e11-py-1 e11-bg-white e11-border e11-border-skin-app-borders focus:e11-outline-none focus:e11-ring-opacity-100"
            role="menu"
            tabindex="-1"
          >
            <a
              [routerLink]="[CNECT_ROUTES.DEFAULT]"
              class="e11-block e11-px-4 e11-py-2 e11-text-sm e11-text-gray-700 hover:e11-bg-gray-100 e11-border-b e11-border-gray-200"
              id="user-menu-item-0"
              role="menuitem"
              tabindex="-1"
            >
              {{ 'Home' | translate }}
            </a>
            <a
              [routerLink]="[CNECT_ROUTES.ACCOUNT]"
              class="e11-block e11-px-4 e11-py-2 e11-text-sm e11-text-gray-700 hover:e11-bg-gray-100 e11-border-b e11-border-gray-200"
              id="user-menu-item-0"
              role="menuitem"
              tabindex="-1"
            >
              {{ 'My Account' | translate }}
            </a>

            <a
              (click)="signOut()"
              class="e11-block e11-px-4 e11-py-2 e11-text-sm e11-text-gray-700 hover:e11-bg-gray-100"
              href="#"
              id="user-menu-item-2"
              role="menuitem"
              tabindex="-1"
            >
              {{ 'Sign out' | translate }}
            </a>
          </div>
        </div>
        <span
          (click)="onClickAccount()"
          *ngIf="currentUser$ | async as currentUser"
          class="e11-flex e11-items-center sm:e11-visible e11-px-3 e11-py-2 e11-rounded-md e11-text-sm e11-text-white/80 hover:e11-text-white e11-cursor-pointer"
        >
          {{ currentUser.firstName }} {{ currentUser.lastName }}
          <span class="material-icons">expand_more</span>
        </span>
      </div>

      <div class="e11-grow e11-justify-end e11--mr-2 e11-flex e11-items-center lg:e11-hidden">
        <referral-link-copy *ngIf="features?.referrals && currentUser"></referral-link-copy>
        <ui-notification *ngIf="features?.notifications && currentUser"></ui-notification>
      </div>
    </div>
    <!-- </div> -->

    <!-- Mobile menu, show/hide based on menu state. -->
    <div [ngClass]="{ 'e11-hidden': !dropDownOpen }" class="lg:e11-hidden" id="e11-mobile-menu">
      <!-- Mobile bg -->
      <div class="e11-py-4 e11-border-t e11-border-skin-app-borders nav-mobile-bg">
        <div class="e11-flex e11-items-center e11-px-4">
          <div class="e11-flex-shrink-0">
            <e11-avatar
              (click)="onClickAccount()"
              *ngIf="currentUser$ | async as currentUser"
              [imageUrl]="currentUser.photoURL"
              [size]="'sm'"
            ></e11-avatar>
          </div>
          <div class="e11-ml-3">
            <div *ngIf="currentUser$ | async as currentUser" class="e11-text-base e11-font-medium e11-text-gray-200">
              {{ currentUser.firstName }} {{ currentUser.lastName }}
            </div>
            <div *ngIf="currentUser$ | async as currentUser" class="e11-text-sm e11-font-medium e11-text-skin-extra-light">
              {{ currentUser.email }}
            </div>
          </div>
        </div>

        <div class="e11-mt-3 e11-space-y-1">
          <a
            [routerLink]="[CNECT_ROUTES.ACCOUNT]"
            class="e11-block e11-px-4 e11-py-2 e11-text-base e11-font-medium e11-text-gray-200 hover:e11-text-gray-100"
          >
            {{ 'My Account' | translate }}
          </a>
          <a (click)="signOut()" class="e11-block e11-px-4 e11-py-2 e11-text-base e11-font-medium e11-text-gray-200 hover:e11-text-gray-100" href="#">
            {{ 'Sign out' | translate }}
          </a>
        </div>
      </div>
    </div>
  </nav>
</header>

// @Todo: Do we use this? Remove?
import { Component, OnDestroy, OnInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { ConfigService } from '@candidate/app/services/config.service'
import { AuthRestService } from '@engineering11/auth-web'
import { AnalyticsService } from '@engineering11/web-api-firebase'
import { ERROR_HANDLING } from '../../../../../app.constants'

@Component({
  templateUrl: './verification-code.component.html',
  styleUrls: ['./verification-code.component.scss'],
})
export class AuthRecoveryVerificationCodeComponent implements OnInit, OnDestroy {
  themeCurrent: 'default'

  // Recover form:
  formRecover = new UntypedFormGroup({})

  // Form controlers
  usernameFC: UntypedFormControl = new UntypedFormControl({})

  // error handling
  usernameMinLength = { value: ERROR_HANDLING.USER_NAME_MIN_LENGTH }
  usernameMaxLength = { value: ERROR_HANDLING.USER_NAME_MAX_LENGTH }

  formRecoverSubmitted = false

  constructor(
    private configService: ConfigService,
    private authService: AuthRestService,
    private formBuilder: UntypedFormBuilder,
    private analyticsService: AnalyticsService
  ) {
    this.themeCurrent = configService.config.theme
  }

  ngOnInit(): void {
    this.createForm()
  }

  createForm(): void {
    this.formRecover = this.formBuilder.group({
      username: new UntypedFormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(ERROR_HANDLING.USER_NAME_MAX_LENGTH)]),
    })
    this.usernameFC = this.formRecover.get('username') as UntypedFormControl
  }

  async onSubmit() {
    this.formRecoverSubmitted = true
    if (this.formRecover.valid) {
      this.formRecover.reset()
      this.formRecoverSubmitted = false
      await this.authService.sendPasswordResetEmailWithCustomLink(this.formRecover.get('username')?.value)
    }
  }

  ngOnDestroy(): void {
    // not needed here
  }
}

import { Component, OnInit, ViewChild } from '@angular/core'
import { PRIMARY_OUTLET, Router, UrlTree } from '@angular/router'
import { IEmployerDetailsVM } from '@candidate/app/models/employer/employer.model'
import { resumeToApplication } from '@candidate/app/services/candidate-application/application.util'
import { CandidateApplicationStageService } from '@candidate/app/services/candidate-application/candidate-application-stage.service'
import { PublicEmployerService } from '@candidate/app/services/employer/employer-public.service'
import { RESUME_FILE_TYPES, RESUME_SIZE_MAX_BYTES, ResumeParsingService } from '@candidate/app/services/resume/resume-parsing.service'
import { SubscriptionManagementService } from '@candidate/app/services/subscription.management.service'
import { getPublicJobDetail } from '@candidate/app/store/job/job.selectors'
import * as fromReduce from '@candidate/app/store/reducers'
import { getPandoUTMFromQuery } from '@candidate/app/util/pando-utm-from-params'
import { IRegistrationResult } from '@engineering11/registration-web'
import { E11Animations } from '@engineering11/ui-lib/e11-animations'
import { E11ModalComponent } from '@engineering11/ui-lib/e11-modal'
import { isNotNil } from '@engineering11/utility'
import { ViewportService, ViewPortSize } from '@engineering11/web-utilities'
import { select, Store } from '@ngrx/store'
import { filter, take } from 'rxjs/operators'
import { AvailableThemes, EmployerDefaultTheme, getCurrentUser, ICandidateSource, IEmployerTheme, TokenStorage } from 'shared-lib'
import { GetPublicJob } from './../../../../store/job/job.actions'

@Component({
  selector: 'app-register-apply',
  templateUrl: './register-apply.component.html',
  styleUrls: ['./register-apply.component.scss'],
  animations: [E11Animations.fadeIn, E11Animations.scaleSlideUp],
})
export class RegisterApplyComponent implements OnInit {
  @ViewChild('modalTerms') modalTerms!: E11ModalComponent
  subs: SubscriptionManagementService = new SubscriptionManagementService()
  currentUser$ = this.store.pipe(select(getCurrentUser))

  jobDetail$ = this.store.select(getPublicJobDetail)
  jobPostId?: string
  maxFileSize = RESUME_SIZE_MAX_BYTES
  resumeFileTypes = RESUME_FILE_TYPES
  resumeFile?: File
  showRegistrationForm: boolean = false

  viewport = this.viewportService.getCurrentSize()
  smallerSizes: ViewPortSize[] = ['xs', 'sm']
  isMobile = this.smallerSizes.includes(this.viewport)
  activeSection: string = ''

  processing: boolean = false
  employerDetailsVM?: IEmployerDetailsVM
  employerTheme?: IEmployerTheme
  employerHeroImage?: string = ''

  candidateSource?: ICandidateSource
  uploadingResume: boolean = false

  constructor(
    private router: Router,
    private resumeParsingService: ResumeParsingService,
    private store: Store<fromReduce.job.State>,
    private tokenStorage: TokenStorage,
    private applicationStageService: CandidateApplicationStageService,
    private viewportService: ViewportService,
    private employerService: PublicEmployerService
  ) {
    const urlTree: UrlTree = this.router.parseUrl(this.router.url)
    const primary = urlTree.root.children[PRIMARY_OUTLET]
    let redirectPath = ''
    if (primary.segments.length > 1) {
      redirectPath = decodeURIComponent(primary.segments[primary.segments.length - 1].path)
      const [initialPathWithOUtQuery, queryString] = redirectPath.split('?')

      // parse utm strings for pando
      if (queryString && queryString.length) {
        const params = new URLSearchParams(queryString)
        this.candidateSource = getPandoUTMFromQuery(params)
      }

      const path = initialPathWithOUtQuery.split('/')
      const jobId = path.pop()
      this.jobPostId = jobId
      this.store.dispatch(new GetPublicJob(jobId as string))
    }
  }

  ngOnInit() {
    this.jobDetail$.pipe(filter(isNotNil), take(1)).subscribe(jobDetail => {
      this.employerService
        .get(jobDetail.customerKey)
        .pipe(filter(isNotNil), take(1))
        .subscribe(employerDetailsVM => {
          this.employerHeroImage = employerDetailsVM.heroSection?.headerImage
          this.employerDetailsVM = employerDetailsVM
          if (employerDetailsVM.theme) {
            this.employerTheme = AvailableThemes.find(t => t.name === employerDetailsVM.theme)
          } else {
            this.employerTheme = EmployerDefaultTheme
          }
        })
    })
  }

  async onSubmit(result: IRegistrationResult) {
    if (result) {
      this.tokenStorage.setAccessToken(result.authToken)
      this.tokenStorage.setItem('user', JSON.stringify(result.appUser))

      if (this.resumeFile && this.jobPostId) {
        this.uploadingResume = true
        const parsedResume = await this.resumeParsingService.parse(this.resumeFile, result.appUser.customerKey, result.appUser.id)
        // ! Need to look at using resume selection endpoint instead
        const applicationStage = resumeToApplication(parsedResume, this.jobPostId, this.candidateSource)
        await this.applicationStageService.upsert(applicationStage)
      }

      /**
       * The below checks if there is a deep link on the route, if so set
       * the redirect to that. The onboarding chain should always propagate
       * the deep link forward
       */
      const urlTree: UrlTree = this.router.parseUrl(this.router.url)
      const primary = urlTree.root.children[PRIMARY_OUTLET]
      let redirectPath = ''
      if (primary.segments.length > 1) {
        redirectPath = decodeURIComponent(primary.segments[primary.segments.length - 1].path)
        const [initialPathWithOUtQuery] = redirectPath.split('?')
        redirectPath = initialPathWithOUtQuery
      }
      this.router.navigate([redirectPath], { queryParamsHandling: 'merge', queryParams: this.candidateSource })
    }
  }

  termsClicked() {
    this.modalTerms.open()
  }

  navigateToLogin() {
    /**
     * The below checks if there is a deep link on the route, if so set
     * the redirect to that. The onboarding chain should always propagate
     * the deep link forward
     */
    const urlTree: UrlTree = this.router.parseUrl(this.router.url)
    const primary = urlTree.root.children[PRIMARY_OUTLET]
    let redirectPath = undefined
    if (primary.segments.length > 1) {
      redirectPath = primary.segments[primary.segments.length - 1].path
    }
    this.router.navigate(['/auth/login', redirectPath], {
      queryParams: urlTree.queryParams,
      queryParamsHandling: 'merge',
    })
  }

  clearSelectedFile() {
    this.resumeFile = undefined
    this.showRegistrationForm = false
  }

  switchShowRegistrationForm(value: boolean) {
    this.showRegistrationForm = value
  }

  back() {
    this.showRegistrationForm = false
  }

  register() {
    this.showRegistrationForm = true
  }
}

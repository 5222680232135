import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { LandingStateService } from './state.service'

@Component({
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
  customerKey?: string
  logoImageUrl?: string
  websiteUrl?: string | null
  employerName?: string
  constructor(private route: ActivatedRoute, private landingStateService: LandingStateService) {}

  ngOnInit(): void {
    this.landingStateService.employer$?.subscribe(employer => {
      if (employer.logoImageURL) {
        this.logoImageUrl = employer.logoImageURL
      }
      this.websiteUrl = employer.websiteURL
      this.employerName = employer.name
    })
  }
}

import { environment } from '@candidate/environments/environment'
import { Injectable } from '@angular/core'
import { RestApiClient } from '@engineering11/web-api-rest'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { IPublicJobPostVM } from '@candidate/app/models/jobs/job-post-public.model'

@Injectable({
  providedIn: 'root',
})
export class PublicJobPostService {
  private restApiClient: RestApiClient
  constructor() {
    this.restApiClient = new RestApiClient({
      baseUrl: environment.services.jobs,
      token: '',
    })
  }

  get(jobPostId: string): Observable<IPublicJobPostVM> {
    return this.restApiClient.get<IPublicJobPostVM>(`/job-post/${jobPostId}/public`).pipe(map(item => item.data))
  }

  getJobPostSearchKey(customerKey: string): Observable<string> {
    return this.restApiClient.get<string>(`search-keys/job-post/${customerKey}`).pipe(map(item => item.data))
  }
}

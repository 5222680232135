import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { IAppNotification, INotificationHandler, INotificationView, UserAppNotificationService } from '@engineering11/notifications-web'
import { SOCIAL_NOTIFICATION_IDS } from '@engineering11/social-web'

export interface IConnectionAcceptedNotification extends IAppNotification {
  type: 'social-invite-accepted'
  addresseeFirstName: string
  addresseeLastName: string
  addresseeDisplayName?: string
}

export type ConnectionAcceptedNotificationVM = INotificationView

@Injectable({ providedIn: 'root' })
export class ConnectionAcceptedNotificationHandler
  implements INotificationHandler<IConnectionAcceptedNotification, ConnectionAcceptedNotificationVM>
{
  notificationType = SOCIAL_NOTIFICATION_IDS.social_new_connection
  constructor(private router: Router, private userAppNotificationService: UserAppNotificationService) {}

  onClick(notification: IConnectionAcceptedNotification): boolean | Promise<boolean> {
    if (!notification.viewed) {
      this.userAppNotificationService.markNotificationAsRead(notification.id)
    }
    this.router.navigate(['/network/cnections'])
    return true
  }
}

import { ICandidateProfile } from '../models/candidate-profile/candidate-profile.model'
import {
  ICandidateJobApplicationStash,
  ICandidateJobSubmissionPreview,
  ICandidateJobVM,
  ICandidateResponses,
  IEducation,
  IFirstImpression,
  IReference,
  IWorkHistory,
} from 'shared-lib'

export function getDefaultJobSubmissionFromProfile(
  jobDetail: ICandidateJobVM,
  candidateProfile: ICandidateProfile,
  candidateResponses?: ICandidateResponses
): ICandidateJobSubmissionPreview {
  return {
    jobPostId: jobDetail.jobId,
    email: candidateProfile.email,
    firstName: candidateProfile.firstName,
    lastName: candidateProfile.lastName,
    firstImpression: candidateProfile.firstImpressions?.find(impression => impression.default),
    phoneNumber: candidateProfile.phone, // TODO: Fix original phone input, libphonenumber validation
    address: candidateProfile.address,
    //photoURL: candidateProfile.photo ?? undefined,
    summary: candidateProfile.summary,
    education: candidateProfile.education,
    skills: candidateProfile.skills,
    certifications: candidateProfile.certifications,
    workHistory: candidateProfile.workHistory,
    militaryAffiliation: candidateProfile.militaryAffiliation,
    portfolio: candidateProfile.portfolio,
    references: candidateProfile.references.filter(reference => reference.default),
    website: candidateProfile.website,
    candidateResponses: candidateResponses,
  }
}

/**
 * Builds the intended candidate job submission by joining data from the profile and the stash
 *
 * @param jobDetail
 * @param candidateProfile
 * @param candidateApplicationStash
 * @returns
 */
export function buildCandidateJobSubmission(
  jobDetail: ICandidateJobVM,
  candidateProfile: ICandidateProfile,
  candidateApplicationStash: ICandidateJobApplicationStash | null
): ICandidateJobSubmissionPreview {
  // Use default profile if no stash is made.
  if (!candidateApplicationStash) {
    return getDefaultJobSubmissionFromProfile(jobDetail, candidateProfile)
  }

  const firstImpression = determineFirstImpression(candidateProfile.firstImpressions, candidateApplicationStash.selectedFirstImpressionId)
  const education = determineEducation(candidateProfile.education, candidateApplicationStash.selectedEducationIds)
  const workHistory = determineWorkHistory(candidateProfile.workHistory, candidateApplicationStash.selectedWorkHistoryIds)
  const references = determineReferences(candidateProfile.references, candidateApplicationStash.selectedReferenceIds)
  return {
    jobPostId: jobDetail.jobId,
    email: candidateProfile.email,
    firstName: candidateProfile.firstName,
    lastName: candidateProfile.lastName,
    firstImpression,
    phoneNumber: candidateProfile.phone,
    address: candidateProfile.address,
    //photoURL: candidateProfile.photo ?? undefined,
    summary: candidateProfile.summary,
    education,
    skills: candidateProfile.skills,
    certifications: candidateProfile.certifications,
    workHistory,
    militaryAffiliation: candidateProfile.militaryAffiliation,
    portfolio: candidateProfile.portfolio,
    references,
    website: candidateProfile.website,
    candidateResponses: candidateApplicationStash?.candidateResponses,
  }
}

function determineFirstImpression(profileFirstImpressions: IFirstImpression[], selectedImpressionId?: string | null): IFirstImpression | undefined {
  if (selectedImpressionId === undefined) {
    return profileFirstImpressions?.find(impression => impression.default)
  } else if (selectedImpressionId === null) {
    return undefined
  } else {
    return profileFirstImpressions?.find(impression => impression.id === selectedImpressionId)
  }
}

function determineEducation(profileEducation: IEducation[], selectedEducationIds?: string[]): IEducation[] {
  if (selectedEducationIds === undefined) {
    return profileEducation
  } else if (selectedEducationIds.length === 0) {
    return []
  } else {
    return profileEducation.filter(education => selectedEducationIds.includes(education.id))
  }
}

function determineWorkHistory(profileWorkHistory: IWorkHistory[], selectedWorkHistoryIds?: string[]): IWorkHistory[] {
  if (selectedWorkHistoryIds === undefined) {
    return profileWorkHistory
  } else if (selectedWorkHistoryIds.length === 0) {
    return []
  } else {
    return profileWorkHistory.filter(workHistory => selectedWorkHistoryIds.includes(workHistory.id))
  }
}

function determineReferences(profileEducation: IReference[], selectedReferenceIds?: string[]): IReference[] {
  if (selectedReferenceIds === undefined) {
    return profileEducation.filter(reference => reference.default)
  } else if (selectedReferenceIds.length === 0) {
    return []
  } else {
    return profileEducation.filter(reference => selectedReferenceIds.includes(reference.id))
  }
}

import { Inject, Injectable } from '@angular/core'
import { ApplicationHistoryService } from '@candidate/app/services/application-history.service'
import { ApplicationUpdateStageService } from '@candidate/app/services/candidate-application/application-update-stage.service'
import {
  addNewSkillsAndCerts,
  stagedUpdatesToPreviewPayload,
  stageToSubmission,
} from '@candidate/app/services/candidate-application/application.util'
import { CandidateApplicationStageService } from '@candidate/app/services/candidate-application/candidate-application-stage.service'
import { CandidateApplicationService } from '@candidate/app/services/candidate-application/candidate-application.service'
import { CandidateHomeNavigationService } from '@candidate/app/services/candidate-home-navigation.service'
import { JobApplicationService } from '@candidate/app/services/jobs/job-application.service'
import { VirtualDialogueService } from '@candidate/app/services/virtual-dialogue/virtual-dialogue.service'
import { combineWithInput } from '@engineering11/stream-utility'
import { E11LoaderGlobalService } from '@engineering11/ui-lib/e11-loader-dots'
import { INotificationMessage } from '@engineering11/ui-lib/e11-notifications'
import { isNotNil } from '@engineering11/utility'
import { E11ErrorHandlerService, ILogger, LOGGER_TOKEN, STANDARD_ERROR_MESSAGE } from '@engineering11/web-api-error'
import { NotificationService } from '@engineering11/web-ui-helpers'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { from, of } from 'rxjs'
import { catchError, filter, finalize, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators'
import { CandidateJobStatus, getCurrentUserId, IJobApplicationStage, IProcessedJobApplication } from 'shared-lib'
import { GetAllJobs, GetJob, GetJobSuccess, JobActionTypes } from '../job/job.actions'
import { getSelectedJobId } from '../job/job.selectors'
import { GetAllResumes } from '../resume/resume.actions'
import {
  AddApplicationSkillsAndCerts,
  ApplicationActionTypes,
  ApplicationChooseResume,
  CreateApplicationStage,
  CreateApplicationStageSuccess,
  ErrorAction,
  GetApplicationHistory,
  GetApplicationHistorySuccess,
  GetApplicationStage,
  GetApplicationStageSuccess,
  GetJobApplication,
  GetJobApplicationSuccess,
  GetSelectedApplicationVirtualDialogues,
  GetSelectedApplicationVirtualDialoguesSuccess,
  GetStagedApplicationUpdate,
  GetStagedApplicationUpdateSuccess,
  ProcessJobApplicationPreview,
  ProcessJobApplicationPreviewSuccess,
  StageApplicationUpdate,
  StageApplicationUpdateSuccess,
  SubmitApplicationUpdate,
  SubmitApplicationUpdateSuccess,
  SubmitJobApplication,
  SubmitJobApplicationSuccess,
  UndoWithdrawal,
  UndoWithdrawalSuccess,
  UPDATE_APPLICATION_ACTION_TYPES,
  UpdateApplicationAction,
  UpdateApplicationStage,
  UpdateApplicationStageSuccess,
  WithdrawApplication,
  WithdrawApplicationSuccess,
} from './application.actions'
import { getApplicationStageForSelectedJob, getSelectedJobApplication } from './application.selectors'

@Injectable()
export class ApplicationEffects {
  userId$ = this.store.pipe(getCurrentUserId).pipe(filter(isNotNil))

  constructor(
    private actions$: Actions,
    private store: Store,
    @Inject(LOGGER_TOKEN) private logger: ILogger,
    private applicationStageService: CandidateApplicationStageService,
    private jobApplicationService: JobApplicationService,
    private candidateApplicationService: CandidateApplicationService,
    private applicationHistoryService: ApplicationHistoryService,
    private applicationUpdateStageService: ApplicationUpdateStageService,
    private notificationsService: NotificationService,
    private errorHandler: E11ErrorHandlerService,
    private loaderService: E11LoaderGlobalService,
    private candidateNavigationService: CandidateHomeNavigationService,
    private virtualDialogueService: VirtualDialogueService
  ) {}

  onGetApplicationStage$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetApplicationStage>(ApplicationActionTypes.getApplicationStage),
      withLatestFrom(this.store.pipe(getCurrentUserId).pipe(filter(isNotNil))), // withLatestFrom only emits when the guiding stream emits
      switchMap(([{ jobPostId }, candidateId]) => {
        return this.applicationStageService.getByUserAndJobPost(candidateId, jobPostId).pipe(
          map(
            combineWithInput({
              jobPostId,
              candidateId,
            })
          )
        )
      }),
      map(([requestData, application]) => (application ? new GetApplicationStageSuccess(application) : new CreateApplicationStage(requestData)))
    )
  )

  onCreateApplicationStage$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateApplicationStage>(ApplicationActionTypes.createApplicationStage),
      withLatestFrom(this.store.pipe(getCurrentUserId).pipe(filter(isNotNil))), // withLatestFrom only emits when the guiding stream emits
      mergeMap(([action, candidateId]) => this.applicationStageService.create({ ...action.payload, candidateId })),
      map(application => new CreateApplicationStageSuccess(application))
    )
  )

  onGetApplicationHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetApplicationHistory>(ApplicationActionTypes.getApplicationHistory),
      mergeMap(({ jobPostId }) => this.applicationHistoryService.getApplicationHistory(jobPostId)),
      tap(applicationHistory => this.logger.log({ applicationHistory })),
      map(applicationHistoryArray => new GetApplicationHistorySuccess(applicationHistoryArray))
    )
  )

  onChooseResume$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ApplicationChooseResume>(ApplicationActionTypes.applicationChooseResume),
      withLatestFrom(this.store.select(getSelectedJobId).pipe(filter(isNotNil))), // withLatestFrom only emits when the guiding stream emits
      tap(([action, jobPostId]) => this.logger.log('choosing resume', { resume: action.payload }, { jobPostId })),
      mergeMap(([action, jobPostId]) =>
        from(this.applicationStageService.selectResume(action.payload, jobPostId)).pipe(
          map(stage => new UpdateApplicationStageSuccess(stage)),
          catchError(error => {
            this.errorHandler.handleError(error)
            this.notificationsService.popNotificationFromError(error, { message: STANDARD_ERROR_MESSAGE })
            return of(new ErrorAction(error?.response ? error?.response.data?.message : [error]))
          })
        )
      )
      // map(stage => new UpdateApplicationStageSuccess(stage))
    )
  )

  onUpdateApplicationStage$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateApplicationAction>(...UPDATE_APPLICATION_ACTION_TYPES),
      withLatestFrom(this.store.pipe(getCurrentUserId).pipe(filter(isNotNil))), // withLatestFrom only emits when the guiding stream emits
      mergeMap(async ([action, candidateId]) => {
        const applicationUpdatePayload = { ...action.payload, candidateId }
        const response = await this.applicationStageService.update(applicationUpdatePayload)
        return applicationUpdatePayload
      }),
      map(applicationUpdatePayload => new UpdateApplicationStageSuccess(applicationUpdatePayload))
    )
  )

  onAddNewSkillsAndCerts$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddApplicationSkillsAndCerts>(ApplicationActionTypes.addApplicationSkillsAndCerts),
      withLatestFrom(this.store.select(getApplicationStageForSelectedJob).pipe(filter(isNotNil))), // TODO: Error if null
      map(([action, selectedApplication]) => addNewSkillsAndCerts(selectedApplication, action.payload)),
      map((application: IJobApplicationStage) => new UpdateApplicationStage(application))
    )
  )

  dispatchApplicationPreview$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetApplicationStageSuccess | UpdateApplicationStageSuccess | CreateApplicationStageSuccess>(
        ApplicationActionTypes.getApplicationStageSuccess,
        ApplicationActionTypes.updateApplicationStageSuccess,
        ApplicationActionTypes.createApplicationStageSuccess
      ),
      withLatestFrom(this.store.select(getApplicationStageForSelectedJob).pipe(filter(isNotNil))),
      tap(([_, application]) => this.logger.log('ApplicationEffects - dispatchApplicationPreview', { application })),
      map(([_, application]) => new ProcessJobApplicationPreview(stageToSubmission(application)))
    )
  )

  onProcessApplicationPreview$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ProcessJobApplicationPreview>(ApplicationActionTypes.processJobApplicationPreview),
      tap(fetchingPreview => this.logger.log({ fetchingPreview })),
      switchMap(action =>
        this.jobApplicationService.previewApplication(action.payload).pipe(
          tap(fetchedPreview => this.logger.log({ fetchedPreview })),
          map((response: IProcessedJobApplication) => new ProcessJobApplicationPreviewSuccess(response)),
          catchError(error => {
            this.errorHandler.handleError(error)
            return of(new ErrorAction(error?.response ? error?.response.data?.message : [error]))
          })
        )
      )
    )
  )

  onSubmitJobApplication$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SubmitJobApplication>(ApplicationActionTypes.submitJobApplication),
      mergeMap(action => {
        this.loaderService.open({ label: 'Processing Application...', backdropStyle: 'light', hasBackdrop: true })
        this.logger.log('SubmitJobApplication - ', action)
        return this.jobApplicationService.submitApplication(action.payload).pipe(
          tap(() => this.loaderService.close()),
          map((response: IProcessedJobApplication) => new SubmitJobApplicationSuccess(response)),
          catchError(error => {
            this.loaderService.close()
            this.notificationsService.popNotification(submissionFailureNotification)
            this.errorHandler.handleError(error, { level: 'critical' })
            return of(new ErrorAction(error instanceof Error ? [error.message || error] : error))
          })
        )
      })
    )
  )

  onSubmitJobApplicationSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SubmitJobApplicationSuccess>(ApplicationActionTypes.submitJobApplicationSuccess),
      tap(_ => this.notificationsService.popNotification(applicationSuccessNotification)),
      withLatestFrom(this.store.pipe(getCurrentUserId).pipe(filter(isNotNil))), // withLatestFrom only emits when the guiding stream emits
      switchMap(([action, userId]) => of(new GetAllJobs(), new GetAllResumes(userId)))
    )
  )

  onGetJobSuccessGetData$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetJobSuccess>(JobActionTypes.getJobSuccess),
      switchMap(({ payload: jobPost }) => {
        if (jobPost.status === CandidateJobStatus.Viewed) {
          return of(new GetApplicationStage(jobPost.jobId))
        } else if (jobPost.status === CandidateJobStatus.Applied && jobPost.requestedUpdated.length) {
          return of(
            new GetJobApplication(jobPost.jobId),
            new GetApplicationHistory(jobPost.jobId),
            new GetSelectedApplicationVirtualDialogues(jobPost.jobId),
            new GetStagedApplicationUpdate(jobPost.jobId)
          )
        } else {
          return of(
            new GetJobApplication(jobPost.jobId),
            new GetApplicationHistory(jobPost.jobId),
            new GetSelectedApplicationVirtualDialogues(jobPost.jobId)
          )
        }
      })
    )
  )

  onGetJobApplication$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetJobApplication>(ApplicationActionTypes.getJobApplication),
      switchMap(action => this.candidateApplicationService.getCandidateApplication(action.jobPostId)),
      map(response => new GetJobApplicationSuccess(response))
    )
  )

  onGetStagedApplicationUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetStagedApplicationUpdate>(ApplicationActionTypes.getStagedApplicationUpdate),
      withLatestFrom(this.store.pipe(getCurrentUserId).pipe(filter(isNotNil))), // withLatestFrom only emits when the guiding stream emits
      switchMap(([{ jobPostId }, candidateId]) => {
        return this.applicationUpdateStageService.getByUserAndJobPost(candidateId, jobPostId).pipe(
          map(
            combineWithInput({
              jobPostId,
              candidateId,
            })
          )
        )
      }),
      map(([requestData, stagedUpdates]) =>
        stagedUpdates ? new GetStagedApplicationUpdateSuccess(stagedUpdates) : new StageApplicationUpdate(requestData)
      )
    )
  )

  onStageApplicationUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType<StageApplicationUpdate>(ApplicationActionTypes.stageApplicationUpdate),
      switchMap(action => this.applicationUpdateStageService.upsert(action.payload)),
      map(response => new StageApplicationUpdateSuccess(response))
    )
  )

  stagedApplicationUpdatePreview$ = createEffect(() =>
    this.actions$.pipe(
      ofType<StageApplicationUpdateSuccess | GetStagedApplicationUpdateSuccess>(
        ApplicationActionTypes.stageApplicationUpdateSuccess,
        ApplicationActionTypes.getStagedApplicationUpdateSuccess
      ),
      withLatestFrom(this.store.select(getSelectedJobApplication).pipe(filter(isNotNil))),
      map(([staged, application]) => new ProcessJobApplicationPreview(stagedUpdatesToPreviewPayload({ ...application, ...staged.payload })))
    )
  )

  onSubmitApplicationUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SubmitApplicationUpdate>(ApplicationActionTypes.submitApplicationUpdate),
      withLatestFrom(this.store.select(getSelectedJobApplication).pipe(filter(isNotNil))), // TODO: Error if null
      mergeMap(([action, selected]) =>
        this.candidateApplicationService.updateCandidateApplication(selected.id, action.payload).pipe(
          map(_ => new SubmitApplicationUpdateSuccess(action.payload)),
          catchError(error => {
            this.notificationsService.popNotification(submissionFailureNotification)
            this.errorHandler.handleError(error)
            return of(new ErrorAction(error instanceof Error ? [error.message || error] : error))
          })
        )
      )
    )
  )

  onSubmitApplicationUpdateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SubmitApplicationUpdateSuccess>(ApplicationActionTypes.submitApplicationUpdateSuccess),
      tap(_ => this.notificationsService.popNotification(applicationUpdateSuccessNotification)),
      withLatestFrom(this.store.select(getSelectedJobApplication).pipe(filter(isNotNil))),
      switchMap(([action, selected]) => of(new GetJobApplication(selected.jobPostId), new GetJob(selected.jobPostId)))
    )
  )

  onWithdrawApplication$ = createEffect(() =>
    this.actions$.pipe(
      ofType<WithdrawApplication>(ApplicationActionTypes.withdrawApplication),
      withLatestFrom(this.store.select(getSelectedJobApplication).pipe(filter(isNotNil))),
      mergeMap(([_, selected]) =>
        this.jobApplicationService.withdrawApplication(selected.jobPostId, selected.candidateId).pipe(
          map(_ => new WithdrawApplicationSuccess()),
          catchError(error => {
            this.notificationsService.popNotificationFromError(error, { title: 'Application withdrawal failed' })
            this.errorHandler.handleError(error)
            return of(new ErrorAction(error instanceof Error ? [error.message || error] : error))
          })
        )
      )
    )
  )

  onWithdrawApplicationSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<WithdrawApplicationSuccess>(ApplicationActionTypes.withdrawApplicationSuccess),
      tap(_ => this.notificationsService.popNotification(withdrawSuccessNotification)),
      tap(_ => this.candidateNavigationService.application()),
      switchMap(_ => of(new GetAllJobs()))
    )
  )

  onUndoWithdrawApplication$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UndoWithdrawal>(ApplicationActionTypes.undoWithdrawal),
      withLatestFrom(this.store.select(getSelectedJobApplication).pipe(filter(isNotNil))),
      mergeMap(([_, selectedApplication]) => {
        this.loaderService.open({ label: 'Resubmitting Application...', backdropStyle: 'light', hasBackdrop: true })
        return this.jobApplicationService.undoWithdrawal(selectedApplication.jobPostId, selectedApplication.candidateId).pipe(
          map(_ => new UndoWithdrawalSuccess(selectedApplication)),
          catchError(error => {
            this.notificationsService.popNotificationFromError(error, { title: 'Application resubmission failed' })
            this.errorHandler.handleError(error)
            return of(new ErrorAction(error instanceof Error ? [error.message || error] : error))
          }),
          finalize(() => this.loaderService.close())
        )
      })
    )
  )

  onUndoWithdrawalSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UndoWithdrawalSuccess>(ApplicationActionTypes.undoWithdrawalSuccess),
      tap(_ => this.notificationsService.popNotification(undoWithdrawalNotification)),
      tap(_ => this.candidateNavigationService.application()),
      switchMap(action => of(new GetJob(action.application.jobPostId)))
    )
  )

  onGetVirtualDialogues$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetSelectedApplicationVirtualDialogues>(ApplicationActionTypes.getSelectedApplicationVirtualDialogues),
      mergeMap(({ jobPostId }) => this.virtualDialogueService.getAllByUserAndJobValueChanges(jobPostId)),
      tap(applicationDialogues => this.logger.log({ applicationDialogues })),
      map(applicationDialoguesArray => new GetSelectedApplicationVirtualDialoguesSuccess(applicationDialoguesArray))
    )
  )

  onError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ErrorAction>(ApplicationActionTypes.error),
        map(action => this.logger.error(action.payload))
      ),
    { dispatch: false }
  )
}

const applicationUpdateSuccessNotification: INotificationMessage = {
  title: 'Updates Submitted',
  message: 'Your application has been updated successfully!',
  type: 'success',
  autoClose: true,
}

const applicationSuccessNotification: INotificationMessage = {
  title: 'Congratulations!',
  message: 'You have successfully submitted your application for this job. Good luck!',
  type: 'success',
  autoClose: true,
}
const submissionFailureNotification: INotificationMessage = {
  title: 'Submission failed',
  message: 'Our engineers have been notified and are looking into the issue.',
  type: 'error',
  autoClose: false,
}

const withdrawSuccessNotification: INotificationMessage = {
  title: 'Application Withdrawn!',
  message: 'You have successfully withdrawn your application for this job.',
  type: 'success',
  autoClose: true,
}

const undoWithdrawalNotification: INotificationMessage = {
  title: 'Application Resubmitted!',
  message: 'You have successfully re-submitted your application for this job.',
  type: 'success',
  autoClose: true,
}
const withdrawFailureNotification: INotificationMessage = {
  title: 'Application withdrawal failed',
  message: 'Our engineers have been notified and are looking into the issue.',
  type: 'error',
  autoClose: false,
}

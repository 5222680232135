<div
  #searchResults
  [ngClass]="{ 'e11-h-0': !isLoadingResults && !jobs, 'e11-h-[80vh] e11-overflow-y-auto': jobs?.length }"
  class="search-results e11-w-full"
>
  <div *ngFor="let job of jobs" [ngClass]="{ 'selected-job': selectedJob?.id === job.id }" class="e11-mb-6">
    <search-line-item (jobSelected)="jobSelected.emit($event)" [isSelected]="selectedJob?.id === job.id" [job]="job"></search-line-item>
  </div>
  <div *ngIf="!hasMoreResults && jobs?.length" class="e11-text-center e11-w-full e11-text-skin-light e11-text-sm">
    {{ 'Showing ' + jobs?.length + ' of ' + jobs?.length + ' result(s)' | translate }}
  </div>
  <div #endOfListEl [ngClass]="{ 'e11-p-5': hasMoreResults && jobs?.length }"></div>

  <ng-container *ngIf="isLoadingResults">
    <div *ngFor="let i of 2 | range" class="e11-mb-6">
      <search-line-item [job]="undefined"></search-line-item>
    </div>
  </ng-container>

  <div *ngIf="isLoadingMoreJobs" class="e11-p-4 e11-text-center e11-mb-12">
    <e11-loader-dots [color]="'e11-bg-skin-primary'" [size]="'sm'" label="{{ 'Loading more jobs...' | translate }}"></e11-loader-dots>
  </div>
</div>

<div
  *ngIf="(!isLoadingResults && !jobs) || (!isLoadingResults && jobs && jobs.length === 0)"
  class="e11-border e11-p-5 e11-border-skin-grey e11-border-dashed e11-bg-skin-white e11-rounded e11-text-skin-light e11-text-center"
>
  <h3 class="e11-mb-2">{{ 'No Results' | translate }}</h3>
</div>

import { Component } from '@angular/core'
import { CNECT_ROUTES } from '@candidate/app/services/candidate-home-navigation.service'
import { AppConfigService } from '@engineering11/tenant-bootstrap-web'
import { Observable, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { CnectFeatures, ConfigStore } from 'shared-lib'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class AppFooterComponent {
  statusPrivacy: boolean = false
  statusTerms: boolean = false
  CNECT_ROUTES = CNECT_ROUTES
  features?: CnectFeatures
  features$: Observable<CnectFeatures | undefined> = this.configStore.features$
  destroy$: Subject<boolean> = new Subject<boolean>()

  logoUrl = this.appConfig.config.brand.logoImageLightUrl
  constructor(private configStore: ConfigStore, private appConfig: AppConfigService) {
    this.features$.pipe(takeUntil(this.destroy$)).subscribe(features => (this.features = features))
  }

  modalOpenPrivacy() {
    this.statusPrivacy = true
  }

  modalOpenTerms() {
    this.statusTerms = true
  }

  navigateTo(link: string) {
    window.open(link, '_blank')
  }
}

import { Injectable } from '@angular/core'
import { IJobSearchRequest, IJobSearchResult } from '@candidate/app/models/jobs/job-search.model'
import { RestApiClient } from '@engineering11/web-api-rest'
import { getCurrentToken } from 'shared-lib'
import { environment } from '@candidate/environments/environment'
import { Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { Store } from '@ngrx/store'
import { isNotNil } from '@engineering11/utility'

@Injectable({
  providedIn: 'root',
})
export class JobSearchService {
  private client: RestApiClient

  constructor(private store: Store) {
    this.client = new RestApiClient({
      baseUrl: environment.services.jobs,
      token: store.select(getCurrentToken).pipe(filter(isNotNil)),
    })
  }

  search(request: IJobSearchRequest): Observable<IJobSearchResult> {
    return this.client.post<IJobSearchResult>('/job-posts/search', request).pipe(map(item => item.data))
  }
}

import { ICandidateDetails, ICandidateResume, INewResumeThreshold } from '@candidate/app/models/candidate-resume.model'
import { ISharedResumeToken } from '@candidate/app/models/resume-share.model'
import { ResumeTokenCreateRequest } from '@candidate/app/services/resume/resume-token.service'
import { AtLeast } from '@engineering11/types'
import { Timestamp } from '@engineering11/web-api-firebase'
import { Action } from '@ngrx/store'

export enum ResumeActionTypes {
  onInitResume = '[Resume Effects] on initial app load',
  getNewResumeThreshold = '[Resume Effects] get new resume threshold',
  getNewResumeThresholdSuccess = '[Resume Effects] get new resume threshold success',
  getAllResumes = '[Resume Effects] get all',
  getAllResumesSuccess = '[Resume Effects] get all success',
  createNewResume = '[Resume List Page] create new resume',
  createNewResumeSuccess = '[Resume Effects] create new resume success',
  selectResume = '[Resume Home Page] select resume',
  navigateToResume = '[Resume Effects] navigate to resume',
  resetResumeSelection = '[Resume Home Page] reset resume selection',
  updateResume = '[Manage Resume Page] update resume',
  updateResumeSuccess = '[Resume Service] update resume success',
  updateResumeCandidateDetails = '[Manage Resume Page] update candidate details',
  updateResumeSummary = '[Summary Generator Page] update summary',
  updateResumePhoto = '[Manage Resume Page] update photo',
  updateResumeMilitaryAffiliations = '[Manage Resume Page] update military affiliations',
  addResumeSkillsAndCerts = '[Manage Resume Page] add skills and certs',
  updateResumeSkills = '[Manage Resume Page] update skills',
  updateResumeCertifications = '[Manage Resume Page] update certifications',
  updateResumeWorkHistory = '[Manage Resume Page] update work history',
  updateResumeEducation = '[Manage Resume Page] update education',
  updateResumeReferences = '[Manage Resume Page] update references',
  updateResumePortfolio = '[Manage Resume Page] update portfolio',
  updateFirstImpression = '[Manage Resume Page] update first impression',
  updateResumeLanguages = '[Manage Resume Page] update languages',
  deleteResume = '[Manage Resume Page] delete resume',
  deleteResumeSuccess = '[Manage Resume Page] delete resume success',
  shareResume = '[Manage Resume Page] share resume',
  shareResumeSuccess = '[Resume Effects] share resume success',
  getAllResumeTokens = '[Resume Effects] get all resume tokens',
  getAllResumeTokensSuccess = '[Resume Effects] get all resume tokens success',
  deactivateResumeToken = '[Resume Manage] deactivate resume token',
  deactivateResumeTokenSuccess = '[Resume Effects] deactivate resume token success',
  reactivateResumeToken = '[Resume Manage] reactivate resume token',
  reactivateResumeTokenSuccess = '[Resume Effects] reactivate resume token success',
  deleteResumeToken = '[Resume Manage] delete resume token',
  deleteResumeTokenSuccess = '[Resume Effects] delete resume token success',
  error = '[Resume] error',
}

export const UPDATE_RESUME_ACTION_TYPES = [
  ResumeActionTypes.updateResume,
  ResumeActionTypes.updateResumeCandidateDetails,
  ResumeActionTypes.updateResumeSummary,
  ResumeActionTypes.updateResumePhoto,
  ResumeActionTypes.updateResumeMilitaryAffiliations,
  ResumeActionTypes.updateResumeSkills,
  ResumeActionTypes.updateResumeCertifications,
  ResumeActionTypes.updateResumeWorkHistory,
  ResumeActionTypes.updateResumeEducation,
  ResumeActionTypes.updateResumeReferences,
  ResumeActionTypes.updateResumePortfolio,
  ResumeActionTypes.updateFirstImpression,
  ResumeActionTypes.updateResumeLanguages,
]

export class OnInitResume implements Action {
  readonly type = ResumeActionTypes.onInitResume
  constructor() {}
}
export class GetNewResumeThreshold implements Action {
  readonly type = ResumeActionTypes.getNewResumeThreshold
  constructor(public userId: string) {}
}

export class GetNewResumeThresholdSuccess implements Action {
  readonly type = ResumeActionTypes.getNewResumeThresholdSuccess
  constructor(public payload: INewResumeThreshold) {}
}
export class GetAllResumes implements Action {
  readonly type = ResumeActionTypes.getAllResumes
  constructor(public userId: string) {}
}

export class GetAllResumesSuccess implements Action {
  readonly type = ResumeActionTypes.getAllResumesSuccess
  constructor(public payload: Timestamp<ICandidateResume>[]) {}
}

export class CreateNewResume implements Action {
  readonly type = ResumeActionTypes.createNewResume
  constructor(public payload?: ICandidateResume) {}
}

export class CreateNewResumeSuccess implements Action {
  readonly type = ResumeActionTypes.createNewResumeSuccess
  constructor(public payload: Timestamp<ICandidateResume>) {}
}

export class NavigateToResume implements Action {
  readonly type = ResumeActionTypes.navigateToResume
  constructor(public payload: string) {}
}

export class SelectResume implements Action {
  readonly type = ResumeActionTypes.selectResume
  constructor(public payload: string) {}
}

export class ResetResumeSelection implements Action {
  readonly type = ResumeActionTypes.resetResumeSelection
  constructor() {}
}

export class UpdateResume implements Action {
  readonly type = ResumeActionTypes.updateResume
  constructor(public payload: AtLeast<ICandidateResume, 'id'>) {}
}

export class UpdateResumeSuccess implements Action {
  readonly type = ResumeActionTypes.updateResumeSuccess
  constructor(public payload: AtLeast<ICandidateResume, 'id'>) {}
}

export class UpdateResumeCandidateDetails implements Action {
  readonly type = ResumeActionTypes.updateResumeCandidateDetails
  constructor(public payload: ICandidateDetails & Pick<ICandidateResume, 'id'>) {}
}

export class UpdateResumeSummary implements Action {
  readonly type = ResumeActionTypes.updateResumeSummary
  constructor(public payload: Pick<ICandidateResume, 'id' | 'summary'>) {}
}

export class UpdateResumePhoto implements Action {
  readonly type = ResumeActionTypes.updateResumePhoto
  constructor(public payload: Pick<ICandidateResume, 'id' | 'photo'>) {}
}

export class UpdateResumeMilitaryAffiliations implements Action {
  readonly type = ResumeActionTypes.updateResumeMilitaryAffiliations
  constructor(public payload: Pick<ICandidateResume, 'id' | 'militaryAffiliation'>) {}
}

export class UpdateResumeSkills implements Action {
  readonly type = ResumeActionTypes.updateResumeSkills
  constructor(public payload: Pick<ICandidateResume, 'id' | 'skills'>) {}
}

export class UpdateResumeCertifications implements Action {
  readonly type = ResumeActionTypes.updateResumeCertifications
  constructor(public payload: Pick<ICandidateResume, 'id' | 'certifications'>) {}
}

export class AddResumeSkillsAndCerts implements Action {
  readonly type = ResumeActionTypes.addResumeSkillsAndCerts
  constructor(public payload: Pick<ICandidateResume, 'id' | 'certifications' | 'skills'>) {}
}

export class UpdateResumeWorkHistory implements Action {
  readonly type = ResumeActionTypes.updateResumeWorkHistory
  constructor(public payload: Pick<ICandidateResume, 'id' | 'workHistory'>) {}
}

export class UpdateResumeEducation implements Action {
  readonly type = ResumeActionTypes.updateResumeEducation
  constructor(public payload: Pick<ICandidateResume, 'id' | 'education'>) {}
}

export class UpdateResumeReferences implements Action {
  readonly type = ResumeActionTypes.updateResumeReferences
  constructor(public payload: Pick<ICandidateResume, 'id' | 'references'>) {}
}

export class UpdateResumePortfolio implements Action {
  readonly type = ResumeActionTypes.updateResumePortfolio
  constructor(public payload: Pick<ICandidateResume, 'id' | 'portfolio'>) {}
}
export class UpdateResumeFirstImpression implements Action {
  readonly type = ResumeActionTypes.updateFirstImpression
  constructor(public payload: Pick<ICandidateResume, 'id' | 'firstImpression'>) {}
}

export class UpdateResumeLanguages implements Action {
  readonly type = ResumeActionTypes.updateResumeLanguages
  constructor(public payload: Pick<ICandidateResume, 'id' | 'languages'>) {}
}

export class DeleteResume implements Action {
  readonly type = ResumeActionTypes.deleteResume
  constructor(public id: string) {}
}

export class DeleteResumeSuccess implements Action {
  readonly type = ResumeActionTypes.deleteResumeSuccess
  constructor(public id: string) {}
}

export class ShareResume implements Action {
  readonly type = ResumeActionTypes.shareResume
  constructor(public payload: ResumeTokenCreateRequest) {}
}

export class ShareResumeSuccess implements Action {
  readonly type = ResumeActionTypes.shareResumeSuccess
  constructor(public payload: ISharedResumeToken) {}
}

export class GetAllResumeTokens implements Action {
  readonly type = ResumeActionTypes.getAllResumeTokens
  constructor() {}
}

export class GetAllResumeTokensSuccess implements Action {
  readonly type = ResumeActionTypes.getAllResumeTokensSuccess
  constructor(public payload: ISharedResumeToken[]) {}
}

export class ErrorAction implements Action {
  readonly type = ResumeActionTypes.error
  constructor(public payload: any[]) {}
}

export class DeactivateResumeToken implements Action {
  readonly type = ResumeActionTypes.deactivateResumeToken
  constructor(public payload: ISharedResumeToken) {}
}

export class DeactivateResumeTokenSuccess implements Action {
  readonly type = ResumeActionTypes.deactivateResumeTokenSuccess
  constructor(public payload: ISharedResumeToken) {}
}

export class ReactivateResumeToken implements Action {
  readonly type = ResumeActionTypes.reactivateResumeToken
  constructor(public payload: ISharedResumeToken) {}
}

export class ReactivateResumeTokenSuccess implements Action {
  readonly type = ResumeActionTypes.reactivateResumeTokenSuccess
  constructor(public payload: ISharedResumeToken) {}
}

export class DeleteResumeToken implements Action {
  readonly type = ResumeActionTypes.deleteResumeToken
  constructor(public payload: ISharedResumeToken) {}
}

export class DeleteResumeTokenSuccess implements Action {
  readonly type = ResumeActionTypes.deleteResumeTokenSuccess
  constructor(public payload: ISharedResumeToken) {}
}

export type ResumeActions =
  | OnInitResume
  | GetNewResumeThreshold
  | GetNewResumeThresholdSuccess
  | GetAllResumes
  | GetAllResumesSuccess
  | CreateNewResume
  | CreateNewResumeSuccess
  | SelectResume
  | NavigateToResume
  | ResetResumeSelection
  | UpdateResumeAction
  | UpdateResumeSuccess
  | AddResumeSkillsAndCerts
  | DeleteResume
  | DeleteResumeSuccess
  | ShareResume
  | ShareResumeSuccess
  | GetAllResumeTokens
  | GetAllResumeTokensSuccess
  | DeactivateResumeToken
  | DeactivateResumeTokenSuccess
  | ReactivateResumeToken
  | ReactivateResumeTokenSuccess
  | DeleteResumeToken
  | DeleteResumeTokenSuccess
  | ErrorAction

export type UpdateResumeAction =
  | UpdateResume
  | UpdateResumeCandidateDetails
  | UpdateResumeSummary
  | UpdateResumePhoto
  | UpdateResumeSkills
  | UpdateResumeCertifications
  | UpdateResumeWorkHistory
  | UpdateResumeEducation
  | UpdateResumeReferences
  | UpdateResumePortfolio
  | UpdateResumeFirstImpression
  | UpdateResumeLanguages

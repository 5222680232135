import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { ICommunityMemberMentionedNotification } from '@candidate/app/models/app-notification.model'
import { COMMUNITY_NOTIFICATION_IDS } from '@engineering11/community-web'
import { INotificationHandler, INotificationView, UserAppNotificationService } from '@engineering11/notifications-web'

@Injectable({ providedIn: 'root' })
export class CommunityMemberMentionedNotificationHandler implements INotificationHandler<ICommunityMemberMentionedNotification, INotificationView> {
  notificationType: string = COMMUNITY_NOTIFICATION_IDS.community_user_mentioned
  constructor(private router: Router, private userAppNotificationService: UserAppNotificationService) {}

  onClick(notification: ICommunityMemberMentionedNotification): boolean | Promise<boolean> {
    if (!notification.viewed) {
      this.userAppNotificationService.markNotificationAsRead(notification.id)
    }
    return this.router.navigate(['c/m/messaging/community/', notification.communityId])
  }
}

import { Injectable } from '@angular/core'
import { CollectionRepository, FirestoreQueryBuilder, OrderByDirection } from '@engineering11/web-api-firebase'
import { COLLECTIONS, IVirtualDialogue } from 'shared-lib'
import { sort } from '@engineering11/utility'

@Injectable({ providedIn: 'root' })
export class VirtualDialogueCandidateRepository extends CollectionRepository<IVirtualDialogue> {
  protected COLLECTION = COLLECTIONS.VIRTUAL_DIALOGUE_CANDIDATE

  getAllByCandidate(candidateId: string) {
    const query = new FirestoreQueryBuilder().where('candidateId', '==', candidateId).build()

    return this.query(query)
  }

  getAllByCandidateValueChanges(candidateId: string) {
    const query = new FirestoreQueryBuilder().where('candidateId', '==', candidateId).build()

    return this.queryValueChanges(query)
  }

  getAllByCandidateAndJobValueChanges(candidateId: string, jobPostId: string) {
    const query = new FirestoreQueryBuilder<IVirtualDialogue>().where('candidateId', '==', candidateId).where('jobPostId', '==', jobPostId).build()
    return this.queryValueChanges(query)
  }
}

<ng-container *ngrxLet="selectedJob$ as selectedJob">
  <div class="e11-w-full">
    <div class="e11-flex e11-items-center">
      <h1 class="e11-text-skin-primary-accent e11-grow">
        {{ 'Application' | translate }}
      </h1>
      <application-delete (deleteConfirmed)="navigateToApplications()" [selectedJob]="selectedJob"></application-delete>
    </div>
    <p
      (click)="navigateToApplications()"
      class="e11-flex e11-items-center e11-cursor-pointer e11-text-skin-primary-accent hover:e11-text-skin-primary e11-mb-0"
    >
      <span class="material-icons-outlined md-18 e11-mr-2"> west </span>
      {{ 'Back to applications list' | translate }}
    </p>
  </div>

  <e11-divider [margin]="1"></e11-divider>

  <div class="e11-mb-4 e11-w-full e11-grid e11-grid-cols-1 lg:e11-grid-cols-3 e11-justify-between e11-items-center e11-flex-wrap">
    <div class="e11-col-span-1">
      <job-info-header (jobTitleClick)="toSidePage(applicationPageEnum.JobDescription)" [jobDetail]="selectedJob"></job-info-header>
      <div
        (click)="backFromSidePage()"
        *ngIf="currentPage === applicationPageEnum.JobDescription"
        class="e11-cursor-pointer e11-text-sm e11-text-skin-primary-accent"
      >
        {{ 'Back to Application' | translate }}
      </div>
    </div>

    <div *ngIf="currentPage === applicationPageEnum.EditApplication" class="e11-col-span-2">
      <ng-container *ngrxLet="resumes$ as resumes">
        <ng-container *ngrxLet="resumesLoaded$ as resumesLoaded">
          <resume-select (selectedResume)="chooseResumeForApplication($event)" [loading]="!resumesLoaded" [resumeList]="resumes"></resume-select>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <ng-container *ngIf="currentPage === applicationPageEnum.JobDescription">
    <div>
      <job-detail-display *ngIf="selectedJob; else Loading" [jobDetail]="selectedJob!"></job-detail-display>
    </div>
  </ng-container>

  <ng-container *ngIf="currentPage === applicationPageEnum.EditApplication">
    <application-manage (submitClicked)="confirmSubmission()" [jobPost]="selectedJob" [jobPostId]="selectedJob?.jobId"></application-manage>
  </ng-container>

  <!-- Footer Buttons -->
  <div class="e11-w-full e11-flex e11-mt-8 e11-mb-16 e11-justify-end">
    <e11-button
      (click)="confirmSubmission()"
      *ngIf="currentPage === applicationPageEnum.EditApplication"
      [color]="'primary-accent'"
      [iconPosition]="'right'"
      [icon]="'send'"
      [size]="(isMobile$ | async) ? 'sm' : 'md'"
      value="{{ 'Submit Application' | translate }}"
    ></e11-button>
    <e11-button
      (click)="backFromSidePage()"
      *ngIf="currentPage === applicationPageEnum.JobDescription"
      [color]="'primary-accent'"
      [iconPosition]="'left'"
      [icon]="'arrow_back'"
      [size]="(isMobile$ | async) ? 'sm' : 'md'"
      value="{{ 'Back to Application' | translate }}"
    ></e11-button>
  </div>

  <ng-template #Loading>
    <div class="e11-flex e11-justify-center">
      <e11-loader-dots></e11-loader-dots>
    </div>
  </ng-template>

  <ng-container *ngIf="selectedJob && selectedApplication">
    <application-confirmation-modal
      #applicationConfirmationModal
      [selectedJob]="selectedJob"
      [selectedApplication]="selectedApplication"
      (submitApplication)="submitApplication($event)"
    ></application-confirmation-modal>
  </ng-container>
</ng-container>

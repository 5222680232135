<sdk-conversation-group-nav
  [conversationFilter]="conversationFilter"
  [darkMode]="true"
  [emptyStateSubtitle]="'This is where you discuss your job applications with employers'"
  [groupFn]="groupFn"
  [groupNameFn]="groupNameFn"
  [types]="
    features?.jobApplicationChannels ? [ConversationTypes.JobApplicationDM, ConversationTypes.JobApplication] : [ConversationTypes.JobApplicationDM]
  "
></sdk-conversation-group-nav>

import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { redirectLoggedInToHomeGuard } from '@engineering11/auth-web'
import { FileNotFoundComponent } from '../_404/404.component'
import { RegistrationComponent } from './registration.component'
import { RegisterApplyComponent } from './views/register-apply/register-apply.component'
import { RegisterReferComponent } from './views/register-refer/register-refer.component'
// This 'DenyGuard' is used to block users from gettign back to the 'auth' module post login
// All 'user' management should be done in a 'users' module. 'auth' is not reusable for user management
import { RegistrationRegisterComponent } from './views/register/register.component'

const routes: Routes = [
  {
    path: '',
    component: RegistrationComponent,
    ...redirectLoggedInToHomeGuard(),
  },
  {
    path: 'registration',
    component: RegistrationComponent,
    children: [
      {
        path: '',
        component: RegistrationRegisterComponent,
      },
      {
        path: 'register',
        component: RegistrationRegisterComponent,
        ...redirectLoggedInToHomeGuard(),
      },
      {
        path: 'register-referral',
        component: RegisterReferComponent,
        ...redirectLoggedInToHomeGuard(),
      },
      {
        path: 'register/:deepLink',
        component: RegistrationRegisterComponent,
        ...redirectLoggedInToHomeGuard(),
      },
      {
        path: 'register-referral/:deepLink',
        component: RegisterReferComponent,
        ...redirectLoggedInToHomeGuard(),
      },
      {
        path: 'register-apply/:deepLink',
        component: RegisterApplyComponent,
        ...redirectLoggedInToHomeGuard(),
      },
      {
        path: '**',
        component: FileNotFoundComponent,
      },
    ],
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RegistrationRoutingModule {}

import { Injectable } from '@angular/core'
import { IResumeShareResponse, IResumeTokenSettings, ISharedResumeToken } from '@candidate/app/models/resume-share.model'
import { environment } from '@candidate/environments/environment'
import { isNotNil } from '@engineering11/utility'
import { Timestamp } from '@engineering11/web-api-firebase'
import { RestApiClient } from '@engineering11/web-api-rest'
import { Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { copyToClipboard, getCurrentToken } from 'shared-lib'
import { TokenManagementService } from '../token-management.service'

export type ResumeTokenCreateRequest = { resumeId: string; tokenSettings: IResumeTokenSettings }

@Injectable({ providedIn: 'root' })
export class ResumeTokenService {
  private restApiClient: RestApiClient
  private restApiClientWithOutAuth: RestApiClient
  constructor(private store: Store, private tokenManagementService: TokenManagementService) {
    this.restApiClient = new RestApiClient({
      baseUrl: environment.services.candidate,
      token: store.select(getCurrentToken).pipe(filter(isNotNil)),
    })

    this.restApiClientWithOutAuth = new RestApiClient({
      baseUrl: environment.services.candidate,
      token: of(null),
    })
  }

  createAccessToken(req: ResumeTokenCreateRequest) {
    const { resumeId, tokenSettings } = req
    return this.restApiClient.post<Timestamp<ISharedResumeToken>>(`candidate-resume/${resumeId}/token`, tokenSettings).pipe(
      filter(isNotNil),
      map(res => res.data)
    )
  }

  getFromToken(token: string): Observable<IResumeShareResponse> {
    return this.restApiClientWithOutAuth.get<IResumeShareResponse>(`candidate-resume/share-data/${token}`).pipe(
      filter(isNotNil),
      map(res => res.data)
    )
  }

  getAllTokens(): Observable<Timestamp<ISharedResumeToken>[]> {
    return this.restApiClient.get<Timestamp<ISharedResumeToken>[]>('candidate-resume/tokens').pipe(
      filter(isNotNil),
      map(res => res.data)
    )
  }

  deactivateAccessToken(token: string) {
    return this.tokenManagementService.disableToken(token).pipe(
      map(_ => ({
        message: 'success',
      }))
    )
  }
  reactivateAccessToken(token: string) {
    return this.tokenManagementService.enableToken(token).pipe(
      map(_ => ({
        message: 'success',
      }))
    )
  }

  deleteAccessToken(token: string) {
    return this.tokenManagementService.deleteToken(token).pipe(
      map(_ => ({
        message: 'success',
      }))
    )
  }
}

export function buildOriginalUrl(token: string) {
  return `${environment.candidateBaseUrl}/#/public/profile-share/profile/${token}`
}

export function copyShareUrl(token: ISharedResumeToken) {
  const copyUrl = token.dynamicLink ? token.dynamicLink : buildOriginalUrl(token.token)
  copyToClipboard(copyUrl)
}

<div class="view e11-flex e11-flex-col e11-justify-center e11-py-6 e11-px-6">
  <div class="sm:e11-mx-auto sm:e11-w-full sm:e11-max-w-md">
    <div class="e11-w-full e11-flex e11-justify-center">
      <img [src]="logoUrl" class="e11-h-24" />
    </div>
    <h1 class="e11-text-center e11-font-extrabold">
      {{ 'Verify Your Email' | translate }}
    </h1>
  </div>

  <div class="sm:e11-mx-auto sm:e11-w-full sm:e11-max-w-md">
    <e11-panel [bgOpacity]="90" [contentClassOverrides]="'e11-p-6 e11-pb-12'">
      <auth-verification (onResend)="resendEmail()"></auth-verification>
    </e11-panel>
  </div>
</div>

import { Pipe, PipeTransform } from '@angular/core'
import { CnectFeatures } from 'shared-lib'
import { CandidateHomeNavigationService } from '@candidate/app/services/candidate-home-navigation.service'
import { INavigationItem } from '@engineering11/ui-lib/e11-nav-left'

@Pipe({
  name: 'canDisplayNavItem',
})
export class CanDisplayNavItemPipe implements PipeTransform {
  constructor(private navigationService: CandidateHomeNavigationService) {}

  transform(value: INavigationItem, features: CnectFeatures): boolean {
    return this.navigationService.canDisplayNavItem(value, features)
  }
}

import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgAisModule } from '@engineering11/angular-instantsearch'
import { UiComponentLibraryModule } from '../_component-library/component-library.module'
import { SharedModule } from '../_shared/shared.module'
import { LandingComponent } from './landing.component'
import { LandingRoutingModule } from './landing.routing'
import { JobPostComponent } from './views/job-post/job-post.component'
import { JobSearchComponent } from './views/job-search/job-search.component'
import { LandingStartComponent } from './views/start/start.component'

@NgModule({
  declarations: [LandingComponent, LandingStartComponent, JobPostComponent, JobSearchComponent],
  imports: [
    CommonModule,
    SharedModule,
    LandingRoutingModule,
    UiComponentLibraryModule,
    FormsModule,
    ReactiveFormsModule,
    // ConfirmModule.forRoot(),
    NgAisModule,
  ],
  exports: [],
  providers: [],
})
export class LandingModule {}

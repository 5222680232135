<div class="e11-info-tips e11-info-tips-active e11-relative e11-bg-skin-app-bg-dark" [@animationScaleUp]>
  <div class="e11-info-tips-bg"></div>
  <div class="e11-info-tips-content e11-pb-6 e11-w-full e11-p-4 e11-relative">
    <div
      class="e11-info-tips-closer e11-absolute e11-top-3 e11-right-3 e11-cursor-pointer e11-text-skin-extra-light hover:e11-text-white"
      (click)="this.closeInfoContent.emit(true)"
    >
      <span class="material-icons-outlined"> cancel </span>
    </div>
    <h3 class="e11-text-skin-primary-accent e11-mb-6">{{ 'Did you know...' | translate }}</h3>
    <!-- Content -->
    <div class="e11-grid e11-grid-cols-3 e11-gap-4">
      <div class="e11-border e11-border-skin-grey/20 e11-rounded e11-p-4">
        <h4 class="e11-text-skin-extra-light e11-mb-6 e11-flex e11-items-center">
          <span class="material-icons e11-mr-2 e11-text-skin-primary-accent"> diversity_3 </span>
          {{ 'Social' | translate }}
        </h4>
        <p
          (click)="navigateTo(infoContentRoutes.SocialNetwork); this.closeInfoContent.emit(true)"
          class="e11-text-skin-extra-light hover:e11-text-white e11-cursor-pointer e11-mb-4 e11-flex e11-items-center"
        >
          {{ 'Invite unlimited friends to your own Cnect network!' | translate }}
        </p>
        <p
          [routerLink]="[infoContentRoutes.SocialCommunity]"
          (click)="this.closeInfoContent.emit(true)"
          class="e11-text-skin-extra-light hover:e11-text-white e11-cursor-pointer e11-mb-4 e11-flex e11-items-center"
        >
          {{ 'Create your own Community' | translate }}
        </p>
      </div>

      <div class="e11-border e11-border-skin-grey/20 e11-rounded e11-p-4">
        <h4 class="e11-text-skin-extra-light e11-mb-6 e11-flex e11-items-center">
          <span class="material-icons e11-mr-2 e11-text-skin-primary-accent"> person_pin </span>
          {{ 'Profiles' | translate }}
        </h4>
        <p
          *ngIf="features?.resumeParsing"
          [routerLink]="[infoContentRoutes.ProfileResume]"
          (click)="this.closeInfoContent.emit(true)"
          class="e11-text-skin-extra-light hover:e11-text-white e11-cursor-pointer e11-scroll-mb-4 e11-flex e11-items-center"
        >
          {{ 'We can auto-generate your profiles!' | translate }}
        </p>
        <p
          [routerLink]="[infoContentRoutes.ProfileFIV]"
          (click)="this.closeInfoContent.emit(true)"
          class="e11-text-skin-extra-light hover:e11-text-white e11-cursor-pointer e11-mb-4 e11-flex e11-items-center"
        >
          {{ 'First impression Videos get results!' | translate }}
        </p>
      </div>

      <div class="e11-border e11-border-skin-grey/20 e11-rounded e11-p-4">
        <h4 class="e11-text-skin-extra-light e11-mb-6 e11-flex e11-items-center">
          <span class="material-icons-outlined e11-mr-2 e11-text-skin-primary-accent"> travel_explore </span>
          {{ 'Jobs Related' | translate }}
        </h4>
        <p
          [routerLink]="[infoContentRoutes.JobHome]"
          (click)="this.closeInfoContent.emit(true)"
          class="e11-text-skin-extra-light hover:e11-text-white e11-cursor-pointer e11-mb-4 e11-flex e11-items-center"
        >
          {{ 'All you need is right here!' | translate }}
        </p>
        <p
          [routerLink]="[infoContentRoutes.JobSearch]"
          (click)="this.closeInfoContent.emit(true)"
          class="e11-text-skin-extra-light hover:e11-text-white e11-cursor-pointer e11-mb-4 e11-flex e11-items-center"
        >
          {{ 'Job Search is coming soon!' | translate }}
        </p>
      </div>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core'
import { environment } from '@candidate/environments/environment'
import { IDENTICON } from '../app.constants'

@Injectable({ providedIn: 'root' })
export class IdenticonService {
  constructor() {}
  buildPath(userId: string) {
    return `https://${environment.cdnCname}/${IDENTICON.ROOT_PATH}${userId}/${userId}${IDENTICON.TAIL}`
  }
}

import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { JobSearchStore } from '../../store/job-search.store'

export interface ISearchFormRequest {
  query: string
  address: string
}

@Component({
  selector: 'search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss'],
})
export class SearchFormComponent implements OnInit {
  @Output() search = new EventEmitter<ISearchFormRequest>()

  jobSearchResult$ = this.jobSearchStore.jobSearchResult$

  jobSearchForm: FormGroup = this.formBuilder.group({
    query: new FormControl(''),
    address: new FormControl(),
  })

  constructor(private formBuilder: FormBuilder, private jobSearchStore: JobSearchStore) {}

  ngOnInit(): void {}

  onSubmit() {
    this.search.emit(this.jobSearchForm.value)
  }
}

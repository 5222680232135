<div class="e11-w-full e11-h-8 e11-bg-skin-primary"></div>

<footer class="e11-bg-skin-app-bg-dark" aria-labelledby="footer-heading" id="appFooter">
  <h2 id="footer-heading" class="e11-sr-only">Footer</h2>
  <div class="e11-container-full e11-mx-auto">
    <div class="e11-grid e11-grid-cols-2 e11-px-6">
      <img [src]="logoUrl" class="e11-h-16" />
      <div class="e11-flex e11-grow e11-justify-end e11-items-center">
        <a class="e11-text-sm e11-text-skin-light hover:e11-text-skin-extra-light e11-cursor-pointer" [routerLink]="[CNECT_ROUTES.PRIVACY_POLICY]">
          {{ 'Privacy Policy' | translate }}
        </a>
        <a
          class="e11-text-sm e11-text-skin-light hover:e11-text-skin-extra-light e11-cursor-pointer e11-ml-4"
          [routerLink]="[CNECT_ROUTES.TERMS_OF_USE]"
        >
          {{ 'Terms of Use' | translate }}
        </a>
      </div>
    </div>
  </div>
  <div class="e11-container-full e11-mx-auto e11-px-6 e11-pb-4">
    <div class="e11-w-full e11-flex e11-border-t e11-border-skin-grey/50 e11-pt-4">
      <div class="e11-grow">
        <app-copyright></app-copyright>
      </div>
      <div class="e11-flex e11-grow e11-justify-end e11-items-center"></div>
    </div>
  </div>
</footer>

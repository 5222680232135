import { Component } from '@angular/core'
import { AppConfigService } from '@engineering11/tenant-bootstrap-web'

@Component({
  templateUrl: './deactivation.component.html',
  styleUrls: ['./deactivation.component.scss'],
})
export class AccountDeactivationComponent {
  logoUrl = this.appConfig.config.brand.logoImageDarkUrl
  constructor(private appConfig: AppConfigService) {}
}

import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { IJobSearchResultVM } from '@candidate/app/models/jobs/job-search.model'
import { Optional } from '@engineering11/types'
import { e11Error, E11ErrorData } from '@engineering11/web-api-error'
import { CNECT_ERRORS } from 'shared-lib'
import { E11NotificationsService } from '@engineering11/ui-lib/e11-notifications'
import { filter, map } from 'rxjs/operators'
import { isNotNil } from '@engineering11/utility'
import { copyToClipboard, openInNewTab, ViewportService } from '@engineering11/web-utilities'
import { CandidateHomeNavigationService } from '@candidate/app/services/candidate-home-navigation.service'

@Component({
  selector: 'search-result-detail',
  templateUrl: './search-result-detail.component.html',
  styleUrls: ['./search-result-detail.component.scss'],
})
export class SearchResultDetailComponent implements OnInit {
  @ViewChild('contentContainer') contentContainer!: ElementRef

  @Input() set selectedJob(selectedJob: IJobSearchResultVM | null) {
    this._selectedJob = selectedJob
    if (this.contentContainer) {
      this.contentContainer.nativeElement.scrollTop = 0
    }
  }

  @Input() loading: boolean = false

  _selectedJob: IJobSearchResultVM | null = null
  isMobile$ = this.viewportService.belowBreakpoint('md')

  constructor(
    private router: Router,
    private notificationService: E11NotificationsService,
    private viewportService: ViewportService,
    private candidateNavigationService: CandidateHomeNavigationService
  ) {}

  ngOnInit(): void {}

  navigateToJobSite(companyUrl: string) {
    openInNewTab(companyUrl)
  }

  async copy() {
    if (!this._selectedJob?.directJobUrl) return

    copyToClipboard(this._selectedJob.directJobUrl)

    this.notificationService.popNotificationMessage({
      title: 'Job URL copied',
      message: 'Link copied to clipboard',
      type: 'success',
      autoClose: true,
      dismissOnRouteChange: true,
    })
  }

  startApplicationProcess() {
    const { id, directJobUrl, isInternalJob, jobPostId } = this._selectedJob!
    if (directJobUrl) {
      openInNewTab(directJobUrl)
      return
    }

    if (!isInternalJob) throw e11Error(jobNotFoundErrorData)

    this.candidateNavigationService.applyToJob(jobPostId)
  }

  favoriteApplication() {}
}

const jobNotFoundErrorData: Optional<E11ErrorData, 'timestamp' | 'message'> = {
  type: CNECT_ERRORS.JOB_URL_NOT_FOUND,
  title: 'Job URL not found. Our engineers have been notified and are working on a solution',
}

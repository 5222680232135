import { Injectable } from '@angular/core'
import { ICandidateResume } from '@candidate/app/models/candidate-resume.model'
import { CollectionRepository, FirestoreQueryBuilder } from '@engineering11/web-api-firebase'
import { COLLECTIONS } from 'shared-lib'

@Injectable({ providedIn: 'root' })
export class CandidateResumeRepository extends CollectionRepository<ICandidateResume> {
  COLLECTION = COLLECTIONS.CANDIDATE_RESUME

  getAllForUser(userId: string) {
    const query = new FirestoreQueryBuilder().where('userId', '==', userId).build()
    return this.query(query)
  }
}

import { Component, Input } from '@angular/core'

@Component({
  selector: 'candidate-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent {
  @Input() leftNavEnabled = true
}

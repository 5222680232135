import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { AuthConfigProvider } from '@candidate/app/config/auth.config'
import { AuthModule } from '@engineering11/auth-web'
import { IUserRestService, UserModule, UserRestService } from '@engineering11/user-web'
import { UiComponentLibraryModule } from '../_component-library/component-library.module'
import { SharedModule } from '../_shared/shared.module'
import { AuthenticationComponent } from './authentication.component'
import { AuthenticationRoutingModule } from './authentication.routing'
import { AuthRecoveryFindAccountComponent } from './views/account-recovery/find-account/find-account.component'
import { AuthRecoveryVerificationCodeComponent } from './views/account-recovery/verification-code/verification-code.component'
import { AuthEmailVerificationComponent } from './views/email-verification/email-verification.component'
import { AuthenticationLoginComponent } from './views/login/login.component'
import { AuthSuccessComponent } from './views/success/success.component'
import { AuthTermsOfUseComponent } from './views/terms/terms.component'
import { UserConfigProvider } from '@candidate/app/config/user.config'

@NgModule({
  declarations: [
    AuthenticationComponent,
    AuthenticationLoginComponent,
    AuthRecoveryFindAccountComponent,
    AuthRecoveryVerificationCodeComponent,
    AuthEmailVerificationComponent,
    AuthTermsOfUseComponent,
    AuthSuccessComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    AuthenticationRoutingModule,
    AuthModule.forRoot({ configProvider: AuthConfigProvider }),
    UserModule.forRoot({ configProvider: UserConfigProvider }),
    UiComponentLibraryModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [],
  providers: [{ provide: IUserRestService, useClass: UserRestService }],
})
export class AuthenticationModule {}

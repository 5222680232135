import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { JobsComponent } from './jobs.component'
import { JobsSearchComponent } from './views/search/search.component'
import { FeatureGuard } from '@engineering11/config-web'
import { CnectFeature } from 'shared-lib'

const jobSearch: CnectFeature = 'jobSearch'

const routes: Routes = [
  {
    path: '',
    component: JobsComponent,
    children: [
      {
        path: '',
        redirectTo: 'search',
        pathMatch: 'full',
      },
      {
        path: 'search',
        component: JobsSearchComponent,
      },
    ],
    canActivate: [FeatureGuard],
    data: { feature: jobSearch },
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class JobsRoutingModule {}

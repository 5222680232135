import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core'
import { ICandidateResume, ICandidateResumeData } from '@candidate/app/models/candidate-resume.model'

@Component({
  selector: 'modal-resume-parse',
  templateUrl: './modal-resume-parse.component.html',
  styleUrls: ['./modal-resume-parse.component.scss'],
})
export class ModalResumeParseComponent implements OnInit {
  @Input() resume!: ICandidateResumeData | undefined
  @Output() closeModal = new EventEmitter()

  resumeMissingData: boolean = false

  constructor() {}

  ngOnInit(): void {
    this.importResumeMissingData(this.resume!)
  }

  close() {
    this.closeModal.emit(true)
  }

  importResumeMissingData(importedResume: ICandidateResumeData) {
    this.resumeMissingData = false
    if (
      !importedResume?.summary ||
      !importedResume?.address ||
      !importedResume?.address?.city ||
      !importedResume?.address?.state ||
      !importedResume?.address?.zip
    ) {
      this.resumeMissingData = true
    }
  }
}

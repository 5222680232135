import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RegistrationConfigProvider } from '@candidate/app/config/registration.config'
import { FilesModule } from '@engineering11/files-web'
import { RegistrationModule as RegistrationModuleSDK } from '@engineering11/registration-web'
import { UiComponentLibraryModule } from '../_component-library/component-library.module'
import { SharedModule } from '../_shared/shared.module'
import { RegistrationComponent } from './registration.component'
import { RegistrationRoutingModule } from './registration.routing'
import { RegisterApplyComponent } from './views/register-apply/register-apply.component'
import { RegisterReferComponent } from './views/register-refer/register-refer.component'
import { RegistrationRegisterComponent } from './views/register/register.component'

@NgModule({
  declarations: [RegistrationComponent, RegistrationRegisterComponent, RegisterApplyComponent, RegisterReferComponent],
  imports: [
    CommonModule,
    SharedModule,
    RegistrationRoutingModule,
    UiComponentLibraryModule,
    FormsModule,
    FilesModule,
    ReactiveFormsModule,
    RegistrationModuleSDK.forRoot({ configProvider: RegistrationConfigProvider }),
  ],
  exports: [],
  providers: [],
})
export class RegistrationModule {}

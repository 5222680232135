<div class="bg"></div>
<div class="header"></div>
<div class="view">
  <div class="e11-w-full e11-px-6">
    <h1 class="registration-consumer-title e11-text-skin-primary-accent e11-mb-4 e11-w-full lg:e11-w-2/3">
      {{ 'Start cnecting with your peers in the most authentic way possible.' | translate }}
    </h1>

    <div class="e11-grid e11-grid-cols-1 sm:e11-grid-cols-2 e11-gap-4">
      <div>
        <e11-panel [bgOpacity]="90">
          <div class="e11-text-center">
            <img class="e11-mx-auto e11-h-24 e11-w-auto" [src]="logoUrl" alt="Cnect" />
            <h2 class="e11-mt-4 e11-text-center e11-font-extrabold e11-text-skin-primary">
              {{ 'Create Your Account' | translate }}
            </h2>
            <p class="e11-text-sm e11-text-skin-base">
              {{ 'Already have an account?' | translate }}
              <a (click)="navigateToLogin()" class="e11-cursor-pointer e11-font-medium e11-text-skin-secondary hover:e11-text-skin-secondary">
                {{ 'Sign In' | translate }}
              </a>
            </p>
          </div>
          <sdk-registration-consumer (onSubmit)="onSubmit($event)" (termsClicked)="termsClicked()"></sdk-registration-consumer>
        </e11-panel>
      </div>
      <div></div>
    </div>
  </div>
</div>

import { ViewportScroller } from '@angular/common'
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { IJobSearchRequest, IJobSearchResultVM } from '@candidate/app/models/jobs/job-search.model'
import { JobSearchStore } from '@candidate/app/modules/jobs/store/job-search.store'
import { LocationStore } from '@engineering11/maps-web'
import { isNotNil } from '@engineering11/utility'
import { ViewportService } from '@engineering11/web-utilities'
import { Store } from '@ngrx/store'
import { firstValueFrom, Subscription } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { hookToGlobalState } from 'shared-lib'
import { ISearchFormRequest } from '../../components/search-form/search-form.component'

@Component({
  selector: 'jobs-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class JobsSearchComponent implements OnInit {
  @ViewChild('mobileBackButton', { static: false }) mobileBackButton!: ElementRef

  jobsSearched: boolean = false

  jobSearchResult$ = this.jobSearchStore.jobSearchResult$
  searchResultLoading$ = this.jobSearchStore.searchResultLoading$
  nextSearchResultLoading$ = this.jobSearchStore.nextSearchResultLoading$
  selectedJob$ = this.jobSearchStore.selectedJob$

  isMobile: boolean = false
  isMobile$ = this.viewportService.belowBreakpoint('md')
  approximateLocation$ = this.locationStore.approximateLocation$
  showDetailsOnMobile = false

  maxPageResult = 20

  currentSearchRequest: IJobSearchRequest = {}

  constructor(
    private jobSearchStore: JobSearchStore,
    private store: Store,
    private viewportService: ViewportService,
    private scroller: ViewportScroller,
    private locationStore: LocationStore
  ) {}

  ngOnInit(): void {
    this.locationStore.onGetApproximateLocationIfNotLoaded()
    this.onSearch({ address: '', query: '' })
    hookToGlobalState(this.jobSearchStore.getState, 'job-search', this.store)
    if (this.isMobile) {
      this.showDetailsOnMobile = true
    }
  }

  async onSearch(request: ISearchFormRequest): Promise<void> {
    this.jobsSearched = true
    this.currentSearchRequest = await this.getCurrentRequest(request)
    this.jobSearchStore.onSearchJobs(this.currentSearchRequest)
  }

  loadMoreJobs(nextPageToken: string) {
    this.jobSearchStore.onLoadMoreJobs({ ...this.currentSearchRequest, pageToken: nextPageToken })
  }

  selectJob(job: IJobSearchResultVM) {
    this.jobSearchStore.onSelectJob(job)
    this.showDetailsOnMobile = true
    setTimeout(() => this.scroller.scrollToAnchor('mobileBackButton'), 50)
  }

  hideDetails() {
    this.showDetailsOnMobile = false
    setTimeout(() => this.scrollToSelectedJob(), 50)
  }

  scrollToSelectedJob(): void {
    const listElement = document.querySelector('.search-results')

    if (!listElement) {
      return
    }

    const scrollableContainer = listElement.getBoundingClientRect().top

    const selectedJobElem = document.querySelector('.selected-job')

    const offsetTop = selectedJobElem?.getBoundingClientRect().top

    if (offsetTop) {
      listElement.scrollTo(0, offsetTop - scrollableContainer)
    }
  }

  private async getCurrentRequest(request: ISearchFormRequest) {
    const { query, address } = request

    const maxPageSize = this.maxPageResult

    return { query, maxPageSize }

    // if (address) {
    //   return { query, location: { address }, maxPageSize }
    // } else {
    //   // If no location is input, use their current location
    //   const locationData = await firstValueFrom(this.approximateLocation$.pipe(filter(isNotNil)))
    //   const latLng = { latitude: locationData.lat, longitude: locationData.lng }
    //   const location = { latLng }
    //   return { query, location, maxPageSize }
    // }
  }
}

import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'inArray',
})
export class InArrayPipe implements PipeTransform {
  transform(value: string, array: string[]): boolean {
    return array.includes(value)
  }
}

import { ICandidateApplicationUpdateStage } from '@candidate/app/models/candidate-job-update.model'
import { buildApplicationStageId } from '@candidate/app/services/candidate-application/candidate-application-stage.service'
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { IApplicationHistory, IJobApplicationStage, IProcessedJobApplication, IVirtualDialogue } from 'shared-lib'
import { ApplicationActions, ApplicationActionTypes } from './application.actions'

export interface State extends EntityState<IJobApplicationStage> {
  error: any | null
  processedApplicationPreview: IProcessedJobApplication | null // For the selected application
  selectedJobApplication: IProcessedJobApplication | null
  selectedApplicationHistory: IApplicationHistory[] | null // Application History for selected job - consider making entity state
  selectedApplicationUpdateStage: ICandidateApplicationUpdateStage | null
  selectedApplicationDialogues: IVirtualDialogue[] | null
  loadingGetApplicationStage: boolean
  loadingSaveApplicationStage: boolean
  loadingApplicationPreview: boolean
  loadingApplicationHistory: boolean
  loadingSelectedJobApplication: boolean
  loadingVirtualDialogues: boolean
  submittingApplicationUpdate: boolean
  withdrawingApplication: boolean
  loadingChooseResume: boolean
}

export const adapter: EntityAdapter<IJobApplicationStage> = createEntityAdapter<IJobApplicationStage>({
  selectId: applicationStage => buildApplicationStageId(applicationStage),
})

export const initialState: State = adapter.getInitialState({
  error: null,
  processedApplicationPreview: null,
  selectedJobApplication: null,
  selectedApplicationHistory: null,
  selectedApplicationUpdateStage: null,
  selectedApplicationDialogues: null,
  loadingGetApplicationStage: false,
  loadingSaveApplicationStage: false,
  loadingApplicationPreview: false,
  loadingApplicationHistory: false,
  loadingSelectedJobApplication: false,
  loadingVirtualDialogues: false,
  submittingApplicationUpdate: false,
  withdrawingApplication: false,
  loadingChooseResume: false,
})

export function reducer(state = initialState, action: ApplicationActions): State {
  switch (action.type) {
    case ApplicationActionTypes.getApplicationStage:
      return { ...state, loadingGetApplicationStage: true }
    case ApplicationActionTypes.getApplicationStageSuccessWithEmpty:
      return { ...state, loadingGetApplicationStage: false }
    case ApplicationActionTypes.getApplicationStageSuccess:
      return adapter.setOne(action.payload, { ...state, loadingGetApplicationStage: false })
    case ApplicationActionTypes.getApplicationHistory:
      return { ...state, loadingApplicationHistory: true }
    case ApplicationActionTypes.getApplicationHistorySuccess:
      return { ...state, selectedApplicationHistory: action.payload, loadingApplicationHistory: false }
    case ApplicationActionTypes.createApplicationStage:
    case ApplicationActionTypes.updateApplicationStage:
      return { ...state, loadingSaveApplicationStage: true }
    case ApplicationActionTypes.createApplicationStageSuccess:
      return adapter.setOne(action.payload, { ...state, loadingSaveApplicationStage: false, loadingGetApplicationStage: false })
    case ApplicationActionTypes.updateApplicationStageSuccess:
      return adapter.updateOne(
        {
          id: buildApplicationStageId(action.payload),
          changes: action.payload,
        },
        {
          ...state,
          loadingSaveApplicationStage: false,
          loadingChooseResume: false,
        }
      )
    case ApplicationActionTypes.processJobApplicationPreview:
      return { ...state, loadingApplicationPreview: true }
    case ApplicationActionTypes.processJobApplicationPreviewSuccess:
      return {
        ...state,
        processedApplicationPreview: action.payload,
        loadingApplicationPreview: false,
        selectedJobApplication: state.selectedJobApplication
          ? {
              // we need to recalculate the jobMatchScore when we update the application from a request update
              ...state.selectedJobApplication,
              jobMatchScore: action.payload.jobMatchScore,
            }
          : null,
      }
    case ApplicationActionTypes.stageApplicationUpdateSuccess:
      return { ...state, selectedApplicationUpdateStage: { ...state.selectedApplicationUpdateStage, ...action.payload } }
    case ApplicationActionTypes.getStagedApplicationUpdateSuccess:
      return { ...state, selectedApplicationUpdateStage: action.payload }
    case ApplicationActionTypes.getJobApplication:
      return { ...state, loadingSelectedJobApplication: true }
    case ApplicationActionTypes.getJobApplicationSuccess:
      return { ...state, selectedJobApplication: action.payload, loadingSelectedJobApplication: false }
    case ApplicationActionTypes.getSelectedApplicationVirtualDialogues:
      return { ...state, loadingVirtualDialogues: true }
    case ApplicationActionTypes.getSelectedApplicationVirtualDialoguesSuccess:
      return { ...state, selectedApplicationDialogues: action.payload, loadingVirtualDialogues: false }
    case ApplicationActionTypes.submitApplicationUpdate:
      return { ...state, submittingApplicationUpdate: true }
    case ApplicationActionTypes.submitApplicationUpdateSuccess:
      return { ...state, submittingApplicationUpdate: false }
    case ApplicationActionTypes.withdrawApplication:
      return { ...state, withdrawingApplication: true }
    case ApplicationActionTypes.withdrawApplicationSuccess:
      return { ...state, withdrawingApplication: false }
    case ApplicationActionTypes.applicationChooseResume:
      return { ...state, loadingChooseResume: true }
    case ApplicationActionTypes.error:
      return { ...state, error: action.payload, loadingChooseResume: false }
    default:
      return state
  }
}

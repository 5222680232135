import { IConversationVM } from '@engineering11/messaging-web'
import { ConversationTypes, IEmployerConversationVM } from 'shared-lib'

/**
 * This file is for utils to share between mobile side drawer and nav-left
 * This can be pulled out if mobile side drawer is merged into nav-left
 */

export function jobRelatedConversationFilter(conversation: IConversationVM) {
  return !conversation.closed
}

export function conversationCountFilter(conversation: IConversationVM) {
  return conversation.type === ConversationTypes.SocialDm || jobRelatedConversationFilter(conversation)
}

export const jobGroupFn: (obj: IConversationVM) => string = (obj: IEmployerConversationVM) =>
  obj.clientMetadata?.companyId ?? obj.clientMetadata?.companyName ?? obj.shortName // TODO: Address missing value

export const jobGroupNameFn: (obj: IConversationVM) => string = (obj: IEmployerConversationVM) => obj.clientMetadata?.companyName ?? obj.shortName // TODO: Address missing value

import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core'
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms'
import { E11PopoverGlobalService } from '@engineering11/ui-lib/e11-popover'
import { removeDuplicatesByNameIgnoreCase } from '@engineering11/web-utilities'
import { ICertification } from '../../model/interfaces'
import { E11FormValidationService } from '@engineering11/ui-lib/e11-input-errors'

@Component({
  selector: 'manage-certifications',
  templateUrl: './manage-certifications.component.html',
  styleUrls: ['./manage-certifications.component.scss'],
})
export class ManageCertificationsComponent {
  @ViewChild('certificationPopover') certificationPopover!: TemplateRef<any>
  @Input() currentCertifications: ICertification[] = []
  @Input() loading = true
  @Input() readOnly = false
  @Input() showCompare = false

  @Input() markAsMissingSkills = false

  @Output() newCertifications = new EventEmitter<ICertification[]>()
  @Output() compareClicked = new EventEmitter()

  state: string = '' // default(blank), 'edit' (edit will work for adding and editing)
  addItem: string = '' // 'skill'(default), 'edit'
  ctaTextCertification: string = 'Add'

  // New
  modifyItem: any = '' // blank/default,   skill,  cert   -- this is for adding or editing a skill/cert
  modifyItemType: any = '' // blank/default,   skill,  cert   -- this is for adding or editing a skill/cert
  modifyTask: string = '' // edit or remove

  // Forms
  formAddCert = new UntypedFormGroup({})
  formAddCertSubmitted = false

  errorMessages = [
    {
      error: 'required',
      message: 'A name is required',
    },
    {
      error: 'hasWhitespaceError',
      message: "Can't start with a space",
    },
    {
      error: 'maxlength',
      message: "Name can't be longer than 100 characters",
    },
  ]

  constructor(
    private formBuilder: UntypedFormBuilder,
    private customValidator: E11FormValidationService,
    private popoverGlobalService: E11PopoverGlobalService
  ) {}

  ngOnInit(): void {
    this.createFormAddCert()
  }

  // Adding certifications form
  get f() {
    return this.formAddCert.controls as {
      [key: string]: UntypedFormControl
    }
  }

  duplicateCertificationValidator(): ValidatorFn {
    return (control: AbstractControl) => {
      if (!this.markAsMissingSkills && this.modifyTask === 'add' && this.currentCertifications.some(cert => cert.name === control.value)) {
        return { name: 'cannot have duplicate certification' }
      }
      return null
    }
  }

  // Certifications
  createFormAddCert(): void {
    this.formAddCert = this.formBuilder.group({
      nameCert: new UntypedFormControl('', [
        Validators.required,
        this.customValidator.whitespaceValidator(),
        this.duplicateCertificationValidator(),
        Validators.maxLength(100),
      ]),
    })
  }

  onSubmitFormAddCert(currentCertifications: ICertification[] | null): void {
    this.formAddCertSubmitted = true // Used for UI error messages
    if (this.formAddCert.valid && currentCertifications) {
      if (this.modifyItemType === 'certification' && this.modifyTask === 'edit') {
        this.editExistingCertification(currentCertifications)
      } else if (this.markAsMissingSkills) {
        this.addMissingCertification()
      } else {
        this.addLineItemCertification(currentCertifications)
      }
      this.formAddCertSubmitted = false // resets forms error handling
      this.editStateRemove()
    }
  }

  addMissingCertification() {
    const name = this.formAddCert.get('nameCert')?.value

    this.saveCertifications([{ name }])
  }

  // Changes view/form state from read only to add/edit
  stateChange(value: string): void {
    this.state = value
    if (!value) {
      this.editStateRemove()
    }
  }

  // Adding and removing individual items intentionally separated
  addLineItemCertification(currentCertifications: ICertification[]) {
    const name = this.formAddCert.get('nameCert')?.value
    const newCertification: ICertification = { name }

    const newCertifications = removeDuplicatesByNameIgnoreCase([...currentCertifications, newCertification])
    this.saveCertifications(newCertifications)
  }

  editExistingCertification(currentCertifications: ICertification[]) {
    if (currentCertifications) {
      const name = this.formAddCert.get('nameCert')?.value
      const newCert = { name }
      const newCertifications = currentCertifications.map((cert: ICertification) => (cert.name === this.modifyItem.name ? newCert : cert))
      this.saveCertifications(newCertifications)
    }
  }

  removeCertification(item: string, currentCertifications: ICertification[] | null) {
    if (currentCertifications) {
      const newCertifications = currentCertifications.filter((value: ICertification) => value.name !== item)
      this.saveCertifications(newCertifications)
    }
    this.editStateRemove()
    this.closePopover()
  }

  userTagAction($event: any, skillsOrCerts: ICertification[] | null, index: number) {
    this.modifyItem = $event.item
    this.modifyTask = $event.task
    this.modifyItemType = 'certification'
    this.formAddCert.patchValue({
      nameCert: $event.item.name,
    })

    if ($event.task === 'edit') {
      this.stateChange('edit')
      this.ctaTextCertification = 'Save'
      this.openPopover('certification-tag-' + index)
    }

    if ($event.task === 'add') {
      this.stateChange('add')
      this.ctaTextCertification = 'Add'
      this.openPopover('add-certification-action')
    }

    if ($event.task === 'remove') {
      this.removeCertification($event.item.name, skillsOrCerts as ICertification[])
    }
  }

  editStateRemove() {
    this.ctaTextCertification = 'Add'
    this.modifyTask = ''
    this.modifyItem = null
    this.formAddCert.reset()
    this.formAddCertSubmitted = false
  }

  saveCertifications(newCertifications: ICertification[]): void {
    this.newCertifications.emit(newCertifications)
  }

  openPopover(targetElement: string) {
    this.popoverGlobalService.openPopover({
      content: this.certificationPopover,
      targetElement: targetElement,
      closeOnEscape: true,
      closeOnClickOutside: true,
    })
  }

  closePopover() {
    this.popoverGlobalService.closePopover()
    this.stateChange('')
  }
}

import { Injectable } from '@angular/core'
import { environment } from '@candidate/environments/environment'
import { RestApiClient } from '@engineering11/web-api-rest'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { IApplicationHistory, TokenStorage } from 'shared-lib'

@Injectable({ providedIn: 'root' })
export class ApplicationHistoryService {
  private restApiClient: RestApiClient
  constructor(tokenStorage: TokenStorage) {
    this.restApiClient = new RestApiClient({
      baseUrl: environment.services.applicationHistory,
      token: () => tokenStorage.getAccessToken(),
    })
  }

  getApplicationHistory(jobPostId: string): Observable<IApplicationHistory[]> {
    return this.restApiClient.get<IApplicationHistory[]>(`job-post/application-history/${jobPostId}`).pipe(map(res => res.data))
  }
}

import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { VirtualDialogueSubmittedNotificationForCandidate } from '@candidate/app/models/app-notification.model'
import { INotificationView, UserAppNotificationService } from '@engineering11/notifications-web'
import { INotificationHandler } from '@engineering11/notifications-web/lib/models/notification-handler.model'
import { CandidateHomeNavigationService } from '../candidate-home-navigation.service'

@Injectable({ providedIn: 'root' })
export class VirtualDialogueSubmittedCandidate implements INotificationHandler<VirtualDialogueSubmittedNotificationForCandidate, INotificationView> {
  notificationType = 'candidate_new_interview_session'
  constructor(
    private candidateHomeNavigationService: CandidateHomeNavigationService,
    private userAppNotificationService: UserAppNotificationService
  ) {}

  onClick(notification: VirtualDialogueSubmittedNotificationForCandidate): boolean | Promise<boolean> {
    this.markAsRead(notification)
    return this.candidateHomeNavigationService.dialogueTab(notification.jobPostId)
  }

  private markAsRead(notification: VirtualDialogueSubmittedNotificationForCandidate) {
    if (notification.viewed) {
      return
    }
    this.userAppNotificationService.markNotificationAsRead(notification.id)
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core'
import { MilitaryAffiliation } from '../../model/enum'
import { UserMode } from '../../model/component-modes.enum'
import { RequestedUpdateFields } from '../../model/candidate-application.model'
import { MilitaryAffiliationService } from '../../service/miltary-affiliation.service'

@Component({
  selector: 'view-military-affiliations',
  template: `
    <div *ngIf="!loading && !militaryAffiliations.length" class="e11-flex e11-flex-col e11-gap-4">
      <e11-empty-state
        [icon]="'military_tech'"
        [iconSize]="'md-36'"
        [iconType]=""
        title="{{ 'No Military Affiliations' | translate }}"
        subtitle="{{
          userMode === userModeEnum.Candidate
            ? 'Your service to your country is valued. Please feel free to share the branches of service you are affiliated with or served in.'
            : ('Candidate did not supply any military affiliations.' | translate)
        }}"
        [clickable]="!readonlyMode"
        (click)="!readonlyMode && editClicked.emit()"
      >
      </e11-empty-state>
    </div>

    <div *ngIf="loading" class="e11-flex e11-my-10 e11-items-center e11-w-1/2 e11-gap-4 e11-flex-wrap md:e11-flex-nowrap">
      <div *ngFor="let i of 3 | range" class="!e11-rounded-full e11-skeleton-empty !e11-w-[70px] !e11-h-[70px]"></div>
    </div>

    <div *ngIf="!loading && militaryAffiliations.length" class="e11-flex e11-items-center e11-gap-2 e11-flex-wrap">
      <img
        *ngFor="let militaryAffiliation of militaryAffiliations"
        [alt]="militaryAffiliation"
        [title]="militaryAffiliation"
        [src]="militaryAffiliationLogoMap.get(militaryAffiliation)"
        class="e11-w-[70px]"
      />
    </div>

    <ng-container *ngIf="showRequestButton && userMode === userModeEnum.Employer">
      <div class="e11-w-full e11-text-center e11-my-4">
        <e11-button
          *ngIf="!openedRequest && !militaryAffiliations.length"
          color="primary-accent"
          [ghost]="true"
          value="{{ 'Request Military Affiliations' | translate }}"
          (click)="!requestsDisabled && requestFromComponent.emit()"
          [disabled]="requestsDisabled"
        ></e11-button>
      </div>
      <!-- Pending/open request -->
      <request-open *ngIf="openedRequest" [request]="openedRequest"></request-open>
    </ng-container>
  `,
  styles: [],
})
export class ViewMilitaryAffiliationsComponent {
  @Input() militaryAffiliations: MilitaryAffiliation[] = []

  @Input() showRequestButton = true
  @Input() loading: boolean = false
  @Input() userMode: UserMode = UserMode.Candidate
  @Input() readonlyMode: boolean = false
  @Input() openedRequest?: RequestedUpdateFields
  @Input() requestsDisabled = false

  @Output() requestFromComponent = new EventEmitter()
  @Output() editClicked = new EventEmitter()

  userModeEnum = UserMode
  militaryAffiliationLogoMap = this.militaryAffiliationService.affiliationUrlMap

  constructor(private militaryAffiliationService: MilitaryAffiliationService) {}
}

import { Component, OnInit } from '@angular/core'
import { Meta } from '@angular/platform-browser'
import { environment } from '@candidate/environments/environment'
declare global {
  interface Window {
    smartbanner: any // smartbanner.js doesn't have typescript type
  }
}
interface SmartBannerOptions {
  title: string
  author: string
  price: string
  'price-suffix-apple': string
  'price-suffix-google': string
  'icon-apple': string
  'icon-google': string
  button: string
  'button-url-apple': string
  'button-url-google': string
  'enabled-platforms': string
  'close-label': string
  'hide-ttl': string
}

@Component({
  selector: 'mobile-banner',
  template: ``,
})
export class MobileBannerComponent implements OnInit {
  smartBannerOptions: SmartBannerOptions = {
    title: 'Cnected',
    author: 'Cnect Inc.',
    price: 'Free',
    'price-suffix-apple': ' - On the App Store',
    'price-suffix-google': ' - In Google Play',
    'icon-apple': environment.smartBannerOptions.appIcon,
    'icon-google': environment.smartBannerOptions.appIcon,
    button: 'OPEN',
    'button-url-apple': environment.smartBannerOptions.appStoreUrl,
    'button-url-google': environment.smartBannerOptions.playStoreUrl,
    'enabled-platforms': 'android,ios',
    'close-label': 'Close',
    'hide-ttl': environment.smartBannerOptions.ttl, // this would keep banner closed for 24 hours
  }
  constructor(private metaService: Meta) {}

  private isIOSInSafari(): boolean {
    const userAgent = window.navigator.userAgent.toLowerCase()
    return /iphone|ipad|ipod/.test(userAgent) && /safari/.test(userAgent) && !/crios/.test(userAgent) && !/fxios/.test(userAgent)
  }

  ngOnInit() {
    // if (this.isIOSInSafari()) {
    //   return
    // }
    // Object.keys(this.smartBannerOptions).forEach(key => {
    //   this.metaService.updateTag({ name: `smartbanner:${key}`, content: this.smartBannerOptions[key as keyof SmartBannerOptions] })
    // })x
    // this.publishSmartBannerScript()
  }

  publishSmartBannerScript() {
    // Manually publish the banner after 2 seconds
    setTimeout(() => {
      if (window.smartbanner) window.smartbanner.publish()
    }, 5000)
  }
}

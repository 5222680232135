import { Component, Input, OnInit } from '@angular/core'
import { ICandidateResume } from '@candidate/app/models/candidate-resume.model'
import { Timestamp } from '@engineering11/web-api-firebase'

@Component({
  selector: 'resume-card',
  templateUrl: './resume-card.component.html',
  styleUrls: ['./resume-card.component.scss'],
})
export class ResumeCardComponent implements OnInit {
  @Input() resume!: Timestamp<ICandidateResume>
  @Input() selectedResumeId?: string

  constructor() {}

  ngOnInit(): void {}
}

import { AtLeast, NamedMapping, Optional } from '@engineering11/types'
import { ICandidateComplianceResponses } from './candidate-compliance.model'
import { APPLICATION_STATE, MilitaryAffiliation } from './enum'
import {
  IAddress,
  ICertification,
  IEducation,
  IFirstImpression,
  IMatchedRequirableCertification,
  IMatchedRequirableSkill,
  IReference,
  IRequirableCertification,
  IRequirableSkill,
  ISkill,
  IWorkHistory,
} from './interfaces'
import { IPortfolio } from './portfolio.model'
import { ILanguage } from './language.model'
import { ISocialMedia } from './social-media.enum'

export interface ICandidateSource {
  source: string
  integration: string
  integrationId: string
}

/** The candidate's record of the application */
export interface ICandidateJobApplication {
  id: string
  candidateId: string // maybe change to userId
  jobPostId: string // all posting information needed for analysis
  firstName: string
  lastName: string
  email: string
  phoneNumber?: string
  summary?: string
  address?: IAddress
  photoURL?: string | null // Consider renaming to thumbnailURL, avatar, or profileURL
  website?: string
  currentJobTitle?: string
  firstImpression?: IFirstImpression | null
  skills: ISkill[]
  certifications: ICertification[]
  education: IEducation[]
  workHistory: IWorkHistory[]
  portfolio: IPortfolio[]
  references: IReference[]
  militaryAffiliation: MilitaryAffiliation[]
  candidateCoreCopy?: NamedMapping<number | undefined>
  candidateResponses?: ICandidateResponses
  candidateComplianceResponses?: ICandidateComplianceResponses
  resumeId?: string
  parsedResumeFileId?: string
  candidateSource?: ICandidateSource
  languages: ILanguage[]
  socialMedia?: ISocialMedia
}

export type ICandidateApplicationDetails = Pick<
  ICandidateJobApplication,
  'phoneNumber' | 'email' | 'summary' | 'address' | 'website' | 'firstName' | 'lastName'
>

export const applicationFieldMappings = new Map<keyof ICandidateJobApplication, string>()
  .set('firstImpression', 'First Impression')
  .set('skills', 'Skills')
  .set('certifications', 'Certifications')
  .set('education', 'Education')
  .set('workHistory', 'Work History')
  .set('languages', 'Languages')
  .set('portfolio', 'Portfolio')
  .set('references', 'References')
  .set('socialMedia', 'Social Media')
  .set('militaryAffiliation', 'Military Affiliations')

/**
 * Responses the candidate gives immediately prior to submitting an application.
 */
export interface ICandidateResponses {
  // referralSource: string
  // referralDetail?: string // Only available if the referralSource is 'other' or 'Employee Referral'
  yearsRelevantExperience: number
  monthsRelevantExperience: number
  visaSponsorshipRequired: boolean
  legalWorkAuthorization: boolean
  relocationConfirmed?: boolean
  hoursConfirmed?: boolean // Only applies if job has set hours
  travelConfirmed?: boolean // Only applies if job requires travel
  isDefaultApplication?: boolean
}

export const fieldsRequiredForPreview = ['jobPostId', 'skills', 'certifications', 'education'] as const
export type FieldsRequiredForPreview = (typeof fieldsRequiredForPreview)[number]

// The data needed to submit an application
export type ICandidateJobSubmission = Omit<ICandidateJobApplication, 'id' | 'candidateId' | 'candidateCoreCopy'>

// The data needed to request an application preview
export type ICandidateJobSubmissionPreview = AtLeast<ICandidateJobSubmission, FieldsRequiredForPreview>

export interface ICandidateJobApplicationStash extends Pick<ICandidateJobApplication, 'jobPostId' | 'candidateId' | 'candidateResponses'> {
  id?: string
  selectedWorkHistoryIds?: string[]
  selectedEducationIds?: string[]
  selectedReferenceIds?: string[]
  selectedFirstImpressionId?: string | null
}

export interface IJobApplicationStage extends Optional<ICandidateJobApplication, 'candidateResponses'> {}

export interface IMatchedJobApplication extends ICandidateJobApplication {
  skills: IMatchedRequirableSkill[]
  missingSkills: IRequirableSkill[]
  certifications: IMatchedRequirableCertification[]
  missingCertifications: IRequirableCertification[]
}

export interface IJobApplicationScores {
  coreMatchScore: number | null
  jobMatchScore: number
}

export interface IProcessedJobApplication extends IMatchedJobApplication, IJobApplicationScores {
  appliedDate: Date
  applicationState: APPLICATION_STATE
  requestedUpdated?: IRequestedUpdate[]
  conversationId?: string
  previousApplicationState?: APPLICATION_STATE
}

/** The employer's record of the candidate's application */
export interface IJobPostApplication extends Omit<IProcessedJobApplication, 'candidateComplianceResponses'> {
  starRating?: number
}

export const requestedUpdateFields = [
  'skills',
  'certifications',
  'education',
  'references',
  'workHistory',
  'languages',
  'portfolio',
  'firstImpression',
  'socialMedia',
  'militaryAffiliation',
] as const
export type RequestedUpdateFields = (typeof requestedUpdateFields)[number]

export interface IRequestedUpdate {
  message: string
  fulfilled: boolean
  requestedFieldChanged: keyof Pick<IProcessedJobApplication, RequestedUpdateFields>
  timestamp: Date
  submitter: string
}

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { ICandidateVideo } from 'shared-lib'
import { VideoActions, VideoActionTypes } from './video.actions'

export interface State extends EntityState<ICandidateVideo> {
  loaded: boolean
  error: [] | null
  selectedVideoId: string | null
}

export const adapter: EntityAdapter<ICandidateVideo> = createEntityAdapter<ICandidateVideo>({
  selectId: video => video.id,
})

export const initialState: State = adapter.getInitialState({
  loaded: false,
  error: null,
  selectedVideoId: null,
})

export function reducer(state = initialState, action: VideoActions): State {
  switch (action.type) {
    case VideoActionTypes.getAllVideos:
      return { ...state, loaded: false }
    case VideoActionTypes.getAllVideosSuccess:
      return adapter.setAll(action.payload, {
        ...state,
        loaded: true,
      })
    case VideoActionTypes.addFirstImpressionSuccess:
      return adapter.addOne(action.payload, { ...state })
    case VideoActionTypes.removeVideoSuccess:
      return adapter.removeOne(action.payload.id, { ...state })
    default:
      return state
  }
}

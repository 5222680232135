<e11-panel>
  <div class="e11-flex e11-justify-between e11-items-center">
    <h3 *ngIf="loading">{{ 'Searching...' | translate }}</h3>
    <div *ngIf="loading || _selectedJob"></div>
  </div>

  <div class="e11-overflow-scroll md:e11-overflow-hidden">
    <div class="e11-flex e11-gap-4 e11-flex-wrap">
      <div *ngIf="loading || _selectedJob" class="e11-grow e11-border-b e11-border-skin-grey e11-mb-4 e11-pb-4">
        <h3 class="e11-mb-2 e11-skeleton-empty">{{ _selectedJob?.jobTitle }}</h3>

        <div [ngClass]="{ 'e11-flex-wrap': _selectedJob, 'e11-w-1/2': !_selectedJob }" class="e11-flex e11-gap-2">
          <p class="e11-mb-1 e11-skeleton-empty">{{ _selectedJob?.companyName }}</p>
          <p *ngIf="!_selectedJob" class="e11-skeleton-empty"></p>
          <p
            *ngIf="_selectedJob && !_selectedJob.isInternalJob && _selectedJob.companyUrl"
            (click)="_selectedJob && navigateToJobSite(_selectedJob.companyUrl)"
            class="e11-flex e11-items-center e11-mb-1 e11-cursor-pointer e11-text-skin-primary-accent hover:e11-text-skin-primary"
          >
            <span class="material-icons-outlined md-18 e11-mr-1"> launch </span>
            {{ "Company's Website" | translate }}
          </p>
        </div>

        <div *ngIf="!_selectedJob" class="e11-w-1/2 e11-gap-2 e11-flex">
          <div class="e11-skeleton-empty e11-max-w-[40%]"></div>
          <div class="e11-skeleton-empty e11-max-w-[15%]"></div>
          <div class="e11-skeleton-empty e11-max-w-[15%]"></div>
        </div>
        <p *ngIf="_selectedJob && _selectedJob.displayLocation" class="e11-mb-1 e11-flex e11-items-center">
          <span>{{ _selectedJob.displayLocation }}</span>
        </p>
      </div>
      <div class="e11-flex e11-items-start">
        <ng-container [ngTemplateOutlet]="jobActionsButtons"></ng-container>
      </div>
    </div>

    <div #contentContainer class="lg:e11-overflow-scroll lg:e11-max-h-[70vh]">
      <div *ngIf="loading" class="e11-w-full e11-mb-4 e11-h-40">
        <h3 class="e11-h3-skeleton e11-mb-2"></h3>
        <div class="e11-skeleton-empty e11-max-w-[20%]"></div>
      </div>

      <div *ngIf="_selectedJob && _selectedJob.description" class="e11-w-full e11-border-skin-grey e11-pb-4 e11-mb-4">
        <h3 class="e11-mb-3">{{ 'Job Description' | translate }}</h3>
        <pre [innerHTML]="_selectedJob.description" class="e11-font-skin-primary e11-whitespace-pre-line e11-pl-2"></pre>
      </div>

      <div *ngIf="_selectedJob && _selectedJob.responsibilities" class="e11-w-full e11-border-skin-grey e11-pb-4 e11-mb-4">
        <h3 class="e11-mb-3">{{ 'Responsibilities' | translate }}</h3>
        <pre [innerHTML]="_selectedJob.responsibilities" class="e11-font-skin-primary e11-whitespace-pre-line e11-pl-2"></pre>
      </div>

      <div *ngIf="_selectedJob && _selectedJob.qualifications" class="e11-w-full e11-border-b e11-border-skin-grey e11-pb-4 e11-mb-4">
        <h3 class="e11-mb-3">{{ 'Qualifications' | translate }}</h3>
        <pre [innerHTML]="_selectedJob.qualifications" class="e11-font-skin-primary e11-whitespace-pre-line e11-pl-2"></pre>
      </div>
    </div>
    <!--No Job Found-->
    <div *ngIf="!loading && !_selectedJob" class="e11-w-full e11-mb-4">
      <h1 class="e11-h3-skeleton e11-mb-2 e11-text-skin-primary-accent">{{ 'No Results' | translate }}</h1>
      <h3 class="e11-mb-2">{{ 'Type some keywords into the search box and get your search started!' | translate }}</h3>
      <div>
        <p class="e11-mb-0 e11-font-primary-demibold e11-mb-1">{{ 'Things to try:' | translate }}</p>
        <ul class="e11-list-unordered">
          <li>{{ 'Check your spelling' | translate }}</li>
          <li>{{ 'Generalize your word or phrase' | translate }}</li>
          <li>{{ 'Refine or broaden your location' | translate }}</li>
          <li>{{ 'Try not to use initials or abbreviations' | translate }}</li>
        </ul>
      </div>
    </div>

    <div *ngIf="loading || _selectedJob" class="e11-w-full e11-flex e11-justify-end e11-items-center e11-border-skin-grey e11-mb-4 e11-pt-4">
      <ng-container [ngTemplateOutlet]="jobActionsButtons"></ng-container>
    </div>
  </div>

  <ng-template #jobActionsButtons>
    <div *ngIf="loading && !_selectedJob" class="e11-flex e11-grow e11-justify-end e11-gap-1 e11-items-center">
      <span class="e11-button-pill-sm-skeleton"></span>
      <span class="e11-button-pill-sm-skeleton"></span>
    </div>

    <div *ngIf="_selectedJob" class="e11-items-center e11-justify-end e11-flex">
      <button class="e11-border e11-border-skin-primary-accent e11-rounded-lg e11-cursor-pointer hover:e11-bg-skin-primary-accent/10 e11-p-2">
        <quick-apply-button (click)="startApplicationProcess()" *ngIf="_selectedJob.isInternalJob" [showTooltip]="true"></quick-apply-button>
      </button>
      <e11-button
        (click)="startApplicationProcess()"
        *ngIf="!_selectedJob.isInternalJob"
        [buttonGroup]="true"
        [color]="'primary-accent'"
        [iconPosition]="'left'"
        [iconSize]="'md-14'"
        [icon]="'launch'"
        [size]="'sm'"
        [style]="'pill'"
        [value]="(isMobile$ | async) ? 'Apply' : ('Apply Now' | translate)"
      >
      </e11-button>
      <e11-button
        (click)="copy()"
        *ngIf="!_selectedJob.isInternalJob"
        [color]="'primary-accent'"
        [ghost]="true"
        [iconPosition]="'left'"
        [iconSize]="'md-14'"
        [icon]="'copy'"
        [size]="'sm'"
        [style]="'pill'"
        [value]="(isMobile$ | async) ? 'Copy' : ('Copy Link' | translate)"
      >
      </e11-button>
      <!-- @Todo: Save for later work - Favorite Button Waiting for Backend -->
      <!-- <e11-button
        (click)="favoriteApplication()"
        *ngIf="_selectedJob.isInternalJob"
        [color]="'primary-accent'"
        [ghost]="true"
        [iconPosition]="'left'"
        [iconSize]="'md-14'"
        [icon]="'favorite_outline'"
        [size]="'sm'"
        [style]="'pill'"
        [value]="(isMobile$ | async) ? 'favorite' : ('Favorite' | translate)"
      >
      </e11-button> -->
    </div>
  </ng-template>
</e11-panel>

<div modal-body class="e11-mb-4">
  <!-- Success Message -->
  <div class="e11-w-full">
    <p>
      {{
        'Your cnect profile is yours to use as you see fit. Create a link or PDF and share your profile with anyone you want. Sending it during your job search will help you stand out from the crowd.'
          | translate
      }}
    </p>
  </div>

  <div class="e11-grid e11-grid-cols-2 md:e11-grid-cols-4 e11-gap-2 md:e11-gap-4 e11-mb-6">
    <div>
      <e11-card [isClickable]="false">
        <div class="e11-text-center">
          <span class="material-icons-outlined e11-text-skin-primary-accent md-36"> work_outline </span>
          <h5 class="e11-font-primary-demibold e11-mb-0">{{ 'Work History' | translate }}</h5>
          <p class="e11-mb-0 e11-text-sm">({{ resume?.workHistory?.length }} {{ resume?.workHistory?.length === 1 ? 'entry' : 'entries' }})</p>
        </div>
      </e11-card>
    </div>
    <div>
      <e11-card [isClickable]="false">
        <div class="e11-text-center">
          <span class="material-icons-outlined e11-text-skin-primary-accent md-36"> school </span>
          <h5 class="e11-font-primary-demibold e11-mb-0">{{ 'Education' | translate }}</h5>
          <p class="e11-mb-0 e11-text-sm">({{ resume?.education?.length }} {{ resume?.education?.length === 1 ? 'entry' : 'entries' }})</p>
        </div>
      </e11-card>
    </div>
    <div>
      <e11-card [isClickable]="false">
        <div class="e11-text-center">
          <span class="material-icons-outlined e11-text-skin-primary-accent md-36"> construction </span>
          <h5 class="e11-font-primary-demibold e11-mb-0">{{ 'Skills' | translate }}</h5>
          <p class="e11-mb-0 e11-text-sm">({{ resume?.skills?.length }} {{ resume?.skills?.length === 1 ? 'item' : 'items' }})</p>
        </div>
      </e11-card>
    </div>
    <div>
      <e11-card [isClickable]="false">
        <div class="e11-text-center">
          <span class="material-icons-outlined e11-text-skin-primary-accent md-36"> class </span>
          <h5 class="e11-font-primary-demibold e11-mb-0">{{ 'Certifications' | translate }}</h5>
          <p class="e11-mb-0 e11-text-sm">({{ resume?.certifications?.length }} {{ resume?.certifications?.length === 1 ? 'item' : 'items' }})</p>
        </div>
      </e11-card>
    </div>
  </div>

  <ng-container *ngIf="!resumeMissingData">
    <h4 class="e11-text-skin-success e11-mb-2">{{ 'Excellent!' | translate }}</h4>
    <div class="e11-border e11-border-skin-success e11-bg-skin-success/10 e11-rounded e11-p-4 e11-mb-8 e11-flex e11-items-center">
      <span class="material-icons-outlined md-36 e11-float-left e11-text-skin-success e11-mr-2"> task_alt </span>
      <p class="e11-mb-0">
        {{ 'We found everything needed to get you started with a new Cnect profile. You should be excited!' | translate }}
      </p>
    </div>
  </ng-container>

  <ng-container *ngIf="resumeMissingData">
    <h4 class="e11-text-skin-complimentary e11-mb-2">{{ "What we didn't find" | translate }}:</h4>
    <div class="e11-border e11-border-skin-complimentary e11-bg-skin-complimentary/10 e11-rounded e11-p-4 e11-mb-8">
      <p class="e11-text-skin-complimentary e11-mb-0">{{ 'Here are some things we did not find that you may want to add:' | translate }}</p>
      <ul class="e11-mb-0 e11-text-skin-complimentary e11-list-disc">
        <li class="e11-ml-4 e11-mr-2" *ngIf="!resume?.summary">Summary</li>
        <li class="e11-ml-4 e11-mr-2" *ngIf="!resume?.address?.address">Address</li>
        <li class="e11-ml-4 e11-mr-2" *ngIf="!resume?.address?.city">City</li>
        <li class="e11-ml-4 e11-mr-2" *ngIf="!resume?.address?.state">State</li>
        <li class="e11-ml-4 e11-mr-2" *ngIf="!resume?.address?.zip">Zip Code</li>
      </ul>
    </div>
  </ng-container>

  <div class="e11-border e11-border-skin-grey e11-rounded e11-p-4 e11-mb-8 e11-flex e11-items-center">
    <span class="material-icons-outlined md-36 e11-float-left e11-mr-2"> info </span>
    <p class="e11-mb-0 e11-text-sm">
      {{
        'We gather as much as we can from your resume. To ensure accuracy and the best overall experience we ask that you review the information in your new profile. Please take extra care to check things like dates. Those are important. '
          | translate
      }}
    </p>
  </div>

  <div class="e11-w-full e11-text-right">
    <e11-button
      [icon]="'edit'"
      [iconPosition]="'left'"
      [iconSize]="'md-18'"
      [color]="'primary-accent'"
      value="{{ 'Review the results' | translate }}"
      (click)="close()"
    ></e11-button>
  </div>
</div>

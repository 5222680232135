import { compareAsc, compareDesc, compareJoin, valueOf } from '@engineering11/utility'
import { CandidateJobStatus, ICandidateJobVM, candidateJobStatusRank } from 'shared-lib'

const minDate = new Date(-8640000000000000)

const compareStatus = compareDesc<ICandidateJobVM>(job => candidateJobStatusRank[job.status])
const compareRelevantDate = compareDesc<ICandidateJobVM>(job => {
  switch (job.status) {
    case CandidateJobStatus.Viewed:
      return job.__createdAt ?? minDate
    case CandidateJobStatus.Applied:
      return job.appliedDate ?? minDate
    case CandidateJobStatus.CandidateNotInterested:
      return job.withdrawnDate ?? minDate
    case CandidateJobStatus.JobClosed:
    case CandidateJobStatus.EmployerNotInterested:
      return job.closedDate ?? minDate
  }
})
export const compareCandidateJobVM = compareJoin([compareStatus, compareRelevantDate])

import { Injectable } from '@angular/core'
import { ICandidateApplicationUpdateStage } from '@candidate/app/models/candidate-job-update.model'
import { AtLeast } from '@engineering11/types'
import { Observable } from 'rxjs'
import { ApplicationUpdateStageRepository } from './application-update-stage.repository'
import { CandidateApplicationService } from './candidate-application.service'

@Injectable({ providedIn: 'root' })
export class ApplicationUpdateStageService {
  constructor(private applicationUpdateStageRepository: ApplicationUpdateStageRepository, private applicationService: CandidateApplicationService) {}

  getByUserAndJobPost(userId: string, jobPostId: string): Observable<ICandidateApplicationUpdateStage | undefined> {
    const id = buildApplicationUpdateStageId({ candidateId: userId, jobPostId })
    return this.applicationUpdateStageRepository.get(id)
  }

  upsert(model: AtLeast<ICandidateApplicationUpdateStage, 'jobPostId' | 'candidateId'>) {
    const modelToSave = { ...model, id: buildApplicationUpdateStageId(model) }
    return this.applicationUpdateStageRepository.upsert(modelToSave)
  }
}

export function buildApplicationUpdateStageId(idData: Pick<ICandidateApplicationUpdateStage, 'jobPostId' | 'candidateId'>) {
  return `${idData.candidateId}_${idData.jobPostId}`
}

import { Injectable } from '@angular/core'
import { IJobSearchRequest, IJobSearchResult, IJobSearchResultVM } from '@candidate/app/models/jobs/job-search.model'
import { JobSearchService } from '@candidate/app/services/jobs/job-search.service'
import { isNotNil } from '@engineering11/utility'
import { E11ErrorHandlerService, E11Logger } from '@engineering11/web-api-error'
import { ComponentStore, tapResponse } from '@ngrx/component-store'
import { Observable } from 'rxjs'
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators'

export interface JobSearchState {
  jobSearchResult: IJobSearchResult | null
  selectedJob: IJobSearchResultVM | null
  searchResultLoading: boolean
  nextSearchResultLoading: boolean
}

const defaultState: JobSearchState = {
  jobSearchResult: null,
  searchResultLoading: false,
  nextSearchResultLoading: false,
  selectedJob: null,
}

@Injectable({
  providedIn: 'root',
})
export class JobSearchStore extends ComponentStore<JobSearchState> {
  constructor(private jobSearchService: JobSearchService, private logger: E11Logger, private errorHandler: E11ErrorHandlerService) {
    super(defaultState)
  }

  // Selectors
  readonly getState = this.select(s => s)
  readonly jobSearchResult$ = this.select(s => s.jobSearchResult)
  readonly searchResultLoading$ = this.select(s => s.searchResultLoading)
  readonly selectedJob$ = this.select(s => s.selectedJob)
  readonly nextSearchResultLoading$ = this.select(s => s.nextSearchResultLoading)

  // Effects
  readonly onSearchJobs = this.effect((payload$: Observable<IJobSearchRequest>) =>
    payload$.pipe(
      switchMap(request => {
        this.onSearchResultLoading()
        return this.jobSearchService.search(request).pipe(
          tapResponse(
            result => {
              this.logger.log({ result })
              if (result.jobs.length) {
                this.onSelectJob(result.jobs[0])
              }

              this.onSearchJobSuccess(result)
            },
            (error: Error) => this.errorHandler.handleError(error)
          )
        )
      })
    )
  )

  readonly onLoadMoreJobs = this.effect((payload$: Observable<IJobSearchRequest>) =>
    payload$.pipe(
      switchMap(request => {
        this.onMoreSearchResultLoading()
        return this.jobSearchService.search(request).pipe(
          withLatestFrom(this.jobSearchResult$.pipe(filter(isNotNil))),
          tapResponse(
            ([result, prevSearchResult]) => {
              this.logger.log({ result })
              this.onSearchJobSuccess({
                jobs: [...prevSearchResult.jobs, ...result.jobs],
                nextPageToken: result.nextPageToken,
                totalResults: result.totalResults,
              })
            },
            (error: Error) => this.errorHandler.handleError(error)
          )
        )
      })
    )
  )

  readonly onSelectJob = this.effect((job$: Observable<IJobSearchResultVM>) => job$.pipe(map(job => this.onJobSelected(job))))

  // Reducers
  private readonly onSearchJobSuccess = this.updater((state, jobSearchResult: IJobSearchResult) => ({
    ...state,
    jobSearchResult,
    searchResultLoading: false,
    nextSearchResultLoading: false,
  }))

  private readonly onJobSelected = this.updater((state, selectedJob: IJobSearchResultVM) => ({
    ...state,
    selectedJob,
  }))

  private readonly onSearchResultLoading = this.updater(state => ({
    ...state,
    jobSearchResult: null,
    selectedJob: null,
    searchResultLoading: true,
  }))

  private readonly onMoreSearchResultLoading = this.updater(state => ({
    ...state,
    searchResultLoading: false,
    nextSearchResultLoading: true,
  }))
}

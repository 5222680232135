import { Injectable } from '@angular/core'
import { Router } from '@angular/router'

export const InfoContentRoutes = Object.freeze({
  SocialNetwork: '/info/network',
  SocialCommunity: '/c/m/messaging/communities',
  ProfileResume: '/info/profiles/resume-parsing',
  ProfileFIV: '/info/video/fiv',
  JobHome: '/info/home',
  JobSearch: '/info/home',
})

@Injectable({ providedIn: 'root' })
export class CandidateNavigationInfoContentService {
  constructor(private router: Router) {}

  navigateTo(route: string) {
    return this.router.navigate([route])
  }
}

import { Component, Input, OnInit } from '@angular/core'
import { NavigationEnd, PRIMARY_OUTLET, Router, UrlTree } from '@angular/router'
import { IAuthService } from '@engineering11/auth-web'
import { AppConfigService } from '@engineering11/tenant-bootstrap-web'
import { TokenStorage } from 'shared-lib'

@Component({
  selector: 'app-header-unauthenticated',
  templateUrl: './header-unauthenticated.component.html',
  styleUrls: ['./header-unauthenticated.component.scss'],
})
export class AppHeaderUnauthenticatedComponent implements OnInit {
  @Input() logo: string = 'white'
  @Input() cta: string = ''
  page = '?'
  imgSrc: string = '../../../../assets/images/logo/cnect-white.png'
  logoUrl = this.appConfig.config.brand.logoImageLightUrl
  constructor(private router: Router, private tokenStorage: TokenStorage, private authService: IAuthService, private appConfig: AppConfigService) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const urlTree: UrlTree = this.router.parseUrl(this.router.url)
        const primary = urlTree.root.children[PRIMARY_OUTLET]
        let page = primary.segments[1]?.path || 'home'
        this.page = this.router.url
        if (page === 'register-verify') {
          this.cta = 'logout'
        }
      }
    })
  }

  ngOnInit() {
    const urlTree: UrlTree = this.router.parseUrl(this.router.url)
    const primary = urlTree.root.children[PRIMARY_OUTLET]
    this.page = primary.segments[1]?.path || 'home'
    if (this.logo === 'blue') {
      this.imgSrc = '../../../../assets/images/logo/cnect-blue.png'
    }
  }

  navigateToEmployer() {
    window.location.href = 'https://employer.cnected.com/'
  }

  signOut(): void {
    this.authService.signOut()
    this.tokenStorage.clear()
  }
}

import { Injectable } from '@angular/core'
import { INotificationHandler, INotificationView, UserAppNotificationService } from '@engineering11/notifications-web'
import { ICandidateApplicationResubmittedNotification } from 'shared-lib'
import { CandidateHomeNavigationService } from '../candidate-home-navigation.service'

@Injectable({ providedIn: 'root' })
export class CandidateApplicationResubmittedHandler implements INotificationHandler<ICandidateApplicationResubmittedNotification, INotificationView> {
  notificationType: string = 'candidate_application_resubmitted'
  constructor(
    private candidateHomeNavigationService: CandidateHomeNavigationService,
    private userAppNotificationService: UserAppNotificationService
  ) {}

  onClick(notification: ICandidateApplicationResubmittedNotification): boolean | Promise<boolean> {
    if (!notification.viewed) {
      this.userAppNotificationService.markNotificationAsRead(notification.id)
    }
    return this.candidateHomeNavigationService.jobApplication(notification.jobId)
  }
}

import { Component, Input } from '@angular/core'
import { jobGroupFn, jobGroupNameFn, jobRelatedConversationFilter } from '@candidate/app/util/conversation.util'
import { IConversationVM } from '@engineering11/messaging-web'
import { CnectFeatures, ConversationTypes } from 'shared-lib'

@Component({
  selector: 'nav-item-conversation-group',
  templateUrl: './conversation-group.component.html',
  styleUrls: ['./conversation-group.component.scss'],
})
export class ConversationGroupComponent {
  @Input() features: CnectFeatures | undefined

  ConversationTypes = ConversationTypes
  conversationFilter = jobRelatedConversationFilter
  groupFn: (obj: IConversationVM) => string = jobGroupFn
  groupNameFn: (obj: IConversationVM) => string = jobGroupNameFn
}

import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { LandingComponent } from './landing.component'
import { JobPostComponent } from './views/job-post/job-post.component'
import { JobSearchComponent } from './views/job-search/job-search.component'
import { LandingStartComponent } from './views/start/start.component'

const routes: Routes = [
  {
    path: '',
    component: LandingComponent,
    // ...redirectLoggedInToHomeGuard(),
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: LandingStartComponent,
      },
      {
        path: 'search/:customerKey',
        component: JobSearchComponent,
      },
      {
        path: 'job/:jobPostId',
        component: JobPostComponent,
      },
    ],
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LandingRoutingModule {}
